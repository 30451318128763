/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import greenlet from 'greenlet'
import Popup from '../common/popup/Popup'
import moment from 'moment'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import { IoMdDownload } from 'react-icons/io'
import { CSVLink } from 'react-csv'
import { toNumberWithSmartPrecision } from '../../util/util'
import { getTokenPriceInCurrency } from '../../util/symbolUtil'
import { updateExposureVariables } from './tradingAction'
import { EXPOSURE_HOME_CURRENY } from './constants'

const DEFAULT_COIN_THRESHOLD_MIN_VALUE = 20000

export const MODES = {
    EXPOSURE: 'EXPOSURE',
    NAV: 'NAV'
}

export const TABLE_GROUP_BYS = {
    COIN: 'COIN',
    ACCOUNT: 'ACCOUNT'
}

const COIN_SORT_BYS = {
    NAME: 'NAME',
    SUM_EXPOSURE: 'SUM_EXPOSURE',
    SUM_EXPOSURE_IN_USD: 'SUM_EXPOSURE_IN_USD',
    INITIAL_BALANCE: 'INITIAL_BALANCE',
    NET_EXPOSURE_IN_ASSET: 'NET_EXPOSURE_IN_ASSET',
    NET_EXPOSURE_IN_USD: 'NET_EXPOSURE_IN_USD',
    NET_EXPOSURE_DIFF_IN_USD: 'NET_EXPOSURE_DIFF_IN_USD',
    POSITION_NOTIONAL_SUM: 'POSITION_NOTIONAL_SUM'
}

const ACCOUNT_SORT_BYS = {
    NAME: 'NAME',
    SUM_EXPOSURE: 'SUM_EXPOSURE',
    SUM_EXPOSURE_IN_USD: 'SUM_EXPOSURE_IN_USD',
    SUM_EXPOSURE_DIFF_IN_USD: 'SUM_EXPOSURE_DIFF_IN_USD'
}

const ITEM_SORT_BYS = {
    SOURCE: 'SOURCE',
    TIME: 'TIME',
    EXPOSURE: 'EXPOSURE'
}

const getExposureReport = greenlet(({
    mode=MODES.EXPOSURE, groupBy=TABLE_GROUP_BYS.COIN, portfolioNames=[], homeCurrency='USDT', shouldHideFTX=false,
    coinSortBy=COIN_SORT_BYS.NAME, accountSortBy=ACCOUNT_SORT_BYS.NAME, itemSortBy=ITEM_SORT_BYS.SOURCE,
    exposureItems={}, initialBalance={}, pricings={},
    exposureItemsToCompare={}, initialBalanceToCompare={}, pricingsToCompare={},
    accountItems={}, symbolItems={}, exposureVariables={}
}) => {

    const DEFAULT_COIN_THRESHOLD_MIN_VALUE = 20000

    const MODES = {
        EXPOSURE: 'EXPOSURE',
        NAV: 'NAV'
    }

    const TABLE_GROUP_BYS = {
        COIN: 'COIN',
        ACCOUNT: 'ACCOUNT'
    }
    
    const COIN_SORT_BYS = {
        NAME: 'NAME',
        SUM_EXPOSURE: 'SUM_EXPOSURE',
        SUM_EXPOSURE_IN_USD: 'SUM_EXPOSURE_IN_USD',
        INITIAL_BALANCE: 'INITIAL_BALANCE',
        NET_EXPOSURE_IN_ASSET: 'NET_EXPOSURE_IN_ASSET',
        NET_EXPOSURE_IN_USD: 'NET_EXPOSURE_IN_USD',
        NET_EXPOSURE_DIFF_IN_USD: 'NET_EXPOSURE_DIFF_IN_USD',
        POSITION_NOTIONAL_SUM: 'POSITION_NOTIONAL_SUM'
    }
    
    const ACCOUNT_SORT_BYS = {
        NAME: 'NAME',
        SUM_EXPOSURE: 'SUM_EXPOSURE',
        SUM_EXPOSURE_IN_USD: 'SUM_EXPOSURE_IN_USD',
        SUM_EXPOSURE_DIFF_IN_USD: 'SUM_EXPOSURE_DIFF_IN_USD'
    }
    
    const ITEM_SORT_BYS = {
        SOURCE: 'SOURCE',
        TIME: 'TIME',
        EXPOSURE: 'EXPOSURE'
    }

    const INSTRUMENT_TYPES = {
        SPOT: 'SPOT',
        FUTURE: 'FUTURE',
        SWAP: 'SWAP',
        INDEX: 'INDEX',
        OPTION: 'OPTION',
        SPREAD: 'SPREAD',
        REPO: 'REPO',
        GRFQ: 'GRFQ',
        UNKOWN: 'UNKOWN'
    }

    const SYMBOLS_WITH_MUTLIPLIER_IN_BTC = ['ETHUSD_BITMEX_SWAP', 'XRPUSD_BITMEX_SWAP', 'ETHUSDZ20_BITMEX_FUT']

    const result = {
        sortedExposureGroups: [],
        exposureGroupsToCompare: {},
        totalNAV: 0,
        totalPnL: 0
    }

    /**
     * A simple implementation similar to lodash.sortBy: returns a sorted copy of the array
     * @param {Array} arr The array to sort
     * @param {Function} keyFn The function to generate sort keys
     * @returns {Array} A sorted copy of the array
     */
    function _sortBy(arr, keyFn) {
        return arr.slice().sort((a, b) => {
            const keyA = keyFn(a)
            const keyB = keyFn(b)
            // Simple comparison for numbers and strings
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })
    }

    /*
    Note: This function was originally in symboUtil.
    Since greenlet in a web worker cannot import external modules,
    it has been reimplemented here.
    Please update this copy if the original function changes.
    */
    const _getTokenPriceInCurrency = ({
        _token = '',
        _lastPricePerSymbol = {},
        _currency = 'USDT',
        _shouldReferencePerpPrice = false,
        _shouldUseDirectConversionOnly = false
    }) => {
        // If the token contains '-OPTION', split it by '-' and take the first part, then convert it to uppercase; otherwise, simply convert the token to uppercase.
        const formattedToken = _token.includes('-OPTION')
            ? _token.split('-')[0].toUpperCase()
            : _token.toUpperCase()
      
        const EXCHANGE_NAMES_TO_INSPECT = [
            'BINANCE', 'OKEX', 'COINFLEX', 'INTERNAL', 'IBBATS',
            'IBARCA', 'GATE', 'HYPERLIQ', 'BYBIT', 'BITGET'
        ]
      
        // Filter out the target currency from the list of intermediary currencies.
        const INTERMEDIARY_CURRENCIES = ['USDT', 'USDC', 'USD'].filter(cur => cur !== _currency)
      
        let price = null
      
        // If the token matches the target currency, or if the token is 'USD' and the target currency is one of ['BUSD', 'USDC', 'USDT'], then the price is 1.
        if (formattedToken === _currency || (formattedToken === 'USD' && ['BUSD', 'USDC', 'USDT'].includes(_currency))) {
            price = 1
        } else {
            // Iterate over the exchange names to try to find a direct (or inverse) price.
            price = EXCHANGE_NAMES_TO_INSPECT.reduce((result, exchangeName) => {
                // Check if result is null or undefined.
                if (result === null) {
                    const keyDirect = `${formattedToken.toLowerCase()}_${_currency.toLowerCase()}_${exchangeName}_SPT`
                    const keyInverse = `${_currency.toLowerCase()}_${formattedToken.toLowerCase()}_${exchangeName}_SPT`
            
                    const directLastPrice = Number(_lastPricePerSymbol[keyDirect])
                    const inverseLastPrice = Number(_lastPricePerSymbol[keyInverse])
            
                    if (directLastPrice > 0) {
                        result = directLastPrice
                    } else if (inverseLastPrice > 0) {
                        result = 1 / inverseLastPrice
                    }
                }
                return result
            }, null)
      
            // If no direct price is found and referencing perp price is allowed, try using BNBFUTA_SWAP related prices.
            if (price === null && _shouldReferencePerpPrice) {
                const keyDirect1 = `${formattedToken.toLowerCase()}_${_currency.toLowerCase()}_BNBFUTA_SWAP`
                const keyDirect2 = `${formattedToken.toLowerCase()}_${_currency.toLowerCase()}_BYBIT_SWAP`
                const keyInverse = `${_currency.toLowerCase()}_${formattedToken.toLowerCase()}_BNBFUTA_SWAP`
                const keyDirectWithMultiplier = `${formattedToken.toLowerCase()}1000_${_currency.toLowerCase()}_BNBFUTA_SWAP`
        
                const directLastPrice = Number(_lastPricePerSymbol[keyDirect1]) || Number(_lastPricePerSymbol[keyDirect2])
                const inverseLastPrice = Number(_lastPricePerSymbol[keyInverse])
                const directLastPriceWithMultiplier = Number(_lastPricePerSymbol[keyDirectWithMultiplier])
        
                if (directLastPrice > 0) {
                    price = directLastPrice
                } else if (inverseLastPrice > 0) {
                    price = 1 / inverseLastPrice
                } else if (directLastPriceWithMultiplier > 0) {
                    price = directLastPriceWithMultiplier / 1000
                }

                if (price === null) {
                    let key
                    if (formattedToken.startsWith('1000')) {
                        key = `${formattedToken.substring(4).toLowerCase()}1000_${_currency.toLowerCase()}_BNBFUTA_SWAP`
                    } else if (formattedToken.startsWith('1M')) {
                        key = `${formattedToken.substring(2).toLowerCase()}1m_${_currency.toLowerCase()}_BNBFUTA_SWAP`
                    }
                    const directLastPrice = Number(_lastPricePerSymbol[key])
                    if (directLastPrice > 0) {
                        price = directLastPrice
                    }
                }
            }
      
            // If still no direct price is found and direct conversion is not enforced, attempt to convert via intermediary currencies.
            if (price == null && !_shouldUseDirectConversionOnly) {
                INTERMEDIARY_CURRENCIES.forEach(intermediaryCurrency => {
                    if (price == null) {
                        const intermediaryCurrencyPrice = _getTokenPriceInCurrency({
                            _token: intermediaryCurrency,
                            _lastPricePerSymbol,
                            _currency,
                            _shouldReferencePerpPrice,
                            _shouldUseDirectConversionOnly: true
                        })
                        const tokenPriceInIntermediaryCurrency = _getTokenPriceInCurrency({
                            _token,
                            _lastPricePerSymbol,
                            _currency: intermediaryCurrency,
                            _shouldReferencePerpPrice,
                            _shouldUseDirectConversionOnly: true
                        })
                        if (Number(intermediaryCurrencyPrice) > 0 && Number(tokenPriceInIntermediaryCurrency) > 0) {
                            price = tokenPriceInIntermediaryCurrency * intermediaryCurrencyPrice
                        }
                    }
                })
            }
        }
      
        return price
    }

    /*
    Note: This function was originally in symboUtil.
    Since greenlet in a web worker cannot import external modules,
    it has been reimplemented here.
    Please update this copy if the original function changes.
    */
    const _getSymbolAttributeByName = (_symbolName) => {

        const tokens = (_symbolName || '').split('_')
        const originalExchangeName = tokens[tokens.length - 2]
        const exchangeName = (originalExchangeName || '').startsWith('IB') ? 'IB' : originalExchangeName
        const originalType = tokens[tokens.length - 1]
        const instrumentType = ['INDEX', 'INDEX2', 'ASTIDX'].includes(originalType) ? INSTRUMENT_TYPES.INDEX
            : originalType === 'SPT' ? INSTRUMENT_TYPES.SPOT
            : ['FTW', 'FNW', 'FQR', 'FNQ', 'FUT'].includes(originalType) ? INSTRUMENT_TYPES.FUTURE
            : originalType === 'SWAP' ? INSTRUMENT_TYPES.SWAP
            : ['CALL', 'PUT', 'QMOV'].includes(originalType) ? INSTRUMENT_TYPES.OPTION
            : originalType === 'SPR' ? INSTRUMENT_TYPES.SPREAD
            : originalType === 'REPO' ? INSTRUMENT_TYPES.REPO
            : originalType === 'GRFQ' ? INSTRUMENT_TYPES.GRFQ
            : INSTRUMENT_TYPES.UNKOWN

        const isCompositInstrument = (_symbolName || '').includes('&')
        const baseMultiplierMatch = tokens[0].match(/1(0{3,})$/)

        let base = isCompositInstrument ? 'UNIT'
            : instrumentType === INSTRUMENT_TYPES.OPTION ? 'OPTION_CONTRACT'
            : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? tokens[0].substring(0, 3).toUpperCase()
            : baseMultiplierMatch ? tokens[0].substring(0, baseMultiplierMatch.index).toUpperCase()
            : tokens.length === 4 ? tokens[0].toUpperCase() 
            : tokens[0].length > 6 && tokens[0].endsWith('USD', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 6)
            : tokens[0].length > 7 && tokens[0].endsWith('USDT', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 7)
            : tokens[0].length > 4 && tokens[0].endsWith('USDT') ? tokens[0].substring(0, tokens[0].length - 4)
            : tokens[0].substring(0, tokens[0].length - 3)
      
        base = ['XBT', 'MBTC'].includes(base) ? 'BTC'
            : ['METH'].includes(base) ? 'ETH'
            : base === 'TSTBSC' ? 'TST'
            : base

        const quote = isCompositInstrument ? 'QUOTE'
            : instrumentType === INSTRUMENT_TYPES.OPTION ? tokens[0].substring(0, 3).toUpperCase() 
            : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? 'USD' 
            : tokens.length === 4 ? tokens[1].toUpperCase()
            : exchangeName === 'BITMEX' && base !== 'BTC' && !tokens[0].includes('USD') ? 'BTC'
            : tokens[0].length > 7 && tokens[0].endsWith('USDT', tokens[0].length - 3) ? 'USDT'
            : 'USD'
      
        return {
            base,
            quote,
            exchangeName,
            instrumentType
        }
    }

    /*
    Note: This function was originally in tradingUtil.
    Since greenlet in a web worker cannot import external modules,
    it has been reimplemented here.
    Please update this copy if the original function changes.
    */
    const _getNotional = ({ _symbolItem, _quantity, _price, _BTCUSDIndexLastPrice }) => {
        _quantity = Number(_quantity)
        _price = Number(_price)
        _BTCUSDIndexLastPrice = Number(_BTCUSDIndexLastPrice) || NaN
        let _notional = null
        if (_symbolItem) {
            const { symbol_name, multiplier, trading_in_notional } = _symbolItem
            const { instrumentType } = _getSymbolAttributeByName(symbol_name)
            if (instrumentType === INSTRUMENT_TYPES.REPO) {
                _notional = null
            } else if (instrumentType === INSTRUMENT_TYPES.OPTION) {
                _notional = _quantity * multiplier
            } else if (SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(symbol_name)) {
                _notional = _quantity * multiplier * _price * _BTCUSDIndexLastPrice
            } else {
                _notional = _quantity * multiplier * (trading_in_notional === '1' ? 1 : _price)
            }
        }
    
        return _notional
    }

    const _getInitialBalanceCoins = (_initialBalance={}) => {
        // Use a Set to automatically handle duplicate coin entries
        const coinsSet = new Set()

        // Helper function to add valid coins from a given balance object
        const addCoins = balance => {
            Object.entries(balance).forEach(([coin, value]) => {
                if (coin !== 'acct_name' && coin !== 'timestamp' && Number(value) !== 0) {
                    coinsSet.add(coin.toUpperCase())
                }
            })
        }

        // If a specific portfolio is set and it's not ALL, process only its balance
        portfolioNames.forEach(_portfolio => {
            const key = `initial_balance_${_portfolio}`
            addCoins(_initialBalance[key] || {})
        })

        // Return an array of unique coin symbols
        return [...coinsSet]
    }

    const _getSortedItems = (_exposureItems) => {
        // Create a shallow copy of the array to avoid mutating the original
        return _exposureItems.slice().sort((a, b) => {
            let aKey, bKey
        
            if (itemSortBy === ITEM_SORT_BYS.EXPOSURE) {
                // For exposure, sort by descending absolute exposure
                aKey = -Math.abs(a.exposure)
                bKey = -Math.abs(b.exposure)
            } else if (itemSortBy === ITEM_SORT_BYS.TIME) {
                // For time, convert the timestamp to a numeric value using Date
                aKey = new Date(a.timestamp).getTime()
                bKey = new Date(b.timestamp).getTime()
            } else if (itemSortBy === ITEM_SORT_BYS.SOURCE) {
                // For source, sort lexicographically by the source string
                aKey = a.source
                bKey = b.source
            } else {
                aKey = null
                bKey = null
            }
        
            if (aKey < bKey) return -1
            if (aKey > bKey) return 1
            return 0
        })
    }      

    const _organizeExposureGroups = ({
        _mode=MODES.EXPOSURE, _groupBy=TABLE_GROUP_BYS.COIN, _shouldSort=false,
        _exposureItems={}, _initialBalance={}, _pricings={}
    }) => {

        // Ignore initial balance of treasury
        // eslint-disable-next-line no-unused-vars
        const { initial_balance_treasury, ...rest } = _initialBalance
        _initialBalance = rest

        // Combine all filtering conditions into one pass
        const filteredExposureItems = Object.values(_exposureItems).filter(item => {
            // Exclude items from FTX if shouldHideFTX is true
            if (shouldHideFTX && accountItems[item.accountName]?.exchange_name === 'FTX') {
                return false
            }

            // Exclude items based on mode-specific invalid sources
            const invalidSources = _mode === MODES.NAV ? ['POSITION', 'TREASURY_EXPOSURE_OFFSET', 'TREASURY_EXPOSURE', 'DEFI_BROKER_EXPOSURE_OFFSET', 'DEFI_BROKER_EXPOSURE']
                : _mode === MODES.EXPOSURE ? ['TREASURY_PNL', 'TREASURY_PNL_OFFSET', 'DEFI_BROKER_PNL', 'DEFI_BROKER_PNL_OFFSET']
                : []

            if (invalidSources.includes(item.source)) {
                return false
            }

            return portfolioNames.includes(item.portfolioName) ||
                (!item.portfolioName && portfolioNames.includes(accountItems[item.accountName]?.portfolio_name))
        })
  
        // Map the filtered items to include computed exposureInUSD (and adjust exposure for NAV mode)
        const seivedExposureItems = filteredExposureItems.map(item => {
            const coinPrice = _getTokenPriceInCurrency({
                _token: item.coin,
                _lastPricePerSymbol: _pricings,
                _currency: homeCurrency,
                _shouldReferencePerpPrice: true
            })
  
            if (_mode === MODES.NAV) {
                // Use equity if available; otherwise, fall back to exposure
                const computedExposure = item.equity ?? item.exposure
                return {
                    ...item,
                    exposure: computedExposure,
                    coinPrice,
                    exposureInUSD: coinPrice ? Number(computedExposure) * coinPrice : null
                }
            }
            
            return {
                ...item,
                coinPrice,
                exposureInUSD: coinPrice ? item.exposure * coinPrice : null
            }
        })

        let exposureGroups = {}
        if (_groupBy === TABLE_GROUP_BYS.COIN) {
              // Group exposureItems by coin
            exposureGroups = seivedExposureItems.reduce((groups, item) => {
                const coin = item.coin
                if (!groups[coin]) groups[coin] = []
                groups[coin].push(item)
                return groups
            }, {})

              // Ensure every coin from initialBalance exists in exposureGroups
            const initialBalanceCoins = _getInitialBalanceCoins(_initialBalance)
            initialBalanceCoins.forEach(coin => {
                if (!(coin in exposureGroups)) {
                    exposureGroups[coin] = []
                }
            })

            // Process each coin group
            Object.entries(exposureGroups).forEach(([coin, itemsByCoin]) => {
                const coinPrice = _getTokenPriceInCurrency({
                    _token: coin,
                    _lastPricePerSymbol: _pricings,
                    _currency: homeCurrency,
                    _shouldReferencePerpPrice: true
                })

                // Sum exposures and USD exposures for all items in this coin group
                const exposureSum = itemsByCoin.reduce((sum, item) => sum + Number(item.exposure || 0), 0)
                const exposureSumInUSD = itemsByCoin.reduce((sum, item) => sum + Number(item.exposureInUSD || 0), 0)

                // Determine initialExposure based on portfolioNames
                let initialExposure = null
                portfolioNames.forEach(_portfolio => {
                    const balanceKey = `initial_balance_${_portfolio}`
                    if (_initialBalance[balanceKey] && coin.toLowerCase() in _initialBalance[balanceKey]) {
                        initialExposure = (initialExposure || 0) + Number(_initialBalance[balanceKey][coin.toLowerCase()])
                    }
                })

                const netExposure = exposureSum - (initialExposure || 0)

                // Calculate the positionNotionalSum over the items in this coin group
                const positionNotionalSum = itemsByCoin.reduce((sum, exposureItem) => {
                    const { source, productName, detail } = exposureItem
                    const { base, quote } = _getSymbolAttributeByName(productName || '')
                    let result = 0
                    if (source === 'POSITION' && base === coin) {
                        const notionalValue = _getNotional({
                            _symbolItem: symbolItems[productName],
                            _quantity: Number(detail?.longPosition) - Number(detail?.shortPosition),
                            _price: _pricings?.[productName],
                            _BTCUSDIndexLastPrice: _pricings?.['btc_usdc_BINANCE_SPT']
                        })
                        result = notionalValue * Number(_getTokenPriceInCurrency({
                            _token: quote,
                            _lastPricePerSymbol: _pricings,
                            _currency: homeCurrency,
                            _shouldReferencePerpPrice: true
                        }))
                    }
                    return sum + result
                }, 0)

                // Replace the coin's group with an object containing aggregated data
                exposureGroups[coin] = {
                    coin,
                    // Group items in this coin group by accountName
                    accounts: itemsByCoin.reduce((acc, item) => {
                        const accountName = item.accountName
                        if (!acc[accountName]) acc[accountName] = []
                        acc[accountName].push(item)
                        return acc
                    }, {}),
                    exposureSum,
                    coinPrice: itemsByCoin?.[0]?.coinPrice,
                    exposureSumInUSD,
                    initialExposure,
                    netExposure,
                    netExposureInUSD: coinPrice ? netExposure * coinPrice : null,
                    positionNotionalSum,
                    threshold: Math.max(
                        Math.abs(positionNotionalSum * Number(exposureVariables?.positionRatio)),
                        (exposureVariables?.coinThresholdMinValues && coin in exposureVariables.coinThresholdMinValues)
                            ? Number(exposureVariables.coinThresholdMinValues[coin])
                            : DEFAULT_COIN_THRESHOLD_MIN_VALUE
                    )
                }

                // Process each account group within this coin group
                Object.entries(exposureGroups[coin].accounts).forEach(([accountName, itemsByAccount]) => {
                    const accountExposureSum = itemsByAccount.reduce((sum, item) => sum + Number(item.exposure || 0), 0)
                    const accountExposureSumInUSD = itemsByAccount.reduce((sum, item) => sum + Number(item.exposureInUSD || 0), 0)
                    exposureGroups[coin].accounts[accountName] = {
                        accountName,
                        items: _shouldSort ? _getSortedItems(itemsByAccount) : itemsByAccount,
                        exposureSum: accountExposureSum,
                        exposureSumInUSD: accountExposureSumInUSD
                    }
                })
            })
        } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
            // Group exposureItems by accountName
            exposureGroups = seivedExposureItems.reduce((groups, item) => {
                const accountName = item.accountName
                if (!groups[accountName]) groups[accountName] = []
                groups[accountName].push(item)
                return groups
            }, {})
          
            // Process each account group
            Object.entries(exposureGroups).forEach(([accountName, itemsByAccount]) => {
                const accountExposureSumInUSD = itemsByAccount.reduce((sum, item) => sum + Number(item.exposureInUSD || 0), 0)
          
                exposureGroups[accountName] = {
                    accountName,
                    coins: {},
                    exposureSumInUSD: accountExposureSumInUSD
                }

                // Group the account's items by coin
                const coinsGroup = itemsByAccount.reduce((coins, item) => {
                    const coin = item.coin
                    if (!coins[coin]) coins[coin] = []
                    coins[coin].push(item)
                    return coins
                }, {})
          
                // Process each coin group within this account
                Object.entries(coinsGroup).forEach(([coin, itemsByCoin]) => {
                    const coinExposureSum = itemsByCoin.reduce((sum, item) => sum + Number(item.exposure || 0), 0)
                    const coinExposureSumInUSD = itemsByCoin.reduce((sum, item) => sum + Number(item.exposureInUSD || 0), 0)
                    exposureGroups[accountName].coins[coin] = {
                        coin,
                        items: _shouldSort ? _getSortedItems(itemsByCoin) : itemsByCoin,
                        coinPrice: itemsByCoin?.[0]?.coinPrice,
                        exposureSum: coinExposureSum,
                        exposureSumInUSD: coinExposureSumInUSD
                    }
                })
            })
        }

        return exposureGroups
    }

    result.exposureGroupsToCompare = _organizeExposureGroups({
        _mode: mode,
        _groupBy: groupBy,
        _shouldSort: false,
        _exposureItems: exposureItemsToCompare,
        _initialBalance: initialBalanceToCompare,
        _pricings: pricingsToCompare
    })

    const exposureGroups = Object.values(
        _organizeExposureGroups({
            _mode: mode,
            _groupBy: groupBy,
            _shouldSort: true,
            _exposureItems: exposureItems,
            _initialBalance: initialBalance,
            _pricings: pricings
        })
    )

    // Sort exposure groups
    if (groupBy === TABLE_GROUP_BYS.COIN) {
        // For each coinExposure, first sort its accounts array
        exposureGroups.forEach(coinExposure => {
            coinExposure.accounts = _sortBy(Object.values(coinExposure.accounts), accountExposure => {
                if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE) {
                    return -Math.abs(accountExposure.exposureSum)
                } else if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD) {
                    return -Math.abs(accountExposure.exposureSumInUSD)
                } else {
                    return accountExposure.accountName
                }
            })
        })

        // Sort exposureGroups
        result.sortedExposureGroups = _sortBy(exposureGroups, coinExposure => {
            if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE) {
                return -Math.abs(coinExposure.exposureSum)
            } else if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE_IN_USD) {
                return -Math.abs(coinExposure.exposureSumInUSD)
            } else if (coinSortBy === COIN_SORT_BYS.INITIAL_BALANCE) {
                return -Math.abs(coinExposure.initialExposure)
            } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_ASSET) {
                return -Math.abs(coinExposure.netExposure)
            } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_USD) {
                return -Math.abs(coinExposure.netExposureInUSD)
            } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_DIFF_IN_USD) {
                // Assuming exposureGroupsToCompare structure is like { [coin]: { netExposure: value } }
                const netExposureToCompare =
                    result.exposureGroupsToCompare?.[coinExposure.coin] &&
                    result.exposureGroupsToCompare[coinExposure.coin].netExposure !== undefined
                    ? Number(result.exposureGroupsToCompare[coinExposure.coin].netExposure)
                    : 0
                const coinPrice = Number(
                    _getTokenPriceInCurrency({
                        _token: coinExposure.coin,
                        _lastPricePerSymbol: pricings,
                        _currency: homeCurrency,
                        _shouldReferencePerpPrice: true
                    })
                )
                return -Math.abs((coinExposure.netExposure - netExposureToCompare) * coinPrice)
            } else if (coinSortBy === COIN_SORT_BYS.POSITION_NOTIONAL_SUM) {
                return -Math.abs(coinExposure.positionNotionalSum)
            } else {
                return coinExposure.coin
            }
        })
    } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
        // For each accountExposure in exposureGroups, sort its coins array
        exposureGroups.forEach(accountExposure => {
            accountExposure.coins = _sortBy(Object.values(accountExposure.coins), coinExposure => {
                if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE) {
                    return -Math.abs(coinExposure.exposureSum)
                } else if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE_IN_USD) {
                    return -Math.abs(coinExposure.exposureSumInUSD)
                } else {
                    return coinExposure.coin
                }
            })
        })

        // Sort exposureGroups based on accountExposure properties
        result.sortedExposureGroups = _sortBy(exposureGroups, accountExposure => {
            if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD) {
                return -Math.abs(accountExposure.exposureSumInUSD)
            } else if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_DIFF_IN_USD) {
                // Assuming exposureGroupsToCompare structure is like { [accountName]: { exposureSumInUSD: value } }
                const exposureSumInUSDToCompare =
                    result.exposureGroupsToCompare?.[accountExposure.accountName] &&
                    result.exposureGroupsToCompare[accountExposure.accountName].exposureSumInUSD !== undefined
                    ? Number(result.exposureGroupsToCompare[accountExposure.accountName].exposureSumInUSD)
                    : 0
                return -Math.abs(accountExposure.exposureSumInUSD - exposureSumInUSDToCompare)
            } else {
                return accountExposure.accountName
            }
        })
    }

    const exposureGroupsToCalculateNAV = mode === MODES.NAV && groupBy === TABLE_GROUP_BYS.COIN
        ? exposureGroups
        : _organizeExposureGroups({
            _mode: MODES.NAV,
            _groupBy: TABLE_GROUP_BYS.COIN,
            _shouldSort: false,
            _exposureItems: exposureItems,
            _initialBalance: initialBalance,
            _pricings: pricings
        })

    Object.values(exposureGroupsToCalculateNAV).forEach(coinExposure => {
        const { exposureSumInUSD, netExposureInUSD } = coinExposure
        result.totalNAV += (exposureSumInUSD || 0)
        result.totalPnL += (netExposureInUSD || 0)
    })

    return result
})

class ExposureTable extends Component {
    constructor (props) {
        super(props)

        this.state = {
            groupBy: props.tableGroupBy,
            expandedPaths: props.config.expandedPaths || [], // Valid path can be: 'coin' || 'coin.accountName',
            coinSortBy: props.config.coinSortBy || this.getSessionStorageCoinSortBy(),
            accountSortBy: props.config.accountSortBy || this.getSessionStorageAccountSortBy(),
            itemSortBy: props.config.itemSortBy || this.getSessionStorageItemSortBy(),
            shouldShowFileDownloadPopup: false,

            isCalculatingExposureReport: false,
            exposureReport: {
                sortedExposureGroups: [],
                exposureGroupsToCompare: {},
                totalNAV: 0,
                totalPnL: 0
            }
        }

        this.STATE_CONFIG_KEYS = ['groupBy', 'expandedPaths', 'coinSortBy', 'accountSortBy', 'itemSortBy']

        this.editingVariables = {
            positionRatio: null,
            coinThresholdMinValues: {}
        }

        this._mounted = true
        this._updateReportId = null
        this._isExposreReportUpdated = false
    }

    static getDerivedStateFromProps (props, state) {
        if (!_.isEqual(props.tableGroupBy, state.groupBy)) {
            return {
                groupBy: props.tableGroupBy,
                expandedPaths: []
            }
        } else {
            return null
        }
    }

    componentDidMount () {
        this._mounted = true
        this.updateExposureReport()
    }

    componentDidUpdate(prevProps, prevState) {
        const { onChangeConfig, config } = this.props
        const prevConfigState = _.pick(prevState, this.STATE_CONFIG_KEYS)
        const configState = _.pick(this.state, this.STATE_CONFIG_KEYS)

        if (!prevState.isCalculatingExposureReport && this.state.isCalculatingExposureReport) {
            return
        }
      
        // If the exposure report was updated, reset the flag and return early
        if (this._isExposreReportUpdated) {
            this._isExposreReportUpdated = false
            return
        }
      
        // If props config has changed and differs from state config, merge state with new config
        if (!_.isEqual(prevProps.config, config) && !_.isEqual(config, configState)) {
           this.mergeState(config)
           return
        }

        // Update exposure report and, if config state has changed, call onChangeConfig
        this.updateExposureReport()
        if (!_.isEqual(prevConfigState, configState)) {
            onChangeConfig(configState)
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    mergeState (newState) {
        this.setState((prevState) => {
            return Object.assign({}, prevState, newState)
        })
    }

    async updateExposureReport () {
        const { coinSortBy, accountSortBy, itemSortBy, isCalculatingExposureReport } = this.state
        const {
            mode, tableGroupBy, portfolioNames, shouldHideFTX, homeCurrency,
            exposureItems, exposureItemsToCompare, initialBalance, initialBalanceToCompare, pricings, pricingsToCompare,
            accountItems, symbolItems, exposureVariables
        } = this.props

        const updateReportId = nanoid() 
        this._updateReportId = updateReportId
        try {

            if (!isCalculatingExposureReport) {
                this.setState({ isCalculatingExposureReport: true })
            }

            const exposureReport = await getExposureReport({
                mode,
                groupBy: tableGroupBy,
                portfolioNames,
                homeCurrency,
                shouldHideFTX,
                coinSortBy,
                accountSortBy,
                itemSortBy,
                exposureItems,
                initialBalance,
                pricings,
                exposureItemsToCompare,
                initialBalanceToCompare,
                pricingsToCompare,
                accountItems,
                symbolItems,
                exposureVariables
            })

            if (this._mounted && this._updateReportId === updateReportId) {
                this._isExposreReportUpdated = true
                this.setState({ exposureReport })
            }
        } catch (error) {
            console.error(`ExposureTable updateExposureReport error: `, error)
        } finally {
            if (this._mounted && this._updateReportId === updateReportId) {
                this.setState({ isCalculatingExposureReport: false })
            }
        }
    }

    getSessionStorageCoinSortBy () {
        const coinSortBy = sessionStorage.exposureTableCoinSortBy
        return _.has(COIN_SORT_BYS, coinSortBy) ? coinSortBy : Object.keys(COIN_SORT_BYS)[0]
    }

    updateCoinSortBy (coinSortBy) {
        if (_.has(COIN_SORT_BYS, coinSortBy)) {
            sessionStorage.exposureTableCoinSortBy = coinSortBy
            this.setState({ coinSortBy })
        }
    }

    getSessionStorageAccountSortBy () {
        const accountSortBy = sessionStorage.exposureTableAccountSortBy
        return _.has(ACCOUNT_SORT_BYS, accountSortBy) ? accountSortBy : Object.keys(ACCOUNT_SORT_BYS)[0]
    }

    updateAccountSortBy (accountSortBy) {
        if (_.has(ACCOUNT_SORT_BYS, accountSortBy)) {
            sessionStorage.exposureTableAccountSortBy = accountSortBy
            this.setState({ accountSortBy })
        }
    }

    getSessionStorageItemSortBy () {
        const itemSortBy = sessionStorage.exposureTableItemSortBy
        return _.has(ITEM_SORT_BYS, itemSortBy) ? itemSortBy : Object.keys(ITEM_SORT_BYS)[0]
    }

    updateItemSortBy (itemSortBy) {
        if (_.has(ITEM_SORT_BYS, itemSortBy)) {
            sessionStorage.exposureTableItemSortBy = itemSortBy
            this.setState({ itemSortBy })
        }
    }

    isLevel1Expanded () {
        const { expandedPaths } = this.state
        return _.some(expandedPaths, path => path.length > 0 && !path.includes('.'))
    }

    isLevel2Expanded () {
        const { expandedPaths } = this.state
        const expandedLevel1Items = expandedPaths.filter(path => path.length > 0 && !path.includes('.'))
        return _.some(expandedPaths, path => path.length > 0 && path.includes('.') && _.includes(expandedLevel1Items, path.split('.')[0]))
    }

    _getInitialBalanceCoins (initialBalance={}) {
        const { portfolioNames } = this.props
        const coins = new Set()
        _.forEach(portfolioNames, _portfolio => {
            const portfolioInitialBalance = _.get(initialBalance, `initial_balance_${_portfolio}`, {})
            _.forEach(portfolioInitialBalance, (value, coin) => {
                if (coin !== 'acct_name' && coin !== 'timestamp' && Number(value) !== 0) {
                    coins.add(_.toUpper(coin))
                }
            })
        })

        return Array.from(coins)
    }

    // _sortExposureGroups ({ exposureGroups={}, groupBy=TABLE_GROUP_BYS.COIN }) {
    //     const { accountSortBy, coinSortBy } = this.state
    //     const { pricings, exposureItemsToCompare, initialBalanceToCompare, pricingsToCompare, mode, homeCurrency } = this.props

    //     let sortedExposureGroups = null, exposureGroupsToCompare = null
    //     if (groupBy === TABLE_GROUP_BYS.COIN) {
    //         if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_DIFF_IN_USD) {
    //             exposureGroupsToCompare = this.getExposureGroups({
    //                 exposureItems: exposureItemsToCompare,
    //                 initialBalance: initialBalanceToCompare,
    //                 pricings: pricingsToCompare,
    //                 groupBy,
    //                 mode,
    //                 shouldSort: false
    //             })
    //         }

    //         _.forEach(exposureGroups, coinExposure => {
    //             coinExposure.accounts = _.sortBy(coinExposure.accounts, accountExposure => {
    //                 if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE) {
    //                     return -Math.abs(accountExposure.exposureSum)
    //                 } else if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD) {
    //                     return -Math.abs(accountExposure.exposureSumInUSD)
    //                 } else {
    //                     return accountExposure.accountName
    //                 }
    //             })
    //         })
    //         sortedExposureGroups = _.sortBy(exposureGroups, coinExposure => {
    //             if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE) {
    //                 return -Math.abs(coinExposure.exposureSum)
    //             } else if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE_IN_USD) {
    //                 return -Math.abs(coinExposure.exposureSumInUSD)
    //             } else if (coinSortBy === COIN_SORT_BYS.INITIAL_BALANCE) {
    //                 return -Math.abs(coinExposure.initialExposure)
    //             } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_ASSET) {
    //                 return -Math.abs(coinExposure.netExposure)
    //             } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_USD) {
    //                 return -Math.abs(coinExposure.netExposureInUSD)
    //             } else if (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_DIFF_IN_USD) {
    //                 const netExposureToCompare = _.has(exposureGroupsToCompare, `${coinExposure.coin}.netExposure`) ? Number(exposureGroupsToCompare[coinExposure.coin].netExposure) : 0
    //                 const coinPrice = getTokenPriceInCurrency({ token: coinExposure.coin, lastPricePerSymbol: pricings, currency: homeCurrency, shouldReferencePerpPrice: true })
    //                 return -Math.abs((coinExposure.netExposure - netExposureToCompare) * Number(coinPrice)) 
    //             } else if (coinSortBy === COIN_SORT_BYS.POSITION_NOTIONAL_SUM) {
    //                 return -Math.abs(coinExposure.positionNotionalSum)
    //             } else {
    //                 return coinExposure.coin
    //             }
    //         })
    //     } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
    //         if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_DIFF_IN_USD) {
    //             exposureGroupsToCompare = this.getExposureGroups({
    //                 exposureItems: exposureItemsToCompare,
    //                 initialBalance: initialBalanceToCompare,
    //                 pricings: pricingsToCompare,
    //                 groupBy,
    //                 mode,
    //                 shouldSort: false
    //             })
    //         }
    //         _.forEach(exposureGroups, accountExposure => {
    //             accountExposure.coins = _.sortBy(accountExposure.coins, coinExposure => {
    //                 if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE) {
    //                     return -Math.abs(coinExposure.exposureSum)
    //                 } else if (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE_IN_USD) {
    //                     return -Math.abs(coinExposure.exposureSumInUSD)
    //                 } else {
    //                     return coinExposure.coin
    //                 }
    //             })
    //         })
    //         sortedExposureGroups = _.sortBy(exposureGroups, accountExposure => {
    //             if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD) {
    //                 return -Math.abs(accountExposure.exposureSumInUSD)
    //             } else if (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_DIFF_IN_USD) {
    //                 const exposureSumInUSDToCompare = _.has(exposureGroupsToCompare, `${accountExposure.accountName}.exposureSumInUSD`) ? Number(exposureGroupsToCompare[accountExposure.accountName].exposureSumInUSD) : 0
    //                 return -Math.abs(accountExposure.exposureSumInUSD - exposureSumInUSDToCompare)
    //             } else {
    //                 return accountExposure.accountName
    //             }
    //         })
    //     }

    //     return _.isArray(sortedExposureGroups) ? sortedExposureGroups : Object.values(exposureGroups)
    // }

    // getExposureGroups ({ exposureItems=[], initialBalance={}, pricings={}, groupBy=TABLE_GROUP_BYS.COIN, mode=MODES.EXPOSURE, shouldSort=true }) {
    //     const { portfolioName, accountItems, symbolItems, exposureVariables, shouldHideFTX, homeCurrency } = this.props
    //     const { itemSortBy } = this.state

    //     const getSortedItems = (exposureItems) => {
    //         return _.sortBy(exposureItems, item => {
    //             if (itemSortBy === ITEM_SORT_BYS.EXPOSURE) {
    //                 return -Math.abs(item.exposure)
    //             } else if (itemSortBy === ITEM_SORT_BYS.TIME) {
    //                 return moment(item.timestamp).valueOf()
    //             } else if (itemSortBy === ITEM_SORT_BYS.SOURCE) {
    //                 return item.source
    //             } else {
    //                 return null
    //             }
    //         })
    //     }

    //     let exposureGroups = {}
    //     if (shouldHideFTX) {
    //         exposureItems = _.filter(exposureItems, item => {
    //             const { exchange_name } = _.get(accountItems, item.accountName) || {}
    //             return exchange_name !== 'FTX'
    //         })
    //     }
    //     exposureItems = mode === MODES.NAV ? _.filter(exposureItems, item => !['POSITION', 'TREASURY_EXPOSURE_OFFSET', 'TREASURY_EXPOSURE', 'DEFI_BROKER_EXPOSURE_OFFSET', 'DEFI_BROKER_EXPOSURE'].includes(item.source)) 
    //         : mode === MODES.EXPOSURE ? _.filter(exposureItems, item => !['TREASURY_PNL', 'TREASURY_PNL_OFFSET', 'DEFI_BROKER_PNL', 'DEFI_BROKER_PNL_OFFSET'].includes(item.source))
    //         : exposureItems
    
    //     exposureItems = _.filter(exposureItems, item => {
    //         return _.isEmpty(portfolioName) || portfolioName === ALL 
    //             || item.portfolioName === portfolioName
    //             || (_.isEmpty(item.portfolioName) && _.get(accountItems, `${item.accountName}.portfolio_name`) === portfolioName)
    //     }).map(item => {
    //         const { coin, exposure, equity } = item
    //         const coinPrice = getTokenPriceInCurrency({ token: coin, lastPricePerSymbol: pricings, currency: homeCurrency, shouldReferencePerpPrice: true })
                    
    //         if (mode === MODES.NAV) {
    //             const _equity = equity ?? exposure
    //             return {
    //                 ...item,
    //                 exposure: _equity,
    //                 exposureInUSD: coinPrice ? Number(_equity || 0) * coinPrice : null
    //             }
    //         } else {
    //             return {
    //                 ...item,
    //                 exposureInUSD: coinPrice ? exposure * coinPrice : null
    //             }
    //         }
    //     })

    //     if (groupBy === TABLE_GROUP_BYS.COIN) {
    //         exposureGroups = _.groupBy(exposureItems, 'coin')
    //         const initialBalanceCoins = this._getInitialBalanceCoins(initialBalance)
    //         _.forEach(initialBalanceCoins, coin => {
    //             if (!_.has(exposureGroups, coin)) {
    //                 exposureGroups[coin] = []
    //             }
    //         })

    //         _.forEach(exposureGroups, (exposureItemsInSameCoin, coin) => {
    //             const coinPrice = getTokenPriceInCurrency({ token: coin, lastPricePerSymbol: pricings, currency: homeCurrency, shouldReferencePerpPrice: true })
    //             const exposureSum = _.sumBy(exposureItemsInSameCoin, 'exposure')
    //             const exposureSumInUSD = _.sumBy(exposureItemsInSameCoin, 'exposureInUSD')
    //             let initialExposure = null
    //             if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
    //                 initialExposure = _.has(initialBalance, `initial_balance_${portfolioName}.${_.toLower(coin)}`)
    //                     ? initialBalance[`initial_balance_${portfolioName}`][_.toLower(coin)]
    //                     : null
    //             } else {
    //                 _.forEach(initialBalance, portfolioInitialBalance => {
    //                     if (_.has(portfolioInitialBalance, _.toLower(coin))) {
    //                         initialExposure = (initialExposure || 0) + Number(portfolioInitialBalance[_.toLower(coin)])
    //                     }
    //                 })
    //             }
    //             const netExposure = exposureSum - (initialExposure || 0)

    //             const positionNotionalSum = _.sumBy(exposureItemsInSameCoin, exposureItem => {
    //                 const { source, productName, detail } = exposureItem
    //                 const { base, quote } = getSymbolAttributeByName(productName || '')

    //                 let result = 0
    //                 if (source === 'POSITION' && base === coin) {
    //                     const notionalValue = getNotional({
    //                         symbolItem: symbolItems[productName],
    //                         quantity: Number(detail.longPosition) - Number(detail.shortPosition),
    //                         price: pricings[productName],
    //                         BTCUSDIndexLastPrice: _.get(pricings, `btc_usdc_BINANCE_SPT`)
    //                     })
    //                     result = notionalValue * Number(getTokenPriceInCurrency({ token: quote, lastPricePerSymbol: pricings, currency: homeCurrency, shouldReferencePerpPrice: true }))
    //                 }
    //                 return result
    //             })

    //             exposureGroups[coin] = {
    //                 coin,
    //                 accounts: _.groupBy(exposureItemsInSameCoin, 'accountName'),
    //                 exposureSum,
    //                 exposureSumInUSD,
    //                 initialExposure,
    //                 netExposure,
    //                 netExposureInUSD: coinPrice ? netExposure * coinPrice : null,
    //                 positionNotionalSum,
    //                 threshold: Math.max(Math.abs(positionNotionalSum * Number(exposureVariables.positionRatio)), 
    //                     _.has(exposureVariables.coinThresholdMinValues, coin) ? Number(exposureVariables.coinThresholdMinValues[coin]) : DEFAULT_COIN_THRESHOLD_MIN_VALUE)
    //             }
    //             _.forEach(exposureGroups[coin].accounts, (exposureItemsInSameAccount, accountName) => {
    //                 const exposureSum = _.sumBy(exposureItemsInSameAccount, 'exposure')
    //                 const exposureSumInUSD = _.sumBy(exposureItemsInSameAccount, 'exposureInUSD')
    //                 exposureGroups[coin].accounts[accountName] = {
    //                     accountName,
    //                     items: shouldSort ? getSortedItems(exposureItemsInSameAccount) : exposureItemsInSameAccount,
    //                     exposureSum,
    //                     exposureSumInUSD
    //                 }
    //             })
    //         })
    //     } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
    //         exposureGroups = _.groupBy(exposureItems, 'accountName')
    //         _.forEach(exposureGroups, (exposureItemsInSameAccount, accountName) => {
    //             const exposureSumInUSD = _.sumBy(exposureItemsInSameAccount, 'exposureInUSD')
    //             exposureGroups[accountName] = {
    //                 accountName,
    //                 coins: _.groupBy(exposureItemsInSameAccount, 'coin'),
    //                 exposureSumInUSD
    //             }
    //             _.forEach(exposureGroups[accountName].coins, (exposureItemsInSameCoin, coin) => {
    //                 const exposureSum = _.sumBy(exposureItemsInSameCoin, 'exposure')
    //                 const exposureSumInUSD = _.sumBy(exposureItemsInSameCoin, 'exposureInUSD')
    //                 exposureGroups[accountName].coins[coin] = {
    //                     coin,
    //                     items: shouldSort ? getSortedItems(exposureItemsInSameCoin) : exposureItemsInSameCoin,
    //                     exposureSum,
    //                     exposureSumInUSD
    //                 }
    //             })
    //         })
    //     }
    //     return shouldSort ? this._sortExposureGroups({ exposureGroups, groupBy }) : exposureGroups
    // }

    handleClickSaveButton () {
        const { dispatch } = this.props
        dispatch(updateExposureVariables(this.editingVariables))
        .then(() => { this.setState({ canEditExposureThresholdParams: false }) })
    }

    FileDownload ({ filename }) {
        const { exposureItems, pricings, initialBalance, portfolioNames, accountItems, homeCurrency } = this.props
        const pickedInitialBalance = _.pickBy(initialBalance, (_v, _key) => {
            const _portfolio = _.last(_key.split('_'))
            return _portfolio !== 'treasury' && portfolioNames.includes(_portfolio)
        })

        const filteredExposureItems = _.filter(exposureItems, item => {
            return portfolioNames.includes(item.portfolioName) ||
                (_.has(accountItems, item.accountName) && portfolioNames.includes(accountItems[item.accountName].portfolio_name))
        })

        const flatExposureItems = _.map(filteredExposureItems, (item, index) => {
            const  { coin, detail, accountName, portfolioName: itemPortfolioName } = item
            let initialBalanceString = ''
            const detailString = _.reduce(detail, (result, value, key) => {
                if (!_.isNil(value)) {
                    result += (`${_.isEmpty(result) ? '' : ', '}${key}: ${value}`)
                }
                return result
            }, '')

            if (index === 0) {
                let coinInitialBalance = {}
                _.forEach(pickedInitialBalance, portfolioInitialBalance => {
                    _.forEach(portfolioInitialBalance, (value, coin) => {
                        if (coin !== 'acct_name' && coin !== 'timestamp') {
                            coinInitialBalance[coin] = (coinInitialBalance[coin] || 0) + value
                        }
                    })
                })
                initialBalanceString = _.reduce(coinInitialBalance, (result, value, key) => {
                    if (!_.isNil(value)) {
                        result += (`${_.isEmpty(result) ? '' : ', '}${key}: ${value}`)
                    }
                    return result
                }, '')
            }

            return Object.assign({}, item, { 
                portfolioName: itemPortfolioName || (_.has(accountItems, accountName) ? accountItems[accountName].portfolio_name : 'UNKOWN'),
                detailString, 
                initialBalanceString, 
                coinPrice: getTokenPriceInCurrency({ token: coin, lastPricePerSymbol: pricings, currency: homeCurrency, shouldReferencePerpPrice: true })
            })
        })

        const headers = [
            { label: 'Account', key: 'accountName' },
            { label: 'Portfolio', key: 'portfolioName' },
            { label: 'Coin', key: 'coin' },
            { label: 'Exposure', key: 'exposure' },
            { label: `Coin Price (${homeCurrency})`, key: 'coinPrice' },
            { label: 'Time', key: 'timestamp' },
            { label: 'Source', key: 'source' },
            { label: 'Symbol', key: 'productName' },
            { label: 'detail', key: 'detailString' },
            { label: '', key: 'NULL' },
            { label: 'Initial Balance', key: 'initialBalanceString' }
        ]

        return _.size(headers) > 0 && (
            <div className='exposure-table--file-download'>
                <CSVLink 
                    filename={filename}
                    data={flatExposureItems} 
                    headers={headers}>
                    <button>{'Downlaod CSV'}</button>
                </CSVLink>
            </div>
        )
    }

    ExposureTableData ({ value, diff=0, defaultPrecision=2, highlightThreshold=null }) {
        return (
            <td className={'exposure-table--exposure-data' + (value > 0 ? ' positive' : value < 0 ? ' negative' : '') + (_.isNumber(highlightThreshold) && Math.abs(value) >= highlightThreshold ? ' highlight' : '')}>
                {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true, defaultPrecision }) : 'N/A'}
                {Number(diff) !== 0 && <span className={'exposure-table--exposure-data--diff' + (diff > 0 ? ' positive' : ' negative')}>
                    {`(${diff > 0 ? '+' : ''}${toNumberWithSmartPrecision({ number: diff, shouldReturnLocalString: true, defaultPrecision })})`}
                </span>}
            </td>
        )
    }

    TableGroupByAccount () {
        const { exposureItems, comparisonRules, uniqueExposureBackgroundColor, mode, csvFileName, homeCurrency } = this.props
        const { expandedPaths, accountSortBy, coinSortBy, itemSortBy, shouldShowFileDownloadPopup, exposureReport, isCalculatingExposureReport } = this.state
        // const pickedInitialBalance = _.omit(initialBalance, ['initial_balance_treasury']) 
        // const pickedInitialBalanceToCompare = _.omit(initialBalanceToCompare, ['initial_balance_treasury'])
        const isLevel1Expanded = this.isLevel1Expanded()
        const isLevel2Expanded = this.isLevel2Expanded()

        const { sortedExposureGroups, exposureGroupsToCompare, totalNAV, totalPnL } = exposureReport
        // const exposureGroups = this.getExposureGroups({
        //     exposureItems,
        //     initialBalance: pickedInitialBalance,
        //     pricings,
        //     groupBy: TABLE_GROUP_BYS.ACCOUNT,
        //     mode,
        //     shouldSort: true
        // })

        // const exposureGroupsToCompare = this.getExposureGroups({
        //     exposureItems: exposureItemsToCompare,
        //     initialBalance: pickedInitialBalanceToCompare,
        //     pricings: pricingsToCompare,
        //     groupBy: TABLE_GROUP_BYS.ACCOUNT,
        //     mode,
        //     shouldSort: false
        // })
        // const accountNamesToCompare = Object.keys(exposureGroupsToCompare)

        // let totalNAV = 0, totalPnL = 0
        // const exposureGroupsToCalculateNAV = this.getExposureGroups({
        //     exposureItems,
        //     initialBalance: pickedInitialBalance,
        //     pricings,
        //     groupBy: TABLE_GROUP_BYS.COIN,
        //     mode: MODES.NAV,
        //     shouldSort: false
        // })
        // _.forEach(exposureGroupsToCalculateNAV, coinExposure => {
        //     const { exposureSumInUSD, netExposureInUSD } = coinExposure
        //     totalNAV += (exposureSumInUSD || 0)
        //     totalPnL += (netExposureInUSD || 0)
        // })

        return (
            <table className='exposure-table group-by-account'>
                <thead className='exposure-table--head'>
                    <tr>
                        <td className='exposure-table--head--nav-stats' colSpan={'99'}>
                            <div>
                                <label>{'NAV'}</label>
                                <span>{`${toNumberWithSmartPrecision({ number: totalNAV, shouldReturnLocalString: true, defaultPrecision: 0 })} ${homeCurrency}`}</span>
                            </div>
                            <div>
                                <label>{'Total PnL'}</label>
                                <span>{`${toNumberWithSmartPrecision({ number: totalPnL, shouldReturnLocalString: true, defaultPrecision: 0 })} ${homeCurrency}`}</span>
                            </div>
                            {isCalculatingExposureReport
                                ? <ReactLoading className='exposure-table--head--loading'
                                    type={'spin'}
                                    color='#fff' />
                                : <div className='exposure-table--head--loading'/>}
                        </td>
                    </tr>
                    <tr className={'exposure-table--head--row has-stats'}>
                        <th className={'exposure-table--head-title level-0 index'}>
                            {'#'}
                            {!_.isEmpty(exposureItems) && <Popup on={'click'}
                                className='exposure-table--csv-download-popup'
                                trigger={<button className='exposure-table--csv-download-popup--trigger'><IoMdDownload /></button>}
                                onOpen={() => { this.setState({ shouldShowFileDownloadPopup: true }) }}
                                onClose={() => { this.setState({ shouldShowFileDownloadPopup: false }) }}>
                                {shouldShowFileDownloadPopup && this.FileDownload({ 
                                    filename: csvFileName || `EXPOSURE_SNAPSHOT_${moment().format('YYYY-MM-DD-HH_mm_ss')}.csv`
                                })}
                            </Popup>}
                        </th>
                        <th className={'exposure-table--head-title level-0 account sortable' + (accountSortBy === ACCOUNT_SORT_BYS.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(ACCOUNT_SORT_BYS.NAME) }}>{'Account'}</th>
                        {isLevel1Expanded && <th className={'exposure-table--head-title coin level-1 sortable' + (coinSortBy === COIN_SORT_BYS.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.NAME) }}>{'Coin'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source sortable' + (itemSortBy === ITEM_SORT_BYS.SOURCE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.SOURCE) }}>{'Source'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 timestamp sortable' + (itemSortBy === ITEM_SORT_BYS.TIME ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.TIME) }}>{'Time'}</th>}
                        {isLevel2Expanded && <th className='exposure-table--head-title level-2 detail'>{'Detail'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source-exposure sortable' + (itemSortBy === ITEM_SORT_BYS.EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.EXPOSURE) }}>{mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-exposure sortable' + ((coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE ? ' sorted' : ''))}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.SUM_EXPOSURE) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-price'}>{`Price (${homeCurrency})`}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-exposure-in-usd sortable' + (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE_IN_USD ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.SUM_EXPOSURE_IN_USD) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} (${homeCurrency})`}</th>}
                        <th className={'exposure-table--head-title account-exposure level-0 sortable' + (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(ACCOUNT_SORT_BYS.SUM_EXPOSURE_IN_USD) }}>{`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} (${homeCurrency})`}</th>
                        {comparisonRules.includes('EXPOSURE_DIFF') && 
                        <th className={'exposure-table--head-title account-exposure-diff level-0 sortable' + (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE_DIFF_IN_USD ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(ACCOUNT_SORT_BYS.SUM_EXPOSURE_DIFF_IN_USD) }}>
                            {`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} Diff (${homeCurrency})`}
                        </th>}
                    </tr>
                </thead> 
                {sortedExposureGroups.map((exposureGroup, index) => {
                    const { accountName } = exposureGroup
                    const isAccountExpanded = expandedPaths.includes(accountName)
                    const accountExposureToCompare = _.get(exposureGroupsToCompare, accountName)
                    const accountExposureSumInUSDDiff = comparisonRules.includes('EXPOSURE_DIFF') && accountExposureToCompare ? (exposureGroup.exposureSumInUSD - Number(accountExposureToCompare.exposureSumInUSD)) : null
                    const shouldShowAsUniqueExposureAccount = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !accountExposureToCompare
                    const exposurePerCoinToCompare = _.get(accountExposureToCompare, 'coins', {})
                    return (
                        <tbody className={`exposure-table--body ${accountName}`} key={accountName}>
                            <tr className={'exposure-table--level-1-row' + (isAccountExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureAccount ? ' unique-account' : '')}
                                style={{ backgroundColor: shouldShowAsUniqueExposureAccount ? uniqueExposureBackgroundColor : null }}
                                onClick={() => {
                                    this.setState({ expandedPaths: isAccountExpanded ? _.without(expandedPaths, accountName) : expandedPaths.concat([accountName]) })
                                }}>
                                <td>{index + 1}</td>
                                <td>{accountName}</td>
                                {isLevel1Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                {this.ExposureTableData({ value: exposureGroup.exposureSumInUSD, defaultPrecision: 0 })}
                                {comparisonRules.includes('EXPOSURE_DIFF') && this.ExposureTableData({ value: accountExposureSumInUSDDiff, defaultPrecision: 0 })}
                            </tr>
                            {isAccountExpanded && exposureGroup.coins.map(coinExposure => {
                                const { coin, coinPrice } = coinExposure
                                const isCoinExpanded = expandedPaths.includes(`${accountName}.${coin}`)
                                const coinExposureToCompare = _.get(exposurePerCoinToCompare, coin)
                                const coinExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (coinExposure.exposureSum - coinExposureToCompare.exposureSum) : null
                                const coinExposureSumInUSDDIff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (coinExposure.exposureSumInUSD - coinExposureToCompare.exposureSumInUSD) : null
                                const shouldShowAsUniqueExposureCoin = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !_.has(exposurePerCoinToCompare, coin)
                                return (
                                    <Fragment key={coin}>
                                        <tr className={'exposure-table--level-2-row' + (isCoinExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureCoin ? ' unique-coin' : '')} 
                                            style={{ backgroundColor: shouldShowAsUniqueExposureCoin ? uniqueExposureBackgroundColor : null }}
                                            onClick={() => {
                                                const path = `${accountName}.${coin}`
                                                this.setState({ expandedPaths: isCoinExpanded ? _.without(expandedPaths, path) : expandedPaths.concat([path]) })
                                            }}>
                                            <Fragment><td /><td /></Fragment>
                                            <td className='exposure-table--coin'>{coin}<span className='exposure-table--level-2-row--count'>{`(${coinExposure.items.length})`}</span></td>
                                            {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                            {this.ExposureTableData({ value: coinExposure.exposureSum, diff: coinExposureSumDiff })}
                                            <td className='right-align'>{coinPrice ? toNumberWithSmartPrecision({ number: coinPrice, defaultPrecision: 5 }) : 'N/A'}</td>
                                            {this.ExposureTableData({ value: coinExposure.exposureSumInUSD, diff: coinExposureSumInUSDDIff, defaultPrecision: 0 })}
                                        </tr>
                                        {isCoinExpanded && coinExposure.items.map((exposureItem, index) => {
                                            const { source, exposure, pair, productName, target, detail, timestamp } = exposureItem
                                            const _target = target ?? detail?.target
                                            const exposureItemToCompare = coinExposureToCompare ? _.find(coinExposureToCompare.items, itemToCompare => {
                                                const _targetToCompare = itemToCompare.target ?? itemToCompare.detail?.target
                                                return itemToCompare.source === source 
                                                    && (_.isNil(pair) || itemToCompare.pair === pair)
                                                    && (_.isNil(productName) || itemToCompare.productName === productName)
                                                    && (_.isNil(_target) || _targetToCompare === _target)
                                            }) : null
                                            const exposureItemDiff = comparisonRules.includes('EXPOSURE_DIFF') && exposureItemToCompare ? (exposure - exposureItemToCompare.exposure) : null
                                            const shouldShowAsUniqueExposureItem = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && _.isNil(exposureItemToCompare)
                                            return (
                                                <tr className={'exposure-table--exposure-item-row' 
                                                + (index % 2 === 0 ? ' even-row' : ' odd-orw')
                                                + (index === 0 ? ' first-item-row' : '')
                                                + (index === coinExposure.items.length - 1 ? ' last-item-row' : '')
                                                + (shouldShowAsUniqueExposureItem ? ' unique-item' : '')} key={index}
                                                style={{ backgroundColor: shouldShowAsUniqueExposureItem ? uniqueExposureBackgroundColor : null }}>
                                                    <Fragment><td /><td /><td /></Fragment>
                                                    <td className='exposure-table--exposure-item-source'>
                                                        <span className={`exposure-table--exposure-item-source-type ${source}`}>{_.isString(source) ? source.replace(/_/g, ' ') : 'Unkown'}</span>
                                                        {pair || productName || ''}
                                                    </td>
                                                    <td>{moment(timestamp).format('HH:mm:ss')}</td>
                                                    <td className='exposure-table--exposure-item-detail'>
                                                        {_.map(exposureItem.detail, (value, key) => {
                                                            const name = Object.values(key).reduce((result, c) => {
                                                                result += (c === c.toUpperCase() ? ` ${c.toLowerCase()}` : c)
                                                                return result
                                                            }, '')
                                                            return !_.isNil(value) ? (
                                                                <div className='exposure-table--exposure-item-detail--block' key={key}>
                                                                    <span className='exposure-table--exposure-item-detail--name'>{name}</span>
                                                                    <span className='exposure-table--exposure-item-detail--value'>
                                                                        {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true }) : value}
                                                                    </span>
                                                                </div>
                                                            ) : null
                                                        })}
                                                    </td>
                                                    {this.ExposureTableData({ value: exposure, diff: exposureItemDiff })}
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    )
                })}
            </table>
        )
    }

    TableGroupByCoin () {
        const { portfolioNames, exposureItems, comparisonRules, uniqueExposureBackgroundColor,
            mode, exposureVariables, csvFileName, homeCurrency } = this.props
        const { expandedPaths, coinSortBy, accountSortBy, itemSortBy, canEditExposureThresholdParams,
            shouldShowFileDownloadPopup, exposureReport, isCalculatingExposureReport } = this.state

        const { sortedExposureGroups, exposureGroupsToCompare, totalNAV, totalPnL } = exposureReport
        // const pickedInitialBalance = _.omit(initialBalance, ['initial_balance_treasury']) 
        // const pickedInitialBalanceToCompare = _.omit(initialBalanceToCompare, ['initial_balance_treasury'])
        const isLevel1Expanded = this.isLevel1Expanded()
        const isLevel2Expanded = this.isLevel2Expanded()
        // const exposureGroups = this.getExposureGroups({
        //     exposureItems,
        //     initialBalance: pickedInitialBalance,
        //     pricings,
        //     groupBy: TABLE_GROUP_BYS.COIN,
        //     mode,
        //     shouldSort: true
        // })
        // const exposureGroupsToCompare = this.getExposureGroups({
        //     exposureItems: exposureItemsToCompare,
        //     initialBalance: pickedInitialBalanceToCompare,
        //     pricings: pricingsToCompare,
        //     groupBy: TABLE_GROUP_BYS.COIN,
        //     mode,
        //     shouldSort: false
        // })
        // const coinsToCompare = Object.keys(exposureGroupsToCompare)

        // let totalNAV = 0, totalPnL = 0
        // const exposureGroupsToCalculateNAV = mode === MODES.NAV 
        //     ? exposureGroups 
        //     : this.getExposureGroups({
        //         exposureItems,
        //         initialBalance: pickedInitialBalance,
        //         pricings,
        //         groupBy: TABLE_GROUP_BYS.COIN,
        //         mode: MODES.NAV,
        //         shouldSort: false
        //     })
        // _.forEach(exposureGroupsToCalculateNAV, coinExposure => {
        //     const { exposureSumInUSD, netExposureInUSD } = coinExposure
        //     totalNAV += (exposureSumInUSD || 0)
        //     totalPnL += (netExposureInUSD || 0)
        // })

        return (
            <table className='exposure-table group-by-coin'>
                <thead className='exposure-table--head'>
                    <tr>
                        <td className='exposure-table--head--nav-stats' colSpan={'99'}>
                            <div>
                                <label>{'NAV'}</label>
                                <span>{`${toNumberWithSmartPrecision({ number: totalNAV, shouldReturnLocalString: true, defaultPrecision: 0 })} ${homeCurrency}`}</span>
                            </div>
                            <div>
                                <label>{'Total PnL'}</label>
                                <span>{`${toNumberWithSmartPrecision({ number: totalPnL, shouldReturnLocalString: true, defaultPrecision: 0 })} ${homeCurrency}`}</span>
                            </div>
                            {isCalculatingExposureReport
                                ? <ReactLoading className='exposure-table--head--loading'
                                    type={'spin'}
                                    color='#fff' />
                                : <div className='exposure-table--head--loading'/>}
                        </td>
                    </tr>
                    <tr className={'exposure-table--head--row has-stats'}>
                        <th className={'exposure-table--head-title level-0 index'}>
                            {'#'}
                            {!_.isEmpty(exposureItems) && <Popup on={'click'}
                                className='exposure-table--csv-download-popup'
                                trigger={<button className='exposure-table--csv-download-popup--trigger'><IoMdDownload /></button>}
                                onOpen={() => { this.setState({ shouldShowFileDownloadPopup: true }) }}
                                onClose={() => { this.setState({ shouldShowFileDownloadPopup: false }) }}>
                                {shouldShowFileDownloadPopup && this.FileDownload({ 
                                    filename: csvFileName || `EXPOSURE_SNAPSHOT_${moment().format('YYYY-MM-DD-HH_mm_ss')}.csv`
                                })}
                            </Popup>}
                        </th>
                        <th className={'exposure-table--head-title level-0 sortable' + (coinSortBy === COIN_SORT_BYS.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.NAME) }}>{'Coin'}</th>
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 account sortable' + (accountSortBy === ACCOUNT_SORT_BYS.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(ACCOUNT_SORT_BYS.NAME) }}>{'Account'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source sortable' + (itemSortBy === ITEM_SORT_BYS.SOURCE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.SOURCE) }}>{'Source'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 timestamp sortable' + (itemSortBy === ITEM_SORT_BYS.TIME ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.TIME) }}>{'Time'}</th>}
                        {isLevel2Expanded && <th className='exposure-table--head-title level-2 detail'>{'Detail'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source-exposure sortable' + (itemSortBy === ITEM_SORT_BYS.EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(ITEM_SORT_BYS.EXPOSURE) }}>{mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 account-exposure sortable' + (accountSortBy === ACCOUNT_SORT_BYS.SUM_EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(ACCOUNT_SORT_BYS.SUM_EXPOSURE) }}>{`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>}
                        <th className={'exposure-table--head-title coin-exposure level-0 sortable' + (coinSortBy === COIN_SORT_BYS.SUM_EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.SUM_EXPOSURE) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>
                        <th className={'exposure-table--head-title initial-balance level-0 sortable' + (coinSortBy === COIN_SORT_BYS.INITIAL_BALANCE ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.INITIAL_BALANCE) }}>{'Initial Bal.'}</th>
                        <th className={'exposure-table--head-title net-exposure level-0 sortable' + (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_ASSET ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.NET_EXPOSURE_IN_ASSET) }}>{mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'}</th>
                        <th className={'exposure-table--head-title coin-price level-0'}>{`Price (${homeCurrency})`}</th>
                        <th className={'exposure-table--head-title net-exposure-in-usd level-0 sortable' + (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_IN_USD ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.NET_EXPOSURE_IN_USD) }}>{`${mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'} (${homeCurrency})`}</th>
                        {comparisonRules.includes('EXPOSURE_DIFF') && 
                        <Fragment>
                            <th className={'exposure-table--head-title coin-exposure-diff-in-usd level-0 sortable' + (coinSortBy === COIN_SORT_BYS.NET_EXPOSURE_DIFF_IN_USD ? ' sorted' : '')}
                                onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.NET_EXPOSURE_DIFF_IN_USD) }}>
                                {`${mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'} Diff (${homeCurrency})`}
                            </th>
                            {mode === MODES.EXPOSURE && <Fragment>
                                <th className={'exposure-table--head-title position-notional-sum level-0 sortable' + (coinSortBy === COIN_SORT_BYS.POSITION_NOTIONAL_SUM ? ' sorted' : '')}
                                onClick={() => { this.updateCoinSortBy(COIN_SORT_BYS.POSITION_NOTIONAL_SUM) }}>{`POS Notional(${homeCurrency})`}</th>
                                <th className={'exposure-table--head-title threshold level-0'}>{'THR.'}
                                    {!canEditExposureThresholdParams && <button className='edit' 
                                        onClick={() => { 
                                            this.editingVariables = _.cloneDeep(exposureVariables)
                                            this.setState({ canEditExposureThresholdParams: true }) }}>
                                        {'EDIT'}
                                    </button>}
                                </th>
                                {canEditExposureThresholdParams && 
                                <th className='exposure-table--head-title exposure-variables level-0'>
                                    <div className='exposure-table--variables--position-threshold'>
                                        <input type={'number'}
                                            min={0}
                                            max={100}
                                            placeholder={'Position Ratio'}
                                            defaultValue={_.isNumber(this.editingVariables.positionRatio) ? Math.round(this.editingVariables.positionRatio * 10000) / 100 : this.editingVariables.positionRatio} 
                                            onChange={(e) => { 
                                                const newValue = e.target.value.trim()
                                                this.editingVariables.positionRatio = newValue.length > 0 ? Math.round(newValue * 100) / 10000 : 0.01
                                            }} />
                                        <span>{'%'}</span>
                                    </div>
                                    <button className='save' onClick={() => { this.handleClickSaveButton() }}>{'SAVE'}</button>
                                    <button className='reset' onClick={() => { this.setState({ canEditExposureThresholdParams: false }) }}>{'RESET'}</button>
                                </th>}
                            </Fragment>}
                        </Fragment>}
                    </tr>
                </thead>
                {_.filter(
                    sortedExposureGroups,
                    exposureGroup => {
                        return !(mode === MODES.NAV && _.isEqual(portfolioNames, ['prop']) && ['USD', 'USDC'].includes(exposureGroup.coin))
                            && !(mode === MODES.EXPOSURE && _.isEqual(portfolioNames, ['treasury']) && !_.isNil(exposureGroup.netExposureInUSD) && Math.abs(exposureGroup.netExposureInUSD) < 0.1)
                    }
                ).map((exposureGroup, index) => {
                        const { coin, coinPrice, positionNotionalSum, threshold } = exposureGroup
                        const isCoinExpanded = expandedPaths.includes(coin)
                        const coinExposureToCompare = _.get(exposureGroupsToCompare, coin)
                        const coinExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (exposureGroup.exposureSum - coinExposureToCompare.exposureSum) : null
                        const coinNetExposureDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (exposureGroup.netExposure - coinExposureToCompare.netExposure) : null
                        const coinInitialExposureDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (Number(exposureGroup.initialExposure) - Number(coinExposureToCompare.initialExposure)) : null
                        const coinNetExposureDiffInUSD = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare && coinPrice ? coinNetExposureDiff * coinPrice : null
                        const shouldShowAsUniqueExposureCoin = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !coinExposureToCompare
                        const exposurePerAccountToCompare = _.get(coinExposureToCompare, 'accounts')
                        return (
                            <tbody className={`exposure-table--body ${coin}`} key={coin}>
                                <tr className={'exposure-table--level-1-row' + (isCoinExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureCoin ? ' unique-coin' : '')} 
                                    style={{ backgroundColor: shouldShowAsUniqueExposureCoin ? uniqueExposureBackgroundColor : null }}
                                    onClick={() => {
                                        this.setState({ expandedPaths: isCoinExpanded ? _.without(expandedPaths, coin) : expandedPaths.concat([coin]) })
                                    }}>
                                    <td>{index + 1}</td>
                                    <td>{coin}</td>
                                    {isLevel1Expanded && <Fragment><td /><td /></Fragment>}
                                    {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                    {this.ExposureTableData({ value: exposureGroup.exposureSum, diff: coinExposureSumDiff })}
                                    {this.ExposureTableData({ value: exposureGroup.initialExposure, diff: coinInitialExposureDiff })}
                                    {this.ExposureTableData({ value: exposureGroup.netExposure, diff: coinNetExposureDiff })}
                                    <td className='right-align'>{coinPrice ? toNumberWithSmartPrecision({ number: coinPrice, defaultPrecision: 5 }) : 'N/A'}</td>
                                    {this.ExposureTableData({ value: exposureGroup.netExposureInUSD || null, defaultPrecision: 0 })}
                                    {comparisonRules.includes('EXPOSURE_DIFF') && <Fragment>
                                        {this.ExposureTableData({ value: coinNetExposureDiffInUSD, defaultPrecision: 0, highlightThreshold: mode === MODES.EXPOSURE ? threshold : null })}
                                        {mode === MODES.EXPOSURE && <Fragment>
                                            {this.ExposureTableData({ value: positionNotionalSum, defaultPrecision: 0 })}
                                            <td>{toNumberWithSmartPrecision({ number: threshold, defaultPrecision: 0, shouldReturnLocalString: true })}</td>
                                            {canEditExposureThresholdParams && 
                                            <td className='exposure-table--threshold-min-value-input' onClick={(e) => { e.stopPropagation() }}>
                                                <input type={'number'}
                                                    min={0}
                                                    placeholder={'THR. Min Value'}
                                                    defaultValue={_.has(this.editingVariables.coinThresholdMinValues, coin) ? this.editingVariables.coinThresholdMinValues[coin] : DEFAULT_COIN_THRESHOLD_MIN_VALUE} 
                                                    onChange={(e) => { 
                                                        const newValue = e.target.value.trim()
                                                        this.editingVariables.coinThresholdMinValues[coin] = newValue.length > 0 ? Number(newValue) : DEFAULT_COIN_THRESHOLD_MIN_VALUE
                                                    }} /> 
                                            </td>}
                                        </Fragment>}
                                    </Fragment>}
                                </tr>
                                {isCoinExpanded && exposureGroup.accounts.map(accountExposure => {
                                    const { accountName } = accountExposure
                                    const isAccountExpanded = expandedPaths.includes(`${coin}.${accountName}`)
                                    const accountExposureToCompare = _.get(exposurePerAccountToCompare, accountName)
                                    const accountExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && accountExposureToCompare ? (accountExposure.exposureSum - accountExposureToCompare.exposureSum) : null
                                    const shouldShowAsUniqueExposureAccount = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !_.has(exposurePerAccountToCompare, accountName)
                                    return (
                                        <Fragment key={accountName}>
                                            <tr className={'exposure-table--level-2-row' + (isAccountExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureAccount ? ' unique-account' : '')} 
                                                style={{ backgroundColor: shouldShowAsUniqueExposureAccount ? uniqueExposureBackgroundColor : null }}
                                                onClick={() => {
                                                    const path = `${coin}.${accountName}`
                                                    this.setState({ expandedPaths: isAccountExpanded ? _.without(expandedPaths, path) : expandedPaths.concat([path]) })
                                                }}>
                                                <Fragment><td /><td /></Fragment>
                                                <td className='exposure-table--account-name'>{accountName}<span className='exposure-table--level-2-row--count'>{`(${accountExposure.items.length})`}</span></td>
                                                {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                                {this.ExposureTableData({ value: accountExposure.exposureSum, diff: accountExposureSumDiff })}
                                            </tr>
                                            {isAccountExpanded && accountExposure.items.map((exposureItem, index) => {
                                                const { source, exposure, pair, productName, target, detail, timestamp } = exposureItem
                                                const _target = target ?? detail?.target
                                                const exposureItemToCompare = accountExposureToCompare ? _.find(accountExposureToCompare.items, itemToCompare => {
                                                    const _targetToCompare = itemToCompare.target ?? itemToCompare.detail?.target
                                                    return itemToCompare.source === source 
                                                        && (_.isNil(pair) || itemToCompare.pair === pair)
                                                        && (_.isNil(productName) || itemToCompare.productName === productName)
                                                        && (_.isNil(_target) || _targetToCompare === _target)
                                                }) : null
                                                const exposureItemDiff = comparisonRules.includes('EXPOSURE_DIFF') && exposureItemToCompare ? (exposure - exposureItemToCompare.exposure) : null
                                                const shouldShowAsUniqueExposureItem = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && _.isNil(exposureItemToCompare)
                                                return (
                                                    <tr className={'exposure-table--exposure-item-row' 
                                                    + (index % 2 === 0 ? ' even-row' : ' odd-orw')
                                                    + (index === 0 ? ' first-item-row' : '')
                                                    + (index === accountExposure.items.length - 1 ? ' last-item-row' : '')
                                                    + (shouldShowAsUniqueExposureItem ? ' unique-item' : '')} key={index}
                                                    style={{ backgroundColor: shouldShowAsUniqueExposureItem ? uniqueExposureBackgroundColor : null }}>
                                                        <Fragment><td /><td /><td /></Fragment>
                                                        <td className='exposure-table--exposure-item-source'>
                                                            <span className={`exposure-table--exposure-item-source-type ${source}`}>{_.isString(source) ? source.replace(/_/g, ' ') : 'Unkown'}</span>
                                                            {pair || productName || ''}
                                                        </td>
                                                        <td>{moment(timestamp).format('HH:mm:ss')}</td>
                                                        <td className='exposure-table--exposure-item-detail'>
                                                            {_.map(exposureItem.detail, (value, key) => {
                                                                const name = Object.values(key).reduce((result, c) => {
                                                                    result += (c === c.toUpperCase() ? ` ${c.toLowerCase()}` : c)
                                                                    return result
                                                                }, '')
                                                                return value ? (
                                                                    <div className='exposure-table--exposure-item-detail--block' key={key}>
                                                                        <span className='exposure-table--exposure-item-detail--name'>{name}</span>
                                                                        <span className='exposure-table--exposure-item-detail--value'>
                                                                            {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true }) : value}
                                                                        </span>
                                                                    </div>
                                                                ) : null
                                                            })}
                                                        </td>
                                                        {this.ExposureTableData({ value: exposure, diff: exposureItemDiff })}
                                                    </tr>
                                                )
                                            })}
                                        </Fragment>
                                    )
                                })}
                            </tbody>
                        )
                })}
            </table>
        )
    } 

    render () {
        const { groupBy } = this.state
        return groupBy === TABLE_GROUP_BYS.ACCOUNT ? this.TableGroupByAccount() : this.TableGroupByCoin()
    }
}

ExposureTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    exposureVariables: PropTypes.object.isRequired,

    portfolioNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    mode: PropTypes.oneOf(Object.keys(MODES)),
    homeCurrency: PropTypes.oneOf(Object.values(EXPOSURE_HOME_CURRENY)).isRequired,
    tableGroupBy: PropTypes.oneOf(Object.keys(TABLE_GROUP_BYS)),
    exposureItems: PropTypes.array.isRequired,
    exposureItemsToCompare: PropTypes.array,
    initialBalance: PropTypes.object.isRequired,
    initialBalanceToCompare: PropTypes.object,
    pricings: PropTypes.object.isRequired,
    pricingsToCompare: PropTypes.object,
    comparisonRules: PropTypes.arrayOf(PropTypes.oneOf(['EXPOSURE_DIFF', 'UNIQUE_EXPOSURES'])),
    uniqueExposureBackgroundColor: PropTypes.string,
    config: PropTypes.shape({
        expandedPaths: PropTypes.array,
        coinSortBy: PropTypes.string,
        accountSortBy: PropTypes.string,
        itemSortBy: PropTypes.string
    }),
    csvFileName: PropTypes.string,
    shouldHideFTX: PropTypes.bool,
    onChangeConfig: PropTypes.func
}

ExposureTable.defaultProps = {
    mode: MODES.EXPOSURE,
    homeCurrency: EXPOSURE_HOME_CURRENY.USDC,
    tableGroupBy: TABLE_GROUP_BYS.COIN,
    exposureItems: [],
    exposureItemsToCompare: [],
    comparisonRules: [],
    uniqueExposureBackgroundColor: '#2e6f6f',
    config: {},
    onChangeConfig: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items,
        symbolItems: state.symbol.items,
        exposureVariables: state.trading.exposureVariables
    }
}

export default connect(mapStateToProps)(ExposureTable)