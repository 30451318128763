import React, { memo, useMemo, useState } from 'react'
import { useLocalStorage, useMountedState } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import BigNumber from 'bignumber.js'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { FaCaretDown, FaCaretLeft, FaCaretRight, FaCaretUp, FaXmark } from 'react-icons/fa6'

import Popup from '../common/popup/Popup'
import PositiveNumberInput from '../common/input/PositiveNumberInput'
import ProfileStatus from './ProfileStatus'
import ProfileItem from './ProfileItem'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { isMetSearchStringCriteria } from '../../util/util'
import TransactionContainer from '../trading/TransactionContainer'
import { restartProfile, updateProfileParams } from './profileAction'


const SIDE = {
    BUY: 'BUY',
    SELL: 'SELL'
}

const _profileValidator = (profile={}) => {
    const isValidConfigCountMount = _.get(profile, 'params.COG_COUNT_MODE') === 'BY_ORDER'
    const isValidEnableHedgeOneByOne = _.get(profile, 'params.ENABLE_HEDGE_ONE_BY_ONE') === true
    const areQuoteMaxSidePosParamsValid = _.every(_.get(profile, 'legs.1.symbols'), _symbol => _.isEqual(_.get(_symbol, 'params.QUOTE_MAX_SIDE_POS'), [0, 0]))
    const areQuoteNewOrderQtyParamsValid = _.every(_.get(profile, 'legs.1.symbols'), _symbol => _.isEqual(_.get(_symbol, 'params.QUOTE_NEW_ORDER_QTY'), [0, 0]))

    const isValidHedgeExposureThreshold = _.get(profile, 'legs.2.params.HEDGE_EXPOSURE_THRESHOLD') === 0.01
    const areHedgeBigPriceWeightsValid = _.reduce(
        _.get(profile, 'legs.2.symbols'),
        (_result, _symbol) => BigNumber(_result).plus(_.get(_symbol, 'params.HEDGE_BIG_PRICE_WEIGHT')).toString(),
        '0'
    ) === '1'
    const isValidHedgeStratregy = _.get(profile, 'legs.2.strategy.type') === 'COG'

    if (!isValidConfigCountMount) {
        return new Error('Invalid COG_COUNT_MODE: must be \'BY_ORDER\'')
    } else if (!isValidEnableHedgeOneByOne) {
        return new Error('Invalid ENABLE_HEDGE_ONE_BY_ONE: must be \'true\'')
    } else if (!areQuoteMaxSidePosParamsValid) {
        return new Error('Invalid QUOTE_MAX_SIDE_POS: must be \'0\'')
    } else if (!areQuoteNewOrderQtyParamsValid) {
        return new Error('Invalid QUOTE_NEW_ORDER_QTY: must be \'0\'')
    } else if (!isValidHedgeExposureThreshold) {
        return new Error('Invalid HEDGE_EXPOSURE_THRESHOLD: must be \'0.01\'')
    } else if (!areHedgeBigPriceWeightsValid) {
        return new Error('Invalid HEDGE_BIG_PRICE_WEIGHT: sum must be \'1\'')
    } else if (!isValidHedgeStratregy) {
        return new Error('Invalid hedge strategy: must be \'COG\'')
    } else {
        return true
    }
}

function FastExecution () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const profileItems = useShallowEqualSelector(state => _.get(state, 'profile.items', {}))

    const [selectedProfileId, setSelectedProfileId] = useState('')
    const [addProfileSearchString, setAddProfileSearchString] = useState('')
    const [profileIds, setProfileIds] = useLocalStorage('fast-execution--profile-ids', [])
    const [isProfileExpanded, setIsProfileExpanded] = useState(true)
    const [fastExecutionParamPerProfile, setFastExecutionParamPerProfile] = useLocalStorage('fast-execution--param-per-profile', {})
    const [overviewHidden, setOverviewHidden] = useLocalStorage('fast-execution--overview-hidden', false)
    const [addProfilePopupId, setAddProfilePopupId] = useState(0)
    const [shouldShowProfileRealtimeTx, setShouldShowProfileRealtimeTx] = useState(true)
    const [isUpdatingParam, setIsUpdatingParam] = useState(false)
    const [operateErrorMessage, setOperateErrorMessage] = useState('')

    const _profileSize = _.size(profileItems)
    const selectedProfileItem = profileItems[selectedProfileId]

    const profileOptions = useMemo(() => {
        const _options = []
        _.forEach(profileItems, _item => {
            const { id, hostname, name, user } = _item
            if (isMetSearchStringCriteria(`${hostname} ${name} ${user}`, addProfileSearchString)) {
                _options.push({
                    id,
                    hostname,
                    name,
                    user
                })
            }
        })
        return _options
    }, [_profileSize, addProfileSearchString])

    const handleClickUpdateAndStartProfile = useEvent(async () => {
        const { side, totalValue } = _.get(fastExecutionParamPerProfile, selectedProfileId, {})
        if (!isUpdatingParam &&
            _.values(SIDE).includes(side) &&
            Number(totalValue) > 0 &&
            selectedProfileItem?.started === false) {
            const _newSkew = Number(totalValue) * (side === SIDE.BUY ? -1 : 1)
            const _newProfileParams = _.pick(
                dotProp.set(selectedProfileItem, 'params.SKEW', _newSkew),
                ['accounts', 'legs', 'params']
            )

            try {
                setIsUpdatingParam(true)
                const updateResponse = await dispatch(updateProfileParams(selectedProfileId, _newProfileParams))
            
                if (isMounted()) {
                    const _success = updateResponse?.status === 200
                    if (_success) {
                        dispatch(restartProfile(selectedProfileId, true))
                    } else {
                        throw new Error(`Update param failed: status code ${updateResponse?.status}`)
                    }
                }
            } catch (error) {
                console.error(`FastExecution.handleClickUpdateAndStartProfile error: `, error)
                setOperateErrorMessage(error.toString())
            } finally {
                if (isMounted()) {
                    setIsUpdatingParam(false)
                }
            }    
        }
    })

    function AddProfile () {
        return (
            <Popup className='fast-execution--add-profile'
                key={addProfilePopupId}
                on={'click'}
                trigger={<button className='fast-execution--add-profile--trigger'>{'Add Profile'}</button>}>
                <div className='fast-execution--add-profile--main' onClick={(e) => { e.stopPropagation() }}>
                    <input className='fast-execution--add-profile--search-input'
                        value={addProfileSearchString}
                        spellCheck={false}
                        placeholder='Search Profile'
                        onChange={(e) => { setAddProfileSearchString(e.target.value) }} />
                    <div className='fast-execution--add-profile--list'>
                        {_.map(profileOptions, _option => {
                            return (
                                <div className='fast-execution--add-profile--list--item' key={_option.id}
                                    onClick={() => {
                                        setProfileIds(_.uniq([_option.id, ...Object.values(profileIds)]))
                                        setSelectedProfileId(_option.id)
                                        setAddProfilePopupId(addProfilePopupId + 1)
                                    }}>
                                    <div className='fast-execution--add-profile--list--item--name'>{_option.name}</div>
                                    <span className='fast-execution--add-profile--list--item--hostname'>{_option.hostname}</span>
                                    <span className='fast-execution--add-profile--list--item--user'>{_option.user}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Popup>
        )
    }

    function ProfileList () {
        return (
            <div className='fast-execution--profile-list'>
                {_.isEmpty(profileIds) ? <div className='fast-execution--profile-list--empty'>{'Empty Profiles'}</div>
                : _.map(profileIds, _id => {
                    const _profileItem = profileItems[_id]
                    return _profileItem && (
                        <div className={'fast-execution--profile-list--item' + (selectedProfileId === _id ? ' selected' : '')} key={_id}
                            onClick={() => { setSelectedProfileId(_id) }}>
                            <div className='fast-execution--profile-list--item--header'>
                                <span className='fast-execution--profile-list--item--hostname'>{_profileItem.hostname}</span>
                                <span className='fast-execution--profile-list--item--user'>{_profileItem.user}</span>
                                <Popup
                                    className='fast-execution--profile-list--item--remove'
                                    on={'click'}
                                    trigger={
                                        <button
                                            className='fast-execution--profile-list--item--remove--trigger'
                                            onClick={(e) => { e.stopPropagation() }}>
                                            <FaXmark />
                                        </button>
                                    }>
                                    <div className='fast-execution--profile-list--item--remove--main' onClick={(e) => { e.stopPropagation() }}>
                                        <div className=''>{'Delete the profile?'}</div>
                                        <button onClick={() => {
                                            setProfileIds(_.without(profileIds, _id))
                                            if (selectedProfileId === _id) {
                                                setSelectedProfileId(null)
                                            }
                                        }}>{'DELETE'}</button>
                                    </div>
                                </Popup>
                            </div>
                            <div className='fast-execution--profile-list--item--name'>{_profileItem.name}</div>
                            <ProfileStatus profileItem={_profileItem} />
                        </div>
                    )
                })}
            </div>
        )
    }

    function Params () {
        const _param = fastExecutionParamPerProfile[selectedProfileId]
        const _validation = _profileValidator(selectedProfileItem)
        
        let errorMessage = ''
        if (_.isEmpty(selectedProfileId)) {
            errorMessage = 'No profile is selected'
        } else if (_validation !== true) {
            errorMessage = _validation.toString()
        } else if (!(selectedProfileItem?.started === false && selectedProfileItem?.resumed === false)) {
            errorMessage = 'Only stopped profile can be modifed'
        } else if (!_.values(SIDE).includes(_param?.side)) {
            errorMessage = 'Invalid Side'
        } else if (!(Number(_param?.totalValue) > 0)) {
            errorMessage = 'Total Value must be greater than 0'
        }

        return (
            <div className='fast-execution--params'>
                <div className='fast-execution--params--header'>{'Params'}</div>
                <div className='fast-execution--params--main'>
                    <div className='fast-execution--params--item'>
                        <label>{'Side'}</label>
                        <div className='fast-execution--params--side'>
                            {_.map(SIDE, _side => {
                                return (
                                    <button key={_side}
                                        className={`${_side}${_side === _param?.side ? ' selected' : ''}`}
                                        disabled={_.isNil(selectedProfileItem)}
                                        onClick={() => {
                                            const _newParams = _.cloneDeep(fastExecutionParamPerProfile ?? {})
                                            _.set(_newParams, `${selectedProfileId}.side`, _side)
                                            setFastExecutionParamPerProfile(_newParams)
                                        }}>
                                        {_side}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='fast-execution--params--item'>
                        <label>{'Total Value'}</label>
                        <PositiveNumberInput
                            autoFocus={false}
                            value={_param?.totalValue ?? ''}
                            disabled={_.isNil(selectedProfileItem)}
                            onChange={(newValue) => {
                                const _newParams = _.cloneDeep(fastExecutionParamPerProfile)
                                _.set(_newParams, `${selectedProfileId}.totalValue`, _.isEmpty(newValue) ? '' : Number(newValue))
                                setFastExecutionParamPerProfile(_newParams)
                            }} />
                    </div>
                </div>
                <div className='fast-execution--params--current-skew'>
                    <label>{'Skew'}</label>
                    <div>{_.get(selectedProfileItem, 'params.SKEW', 'N/A')}</div>
                </div>
                {(!_.isEmpty(errorMessage) || !_.isEmpty(operateErrorMessage)) &&
                <div className='fast-execution--params--error-message'>{errorMessage || operateErrorMessage}</div>}
                <button
                    className='fast-execution--params--modify-button'
                    disabled={!_.isEmpty(errorMessage) || isUpdatingParam}
                    onClick={() => { handleClickUpdateAndStartProfile () }}>
                    {isUpdatingParam ? 'Updating Profile...' : 'Update skew and start profile'}
                </button>
            </div>
        )
    }

    function Overview () {
        return (
            <div className='fast-execution--overview'>
                <div className='fast-execution--overview--header'
                    onClick={() => { setOverviewHidden(!overviewHidden) }}>
                    <label>{'Overview'}</label>
                    {overviewHidden ? <FaCaretUp /> : <FaCaretDown />}
                </div>
                {!overviewHidden && <div className='fast-execution--overview--introduction'>
                    {`This component updates skew based on the side and total value set by you, 
                    enabling quick profile activation for trading. 
                    Selected profiles and the set params are local-storage-only and not shared among traders. 
                    Only stopped profiles can be modified.`}
                </div>}
            </div>
        )
    }

    function Side () {
        return (
            <div className='fast-execution--side'>
                {Overview()}
                {AddProfile()}
                {ProfileList()}
                {Params()}
            </div>
        )
    }

    return (
        <div className='fast-execution'>
            {Side()}
            <div className='fast-execution--profile'>
                <div className='fast-execution--profile--header'>
                    <label>{'Selected Profile'}</label>
                    <button
                        className='fast-execution--profile--tx-toggle'
                        onClick={() => { setShouldShowProfileRealtimeTx(!shouldShowProfileRealtimeTx) }}>
                        {shouldShowProfileRealtimeTx ? <FaCaretRight /> : <FaCaretLeft />}
                    </button>
                </div>
                {!_.isEmpty(selectedProfileItem) &&
                <div className='fast-execution--profile--main'>
                    <ProfileItem
                        profile={selectedProfileItem}
                        expanded={isProfileExpanded}
                        onClickToggleExpand={() => { setIsProfileExpanded(!isProfileExpanded) }} />
                </div>}
            </div>
            {shouldShowProfileRealtimeTx && selectedProfileId &&
            <div className='fast-execution--transactions'>
                <TransactionContainer profileId={selectedProfileId} />
            </div>}
        </div>
    )
}

export default memo(FastExecution)
