import _ from 'lodash'
import { ELF_API_BASE_URL } from '../../configs/config'
import { areAllValuesNonEmpty, secureFetch } from '../../util/util'

export const UPDATE_CONFIG_ITEMS = 'UPDATE_CONFIG_ITEMS'
export const UPDATE_CONFIG_TEXTS = 'UPDATE_CONFIG_TEXTS'
export const UPDATE_CONFIG_TEXT = 'UPDATE_CONFIG_TEXT'

export const CONFIG_TYPE = {
    TEXT: 'text',
    RESULT: 'result'
}

export function fetchConfigs (type=CONFIG_TYPE.TEXT) {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/configs?type=${type}`))
        .then(response => response.json())
        .then(body => {
            if (_.isObject(body)) {
                if (type === CONFIG_TYPE.TEXT) {
                    dispatch({
                        type: UPDATE_CONFIG_TEXTS,
                        configTexts: body
                    })
                } else if (type === CONFIG_TYPE.RESULT) {
                    dispatch({
                        type: UPDATE_CONFIG_ITEMS,
                        configItems: body
                    })
                }
                resolve(body)
            }
        })
        .catch(error => {
            console.error('fetchFundingTags error: ', error)
        })
    })
}

export function updateConfig (configKey, text) {
    return (dispatch, getState) => new Promise(resolve => {
        const user = _.get(getState(), 'auth.username')
        if (areAllValuesNonEmpty([configKey, text, user])) {
            dispatch(secureFetch(`${ELF_API_BASE_URL}/configs/${configKey}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    text,
                    user
                })
            }))
            .then(response => response.json())
            .then(body => {
                if (_.isObject(body)) {
                    dispatch({
                        type: UPDATE_CONFIG_TEXT,
                        key: configKey,
                        text: body
                    })
                    resolve(body)
                }
            })
            .catch(error => {
                console.error('updateConfig error: ', error)
            })
        }
    })
}