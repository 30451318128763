import React, { memo, useState } from 'react'
import _ from 'lodash'

import SignalProfileForm from '../profile/SignalProfileForm'
import SignalProfileMenu from './SignalProfileMenu'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { MdOutlineDeselect } from 'react-icons/md'

function SignalProfileContainer () {

    const signalProfilesPerDirectory = useShallowEqualSelector(state => _.get(state, 'profile.signalProfile')) || {}
    const [selectedProfile, setSelectedProfile] = useState({
        directory: null,
        id: null
    })

    const _selectedSignalProfile = _.get(signalProfilesPerDirectory, `${selectedProfile?.directory}.${selectedProfile?.id}`)

    console.log('2222222', selectedProfile, _selectedSignalProfile)

    return (
        <div className='signal-profile-container'>
            <div className='signal-profile-container--menu'>
                <SignalProfileMenu
                    selectedProfile={selectedProfile}
                    onSelectProfile={({ directory, id }) => {
                        setSelectedProfile({ directory, id })
                    }} />
            </div>
            {_selectedSignalProfile 
                ? <div className='signal-profile-container--form'>
                    <SignalProfileForm
                        signalProfile={_selectedSignalProfile}
                        directory={selectedProfile.directory} />
                </div>
                : <div className='signal-profile-container--empty-profile'>
                    <MdOutlineDeselect />
                    {'No File Selected'}    
                </div>}

        </div>
    )
}

export default memo(SignalProfileContainer)