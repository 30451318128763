import React, { memo, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage, useSessionStorage } from 'react-use'
import { useDispatch } from 'react-redux'
import ReactLoading from 'react-loading'

import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { LuChevronDown, LuChevronRight, LuServer } from 'react-icons/lu'
import { MdDeleteOutline } from 'react-icons/md'

import Popup from '../common/popup/Popup'
import { SignalProfileStruct } from './SignalProfileConfig'

import { deleteSignalProfile, removeSignalProfile, updateSignalProfile } from './profileAction'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { isMetSearchStringCriteria } from '../../util/util'
import { IoIosFolderOpen } from 'react-icons/io'


function ProductionProfileConfigFileId ({ hostname='', profileName='', fileName='' }) {
    return `${hostname}--${profileName}--${fileName.replace(/\.[^/.]+$/, '')}`
}
function Section ({ directory='local', selectedId, isFetching=false, onClickProfile=()=>{}, onClickFetchFiles=()=>{} }) {

    const dispatch = useDispatch()
    const [isHidden, setIsHidden] = useLocalStorage(`signal-profile-menu--${directory}--is-hidden`, false)
    const signalProfilePerId = useShallowEqualSelector(state => _.get(state, `profile.signalProfile.${directory}`)) || {}
    const signalConfigFilesPerHostname = useShallowEqualSelector(state => _.get(state, `profile.signalConfigFilesPerHostname`, {}))
    const [hostnamesShouldShow, setHostnamesShouldShow] = useSessionStorage('signal-profile-menu--hostnames-should-show', {})
    const [profileFolderShouldShow, setProfileFolderShouldShow] = useSessionStorage('signal-profile-menu--profile-folders-should-show', {})
    const [productionProfileSearchString, setProductionProfileSearchString] = useState('')

    const seivedConfigFilesPerHostname = useMemo(() => {
        const _filteredConfigFilesPerHostname = _.reduce(signalConfigFilesPerHostname, (_result, _signalConfigFilesPerProfile, _hostname) => {
            const _filteredConfigFilesPerProfile = _.reduce(_signalConfigFilesPerProfile, (__result, _files, _profileName) => {
                const _filteredFiles = _.filter(_files, _file => isMetSearchStringCriteria(`${_profileName} ${_hostname} ${_file?.name}`, productionProfileSearchString))
                if (!_.isEmpty(_filteredFiles)) {
                    __result[_profileName] = _filteredFiles
                }
                return __result
            }, {})

            if (!_.isEmpty(_filteredConfigFilesPerProfile)) {
                _result[_hostname] = _filteredConfigFilesPerProfile
            }

            return _result
        }, {})

        return _filteredConfigFilesPerHostname
    }, [signalConfigFilesPerHostname, productionProfileSearchString])

    return (
        <section className='signal-profile-menu--section'>
            <div className='signal-profile-menu--section--header' onClick={() => { setIsHidden(!isHidden) }}>
                {isHidden ? <LuChevronRight /> : <LuChevronDown />}
                <span>{_.capitalize(directory)}</span>
                {directory === 'local' &&
                <button onClick={(e) => {
                    e.stopPropagation()
                    dispatch(updateSignalProfile(directory, SignalProfileStruct({ _id: uuidv4(), name: `signal-profile-${_.random(1000, 9999)}` })))
                    if (isHidden) {
                        setIsHidden(false)
                    }
                }}>{'Create Config File'}</button>}
                {directory === 'production' &&
                <>
                    {isFetching ? <ReactLoading
                        className='signal-profile-menu--section--loading'
                        type={'spin'}
                        color='#fff' />
                    : <button onClick={(e) => {
                        e.stopPropagation()
                        onClickFetchFiles()
                    }}>{'Fetch Files'}</button>}
                </>}
            </div>
            {!isHidden &&
            <>
                {directory === 'production' &&
                <input className='signal-profile-menu--section--search'
                    placeholder='Search Files'
                    value={productionProfileSearchString}
                    spellCheck={false}
                    onChange={(e) => { setProductionProfileSearchString(e.target.value) }} />}
                {directory === 'production' ? <div className='signal-profile-menu--section--config-files'>
                    {_.map(seivedConfigFilesPerHostname, (_signalConfigFilesPerProfile, _hostname) => {
                        const _shouldShowHostname = _.has(hostnamesShouldShow, _hostname)
                        return (
                            <div className='signal-profile-menu--section--config-files--hostname' key={_hostname}>
                                <div className='signal-profile-menu--section--config-files--hostname--header'
                                    onClick={() => { setHostnamesShouldShow(_shouldShowHostname ? dotProp.delete(hostnamesShouldShow, _hostname) : dotProp.set(hostnamesShouldShow, _hostname, true)) }}>
                                    {_shouldShowHostname ? <LuChevronDown /> : <LuChevronRight />}
                                    <LuServer />
                                    <label>{_hostname}</label>
                                </div>
                                {_shouldShowHostname &&
                                <div className='signal-profile-menu--section--config-files--profiles'>
                                    {_.map(_signalConfigFilesPerProfile, (_files, _profileName) => {
                                        const _profileKey = `${_profileName}_${_hostname}`
                                        const _shouldShowProfile = _.has(profileFolderShouldShow, _profileKey)
                                        return (
                                            <div className='signal-profile-menu--section--config-files--profile' key={_profileKey}>
                                                <div className='signal-profile-menu--section--config-files--profile--header'
                                                    onClick={() => { setProfileFolderShouldShow(_shouldShowProfile ? dotProp.delete(profileFolderShouldShow, _profileKey) : dotProp.set(profileFolderShouldShow, _profileKey, true) ) }}>
                                                    {_shouldShowProfile ? <LuChevronDown /> : <LuChevronRight />}
                                                    <IoIosFolderOpen />
                                                    <label>{_profileName}</label>
                                                </div>
                                                {_shouldShowProfile &&
                                                <div className='signal-profile-menu--section--config-files--profile--files'>
                                                    {_.map(_files, (_file) => {
                                                        const _fileId = ProductionProfileConfigFileId({ hostname: _hostname, profileName: _profileName, fileName: _file.name })
                                                        return (
                                                            <div className={'signal-profile-menu--section--config-files--profile--file' + (selectedId == _fileId ? ' selected' : '')}
                                                                key={_fileId}
                                                                onClick={() => { onClickProfile(_fileId) }}>
                                                                {_file.name || 'Nil Name'}
                                                            </div>
                                                        )
                                                    })}
                                                </div>}
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        )
                    })}
                </div> : _.isEmpty(signalProfilePerId) ? <div className='signal-profile-menu--section--empty'>{'Empty File'}</div>
                : <div className='signal-profile-menu--section--list'>
                    {_.map(signalProfilePerId, (signalProfile, id) => {
                        return (
                            <div className={'signal-profile-menu--section--item' + (selectedId === id ? ' selected' : '')} key={id}
                                onClick={() => { onClickProfile(id) }}>
                                <div className='signal-profile-menu--section--item--name' title={signalProfile.name}>{signalProfile.name}</div>
                                <Popup className='signal-profile-menu--section--item--delete--popup'
                                    on={'click'}
                                    trigger={<button className='signal-profile-menu--section--item--delete--trigger'><MdDeleteOutline /></button>}>
                                    <div className='signal-profile-menu--section--item--delete'>
                                        <div className='signal-profile-menu--section--item--delete--message'>{'Delete the file forever?'}</div>
                                        <button className='signal-profile-menu--section--item--delete--confirm-button'
                                            onClick={() => {
                                                if (directory === 'local') {
                                                    dispatch(removeSignalProfile(directory, id))
                                                } else if (directory === 'stored') {
                                                    dispatch(deleteSignalProfile(directory, id))
                                                }
                                            }}>
                                            {'DELETE'}
                                        </button>
                                    </div>
                                </Popup>
                            </div>
                        )
                    })}
                </div>}
            </>}
        </section>
    )
}

Section.propTypes = {
    directory: PropTypes.string.isRequired,
    selectedId: PropTypes.string,
    isFetching: PropTypes.bool,
    onClickProfile: PropTypes.func.isRequired,
    onClickFetchFiles: PropTypes.func.isRequired
}

function SignalProfileMenu ({ selectedProfile={}, onSelectProfile=()=>{} }) {

    return (
        <div className='signal-profile-menu'>
            {_.map(['local', 'stored', 'production'], (_directory) => {
                return (
                    <Section key={_directory}
                        directory={_directory}
                        selectedId={selectedProfile.directory === _directory ? selectedProfile.id : null}
                        onClickProfile={(_signalProfileId) => {
                            onSelectProfile({
                                directory: _directory,
                                id: _signalProfileId
                            })
                        }}
                        onClickFetchFiles={() => {

                        }}/>
                )
            })}
        </div>
    )
}

SignalProfileMenu.propTypes = {
    selectedProfile: PropTypes.shape({
        directory: PropTypes.string,
        id: PropTypes.string
    }),
    onSelectProfile: PropTypes.func.isRequired
}

export default memo(SignalProfileMenu)