import dotProp from 'dot-prop-immutable'
import _ from 'lodash'
import { UPDATE_CONFIG_ITEMS, UPDATE_CONFIG_TEXT, UPDATE_CONFIG_TEXTS } from './configAction'
import { areAllValuesNonEmpty } from '../../util/util'

export const SLICE_NAME = 'config'

const initialState = {
    configTexts: {},
    configItems: {}
}

export function configReducer (state=initialState, action) {
    switch(action.type) {
        case UPDATE_CONFIG_TEXTS:
            return dotProp.set(state, 'configTexts', action.configTexts)

        case UPDATE_CONFIG_ITEMS:
            return dotProp.set(state, 'configItems', action.configItems)

        case UPDATE_CONFIG_TEXT:
            if (areAllValuesNonEmpty([action.key, action.text])) {
                return dotProp.set(state, `configTexts.${action.key}`, action.text)
            }
            break

        default:
            return state
    }
}

export const selectConfigTexts = (state) => _.get(state, `${SLICE_NAME}.configTexts`, {})
export const selectConfigItems = (state) => _.get(state, `${SLICE_NAME}.configItems`, {})
export const selectConfigItem = (state, key) => _.get(state, `${SLICE_NAME}.configItems.${key}`, {})