import React, { memo, useMemo, useState } from 'react'
import { useLocalStorage, useMountedState, useUpdateEffect } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { arrayMoveImmutable } from 'array-move'
import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { MdDragIndicator } from 'react-icons/md'
import { FaCaretDown, FaCaretLeft } from 'react-icons/fa6'
import { VscDiscard, VscJson } from 'react-icons/vsc'
import { GrDeploy, GrSave } from 'react-icons/gr'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import AceEditor from 'react-ace-builds'
import 'react-ace-builds/webpack-resolver-min'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SignalConfigItem from './SignalConfigItem'
import SignalParamList from './SignalParamList'
import SignalParam from './SignalParam'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { SignalProfileStruct } from './SignalProfileConfig'
import { SignalProfileShape } from './profilePropTypes'
import { createSignalProfile, modifySignalProfile, updateSignalConfigFile } from './profileAction'
import { areAllValuesNonEmpty, isValidJSON } from '../../util/util'
import { selectConfigItem } from '../config/configReducer'

const DragHandle = SortableHandle(() => <button className='signal-profile-form--sortable-handle'>{<MdDragIndicator />}</button>)

const SortableSignalConfigItem = SortableElement((props) => {
    const { onClickItem, isFocused, ...rest } = props
    return (
        <div className='signal-profile-form--sortable-signal-config-item'
            style={{ zIndex: isFocused ? 9 : null }}
            onClick={() => { onClickItem() }}>
            <DragHandle />
            <SignalConfigItem {...rest} />
        </div>
    )
})

const SortableSignalConfigs = SortableContainer(({ signalProfile={}, configKey='', configName='', focusedConfigIndex, onChange=()=>{}, onFocusItem=()=>{} }) => {
    const _configData = signalProfile[configKey] || []
    return _.isArray(_configData) && (
        <div className='signal-profile-form--sortable-signal-configs'>
            <div className='signal-profile-form--sortable-signal-configs--list'>
                {_.map(_configData, (_data, _index) => {
                    return (
                        <SortableSignalConfigItem
                            key={_index}
                            index={_index}
                            signalProfile={signalProfile}
                            configKey={configKey}
                            data={_data}
                            namePlaceholder={`${configName} Name`}
                            typeSelectorTitle={`${configName} Types`}
                            isFocused={focusedConfigIndex === _index}
                            onClickItem={() => { onFocusItem(_index) }}
                            onChangeData={(_newData) => {
                                const _newSignalConfig = dotProp.set(_configData, _index, _newData)
                                onChange(_newSignalConfig)
                            }}
                            onClickRemove={() => {
                                const _newSignalConfig = dotProp.delete(_configData, _index)
                                onChange(_newSignalConfig)
                            }} />
                    )
                })}
            </div>
            <button className='signal-profile-form--sortable-signal-configs--add-button'
                onClick={() => {
                    const _newSignalConfig = _.concat(_configData, [['', ['', {}]]])
                    onChange(_newSignalConfig)
                }}>
                {`Add ${configName}`}
            </button>
        </div>
    )
})

function SignalProfileForm ({ signalProfile=SignalProfileStruct({}), directory }) {

    const dispatch = useDispatch()
    const isMounted = useMountedState()
    const profileItems = useShallowEqualSelector(state => _.get(state, 'profile.items'), {})
    const symbolItems = useShallowEqualSelector(state => _.get(state, 'symbol.items')) || {}

    const signalProfileConfig = useShallowEqualSelector(state => selectConfigItem(state, 'SIGNAL_PROFILE_CONFIG'))
    const { INSTANCE } = _.get(signalProfileConfig, 'result') || {}

    const [editingSignalProfile, setEditingSignalProfile] = useState({})
    const [focusedConfigIndexPerKey, setFocusedConfigIndexPerKey] = useState({})
    const [shouldShowJsonEditor, setShouldShowJsonEditor] = useState(false)
    const [sectionKeysShouldHide, setSectionKeysShouldHide] = useLocalStorage('signal-profile-form--section-keys-should-hide', [])
    const [focusedSectionKey, setFocusedSectionKey] = useState(null)
    const [profileIdToDeploy, setProfileIdToDeploy] = useState(null)

    const signalProfileId = signalProfile._id
    const _signalProfile = !_.isEmpty(editingSignalProfile) ? editingSignalProfile : signalProfile

    const { instance, symbols, samplers, pricing_models: pricingModels, variables, models, strategies } = _signalProfile || {}

    const symbolOptions = useMemo(() => {
        return _.map(symbolItems, _symbolItem => ({
            value: _symbolItem.symbol_name,
            name: _symbolItem.symbol_name
        }))
    }, [symbolItems])

    useUpdateEffect(() => {
        setEditingSignalProfile({})
    }, [signalProfileId])

    const _handleClickSaveChanges = useEvent(() => {
        const _id = signalProfileId
        dispatch(modifySignalProfile(directory, _signalProfile))
        .then(result => {
            if (isMounted() && _id === result?._id) {
                setEditingSignalProfile({})
            }
        })
    })

    const _handleClickDeploy = useEvent(() => {
        const { hostname, user, name: profile } = profileItems[profileIdToDeploy] || {}
        const { name } = _signalProfile || {}
        if (areAllValuesNonEmpty([hostname, profile, user, name])) {
            dispatch(updateSignalConfigFile({
                hostname,
                user,
                profile,
                name: `${name}.json`,
                content: JSON.stringify(Object.assign(_signalProfile, { _id: uuidv4() }), null, 2)
            }))
            .then(response => {
                console.log('1111111', response)
            })
        }
    })

    // eslint-disable-next-line react/prop-types
    function Section ({ title='', key='', component }) {
        const _canHide = !_.isEmpty(key)
        const _shouldHide = _canHide && sectionKeysShouldHide.includes(key)
        return (
            <section className='signal-profile-form--section' style={{ zIndex: focusedSectionKey === key ? 9 : 1 }}
                onClick={() => { setFocusedSectionKey(key) }}>
                <div className='signal-profile-form--section--title'
                    onClick={() => {
                        if (_canHide) {
                            setSectionKeysShouldHide(_shouldHide ? _.without(sectionKeysShouldHide, key) : _.concat(sectionKeysShouldHide, key))
                        }
                    }}>
                    <span>{title}</span>
                    {_canHide && (_shouldHide ? <FaCaretLeft /> : <FaCaretDown />)}
                </div>
                {!_shouldHide && <div className='signal-profile-form--section--main'>{component}</div>}
            </section>
        )
    }

    function Instance () {
        return (
            <div className='signal-profile-form--instance'>
                {_.map(instance, (_value, _key) => {
                    return (
                        <div className='signal-profile-form--instance--param' key={_key}>
                            <SignalParam
                                key={_key}
                                paramKey={_key}
                                value={_value}
                                paramConfig={_.get(INSTANCE.params, _key)}
                                signalProfile={signalProfile}
                                onChange={(_newValue) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, `instance.${_key}`, _newValue)
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        </div>
                    )
                })}
            </div>
        )
    }

    function Deploy () {
        const _profileOptions = _.map(profileItems, (_profile, _profileId) => ({
            value: _profileId,
            name: `${_profile.name} - ${_profile.hostname}`
        }))
        return (
            <div className='signal-profile-form--deploy' onClick={(e) => { e.stopPropagation() }}>
                <div className='signal-profile-form--deploy--profile'>
                    <label>{'PROFILE'}</label>
                    <SearchSelect
                        value={profileIdToDeploy}
                        options={_profileOptions}
                        onChange={(newOption) => { setProfileIdToDeploy(newOption.value) }} />
                </div>
                <button
                    disabled={_.isEmpty(profileIdToDeploy)}
                    onClick={() => { _handleClickDeploy() }}>{'Deploy'}</button>
            </div>
        )
    }

    return (
        <div className='signal-profile-form'>
            <div className='signal-profile-form--header'>
                <div className='signal-profile-form--name'>{_signalProfile?.name}</div>
                {!_.isEmpty(directory) && <span className={`signal-profile-form--directory ${directory}`}>{_.capitalize(directory)}</span>}
                <div className='signal-profile-form--header--buttons'>
                    <button className='signal-profile-form--toggle-json-button'
                        onClick={() => { setShouldShowJsonEditor(!shouldShowJsonEditor) }}>
                        <VscJson />
                        {'Toggle JSON'}
                    </button>
                    <button className='signal-profile-form--discard-button'
                        disabled={_.isEmpty(editingSignalProfile)}
                        onClick={() => { setEditingSignalProfile({}) }}>
                        <VscDiscard />
                        {`Discard Changes`}
                    </button>
                    {directory === 'local' &&
                    <button className='signal-profile-form--save-button'
                        onClick={() => { dispatch(createSignalProfile('stored', _.omit(_signalProfile, ['_id']))) }}>
                        <GrSave />
                        {`Save File`}
                    </button>}
                    {directory !== 'local' &&
                    <button className='signal-profile-form--save-button'
                        disabled={_.isEmpty(editingSignalProfile)}
                        onClick={() => { _handleClickSaveChanges() }}>
                        <GrSave />
                        {`Save Changes`}
                    </button>}
                    {directory !== 'production' &&
                    <Popup className='signal-profile-form--deploy-popup'
                        on={'click'}
                        trigger={
                            <button className='signal-profile-form--deploy-button'
                                disabled={!_.isEmpty(editingSignalProfile)}>
                                <GrDeploy />
                                {`Deploy to Production`}
                            </button>
                        }
                        onOpen={() => { setProfileIdToDeploy(null) }}>
                        {Deploy()}
                    </Popup>}
                </div>
            </div>
            <div className='signal-profile-form--main'>
                <div className='signal-profile-form--ui'>
                    {Section({
                        title: 'Instance',
                        key: 'instance',
                        component: Instance()
                    })}
                    {Section({
                        title: `Symbols (${_.size(symbols)})`,
                        key: 'symbols',
                        component: (
                            <SignalParamList
                                values={symbols}
                                options={symbolOptions}
                                className={'signal-profile-form--symbols'}
                                placeholder={'Search and add symbol'}
                                onChange={(_newSymbols) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'symbols', _newSymbols)
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                    {Section({
                        title: `Samplers (${_.size(samplers)})`,
                        key: 'samplers',
                        component: (
                            <SortableSignalConfigs
                                axis={'y'}
                                signalProfile={_signalProfile}
                                configKey={'samplers'}
                                configName={'Sampler'}
                                useDragHandle
                                focusedConfigIndex={focusedConfigIndexPerKey.samplers}
                                onFocusItem={(_index) => { setFocusedConfigIndexPerKey(dotProp.set(focusedConfigIndexPerKey, 'samplers', _index)) }}
                                onChange={(_newSignalConfig) => { setEditingSignalProfile(dotProp.set(_signalProfile, 'samplers', _newSignalConfig)) }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'samplers', arrayMoveImmutable(_signalProfile.samplers || [], oldIndex, newIndex))
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                    {Section({
                        title: `Pricing Models (${_.size(pricingModels)})`,
                        key: 'pricingModels',
                        component: (
                            <SortableSignalConfigs
                                axis={'y'}
                                signalProfile={_signalProfile}
                                configKey={'pricing_models'}
                                configName={'Pricing Model'}
                                useDragHandle
                                focusedConfigIndex={focusedConfigIndexPerKey.pricingModels}
                                onFocusItem={(_index) => { setFocusedConfigIndexPerKey(dotProp.set(focusedConfigIndexPerKey, 'pricingModels', _index)) }}
                                onChange={(_newSignalConfig) => { setEditingSignalProfile(dotProp.set(_signalProfile, 'pricing_models', _newSignalConfig)) }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'pricing_models', arrayMoveImmutable(_signalProfile.pricing_models || [], oldIndex, newIndex))
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                    {Section({
                        title: `Variables (${_.size(variables)})`,
                        key: 'variables',
                        component: (
                            <SortableSignalConfigs
                                axis={'y'}
                                signalProfile={_signalProfile}
                                configKey={'variables'}
                                configName={'Variable'}
                                useDragHandle
                                focusedConfigIndex={focusedConfigIndexPerKey.variables}
                                onFocusItem={(_index) => { setFocusedConfigIndexPerKey(dotProp.set(focusedConfigIndexPerKey, 'variables', _index)) }}
                                onChange={(_newSignalConfig) => { setEditingSignalProfile(dotProp.set(_signalProfile, 'variables', _newSignalConfig)) }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'variables', arrayMoveImmutable(_signalProfile.variables || [], oldIndex, newIndex))
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                    {Section({
                        title: `Models (${_.size(models)})`,
                        key: 'models',
                        component: (
                            <SortableSignalConfigs
                                axis={'y'}
                                signalProfile={_signalProfile}
                                configKey={'models'}
                                configName={'Model'}
                                useDragHandle
                                focusedConfigIndex={focusedConfigIndexPerKey.models}
                                onFocusItem={(_index) => { setFocusedConfigIndexPerKey(dotProp.set(focusedConfigIndexPerKey, 'models', _index)) }}
                                onChange={(_newSignalConfig) => { setEditingSignalProfile(dotProp.set(_signalProfile, 'models', _newSignalConfig)) }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'models', arrayMoveImmutable(_signalProfile.models || [], oldIndex, newIndex))
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                    {Section({
                        title: `Strategies (${_.size(strategies)})`,
                        key: 'strategies',
                        component: (
                            <SortableSignalConfigs
                                axis={'y'}
                                signalProfile={_signalProfile}
                                configKey={'strategies'}
                                configName={'Strategy'}
                                useDragHandle
                                focusedConfigIndex={focusedConfigIndexPerKey.strategies}
                                onFocusItem={(_index) => { setFocusedConfigIndexPerKey(dotProp.set(focusedConfigIndexPerKey, 'strategies', _index)) }}
                                onChange={(_newSignalConfig) => { setEditingSignalProfile(dotProp.set(_signalProfile, 'strategies', _newSignalConfig)) }}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const _newSignalProfile = dotProp.set(_signalProfile, 'strategies', arrayMoveImmutable(_signalProfile.strategies || [], oldIndex, newIndex))
                                    setEditingSignalProfile(_newSignalProfile)
                                }} />
                        )
                    })}
                </div>
                {shouldShowJsonEditor &&
                <div className='signal-profile-form--json'>
                    <AceEditor
                        mode='json'
                        theme='dracula'
                        value={JSON.stringify(_signalProfile, null, 2)}
                        showPrintMargin={false}
                        editorProps={{ $blockScrolling: true }}
                        onChange={(newValue) => {
                            if (isValidJSON(newValue)) {
                                setEditingSignalProfile(JSON.parse(newValue))
                            }
                        }} />
                </div>}
            </div>
        </div>
    )
}

SignalProfileForm.propTypes = {
    signalProfile: SignalProfileShape.isRequired,
    directory: PropTypes.string
}

export default memo(SignalProfileForm)