import React, { useRef, useState } from 'react'
import { useDebounce, useMountedState, useSessionStorage } from 'react-use'
import { connect, useDispatch } from 'react-redux'
import useEvent from 'react-use-event-hook'
import PropTypes from 'prop-types'

import { nanoid } from 'nanoid'
import greenlet from 'greenlet'
import dotProps from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import { FaPlus, FaXmark, FaRotateRight, FaCircleCheck, FaScaleUnbalanced } from 'react-icons/fa6'
import { IoSync } from 'react-icons/io5'

import { AutoSizer, Table, Column } from 'react-virtualized'
import ReactLoading from 'react-loading'
import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'
import Checkbox from '../common/checkbox/Checkbox'
import SymbolLeverageUpdateForm from './SymbolLeverageUpdateForm'
import LeverageManagement from './LeverageManagement'

import { addSymbol, enableSymbol, fetchSymbolParameter, fetchSymbols, syncOptionSymbols, syncSymbol, updateSymbol } from './symbolAction'

const SymbolToAddStruct = ({ symbolName='', result='' }) => {
    return {
        symbolName,
        result
    }
}

const _seiveSymbolNames = greenlet(({ enabledSymbolItems={}, disabledSymbolItems={}, shouldShowEnabledItems=true, shouldShowDisabledItems=false, searchInput='' }) => {
    const _isMetSearchStringCriteria = (targetString='', searchString='') => {
        const splitSearchStrings = searchString.split(',')
        return splitSearchStrings.every(splitSearchString => {
            const trimmedSearchString = splitSearchString.toLowerCase().trim()
            return trimmedSearchString.length === 0 
            || trimmedSearchString.split(/\|{2}|\s+/).some(v => {
                const trimmedV = v.trim()
                const isNotLogic = trimmedV.startsWith('!')
                return trimmedV.length > 0 
                    && ((isNotLogic && trimmedV.length > 1 && !targetString.toLowerCase().includes(trimmedV.substring(1)))
                        || (!isNotLogic && targetString.toLowerCase().includes(trimmedV))
                    )
            })
        })
    }

    const [_enabledItems, _disabledItems] = Object.values(enabledSymbolItems).reduce((result, _symbolItem) => {
        result[_symbolItem.trading === '1' ? 0 : 1].push(_symbolItem)
        return result
    }, [[], []])

    let _enabledSymbols = [], _disabledSymbols = []
    const _trimedSearchInput = (searchInput || '').trim()
    if (shouldShowEnabledItems) {
        _enabledSymbols = _trimedSearchInput.length === 0 ? _enabledItems : _enabledItems.filter(symbolItem => _isMetSearchStringCriteria(symbolItem?.symbol_name, _trimedSearchInput))
    }
    if (shouldShowDisabledItems) {
        const _allDisabledItems = _disabledItems.concat(Object.values(disabledSymbolItems))
        _disabledSymbols = _trimedSearchInput.length === 0 ? _allDisabledItems : _allDisabledItems.filter(symbolItem => _isMetSearchStringCriteria(symbolItem?.symbol_name, _trimedSearchInput))
    }

    const mergedSymbols = _enabledSymbols.concat(_disabledSymbols)
    const sortedSymbols = mergedSymbols.sort((a, b) => { return a.symbol_name <= b.symbol_name ? -1 : 1 })
    return sortedSymbols.map(_symbol => _symbol.symbol_name)
})

const SymbolManagement = ({ enabledSymbolItems, disabledSymbolItems }) => {

    const isMounted = useMountedState()
    const dispatch = useDispatch()

    const [searchInput, setSearchInput] = useState('')
    const [shouldShowEnabledItems, setShouldShowEnabledItems] = useSessionStorage('symbol_management_should_show_enabled_items', true)
    const [shouldShowDisabledItems, setShouldShowDisabledItems] = useSessionStorage('symbol_management_should_show_disabled_items', false)
    const [editingSymbols, setEditingSymbols] = useState({})
    const [symbolsAreEnabling, setSymbolsAreEnabling] = useState([])
    const [symbolsAreSaving, setSymbolsAreSaving] = useState([])
    const [symbolsAreSaved, setSymbolsAreSaved] = useState([])
    const [symbolsAreSyncing, setSymbolsAreSyncing] = useState([])
    const [seivedSymbolNames, setSeivedSymbolNames] = useState([])
    const [isSeivingRows, setIsSeivingRows] = useState(false)
    const [symbolsToAdd, setSymbolsToAdd] = useState([])
    const [symbolsAdded, setSymbolsAdded] = useSessionStorage('symbol_management_symbols_added', [])
    const [isFetchingSymbols, setIsFetchingSymbols] = useState(false)
    const [isAddingSymbols, setIsAddingSymbols] = useState(false)
    const [updateSymbolsResults, setUpdateSymbolsResults] = useState({})
    const [confirmSaveSymbolPopupId, setConfirmSaveSymbolPopupId] = useState(0)

    const [exchangeNameToSyncOptionSymbols, setExchangeNameToSyncOptionSymbols] = useState('DERIBIT')
    const [isSyncingOptionSymbols, setIsSyncingOptionSymbols] = useState(false)
    const [syncOptionSymbolsMessage, setSyncOptionSymbolsMessage] = useState('')

    const seiveRowsWorkerId = useRef(null)
    const fetchSymbolId = useRef(null)
    const syncOptionSymbolsId = useRef(null)

    const _fetchSymbols = useEvent((callback) => {
        const _fetchId = nanoid()
        fetchSymbolId.current = _fetchId
        setIsFetchingSymbols(true)
        dispatch(fetchSymbols(true))
        .then((_symbolItems) => {
            if (_.isFunction(callback)) {
                callback(_symbolItems)
            }
        })
        .finally(() => {
            if (isMounted() && _fetchId === fetchSymbolId.current) {
                setIsFetchingSymbols(false)
            }
        })
    })

    const _fetchSymbolParameter = useEvent((_symbolName, callback) => {
        dispatch(fetchSymbolParameter(_symbolName))
        .then(() => {
            if (_.isFunction(callback)) {
                callback()
            }
        })
    })

    // useEffect(() => {
    //     _fetchSymbols()
    //     const polling = setInterval(() => {
    //         _fetchSymbols()
    //     }, 60000)
    //     return () => { clearInterval(polling) }
    // }, [_fetchSymbols])

    useDebounce(() => {
        if (!isSeivingRows) {
            setIsSeivingRows(true)
        }
        const _workerId = nanoid()
        seiveRowsWorkerId.current = _workerId
        _seiveSymbolNames({
            enabledSymbolItems,
            disabledSymbolItems,
            shouldShowEnabledItems,
            shouldShowDisabledItems,
            searchInput
        }).then((_newSeivedSymbolNames) => {
            if (isMounted() && seiveRowsWorkerId.current === _workerId) {
                setSeivedSymbolNames(_.uniq(_newSeivedSymbolNames))
            }
        }).catch((error) => {
            console.error('SymbolManagement seriveRows error: ', error)
        }).finally(() => {
            if (isMounted() && seiveRowsWorkerId.current === _workerId) {
                setIsSeivingRows(false)
            }
        })
    }, 250, [enabledSymbolItems, disabledSymbolItems, shouldShowEnabledItems, shouldShowDisabledItems, searchInput])

    const handleClickAddSymbols = useEvent(async () => {
        setIsAddingSymbols(true)
        await Promise.all(_.map(symbolsToAdd, async (_symbolToAdd, index) => {
            const _symbolName = _symbolToAdd?.symbolName
            if (!_.isEmpty(_symbolName)) {
                if (symbolsAreSaved.includes(_symbolName)) {
                    setSymbolsAreSaved(_.without(symbolsAreSaved, _symbolName))
                }
                if (_.has(editingSymbols, _symbolName)) {
                    setEditingSymbols(prevEditingSymbols => _.omit(prevEditingSymbols, _symbolName))
                }
                if (_.has(updateSymbolsResults, _symbolName)) {
                    setUpdateSymbolsResults(prevResults => _.omit(prevResults, _symbolName))
                }
                try {
                    const response = await dispatch(addSymbol(_symbolName))
                    const status = response?.status
                    if (status === 200) {
                        _fetchSymbolParameter(_symbolName)
                        if (isMounted()) {
                            setSymbolsToAdd(prevSymbolsToAdd => dotProps.set(prevSymbolsToAdd, `${index}.result`, `Successfully added`))
                            setSymbolsAdded(prevSymbolsAdded => _.uniq(_.concat([_symbolName], prevSymbolsAdded)))
                        }
                    } else {
                        const text = await response.text()
                        if (isMounted()) {
                            setSymbolsToAdd(prevSymbolsToAdd => dotProps.set(prevSymbolsToAdd, `${index}.result`, `Status: ${status} - ${text}`))
                        }
                    }
                } catch (error) {
                    if (isMounted()) {
                        setSymbolsToAdd(prevSymbolsToAdd => dotProps.set(prevSymbolsToAdd, `${index}.result`, error.toString()))
                    }
                }
            }
        }))

        if (isMounted()) {
            setIsAddingSymbols(false)
        }
    })

    const handleClickEnableButton = useEvent((symbolName) => {
        setSymbolsAreEnabling(_.concat(symbolsAreEnabling, symbolName))
        dispatch(enableSymbol(symbolName))
            .then((response) => {
                response.text()
                .then(message => {
                    if (isMounted()) {
                        setUpdateSymbolsResults(prevResults => ({
                            ...prevResults,
                            [symbolName]: message
                        }))
                    }
                })
                _fetchSymbolParameter(symbolName) 
            })
            .finally(() => {
                if (isMounted()) {
                    setSymbolsAreEnabling(prevSymbols => _.without(prevSymbols, symbolName))
                }
            })
    })

    const handleClickSyncSymbolButton = useEvent((symbolName) => {
        setSymbolsAreSyncing(_.concat(symbolsAreSyncing, symbolName))
        setUpdateSymbolsResults(prevResults => ({
            ...prevResults,
            [symbolName]: ''
        }))
        dispatch(syncSymbol(symbolName))
            .then((result) => {
                if (isMounted()) {
                    if (result.status === 200) {
                        if (!_.isEmpty(result.symbolItem)) {
                            setUpdateSymbolsResults(prevResults => ({
                                ...prevResults,
                                [symbolName]: 'Synced successfully'
                            }))
                            if (_.has(editingSymbols, symbolName)) {
                                setEditingSymbols(_.omit(editingSymbols, symbolName))
                            }
                            if (symbolsAreSaved.includes(symbolName)) {
                                setSymbolsAreSaved(_.without(symbolsAreSaved, symbolName))
                            }
                        } else {
                            setUpdateSymbolsResults(prevResults => ({
                                ...prevResults,
                                [symbolName]: 'Failed to parse the synced symbol'
                            }))
                        }
                    } else {
                        setUpdateSymbolsResults(prevResults => ({
                            ...prevResults,
                            [symbolName]: result.errorMessage || 'Failed to sync'
                        }))
                    }
                }
            })
            .catch(error => {
                if (isMounted()) {
                    setUpdateSymbolsResults(prevResults => ({
                        ...prevResults,
                        [symbolName]: error.toString()
                    }))
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setSymbolsAreSyncing(prevSymbols => _.without(prevSymbols, symbolName))
                }
            })
    })

    const handleClickSaveButton = useEvent(async (symbolName) => {
        setSymbolsAreSaving(_.concat(symbolsAreSaving, symbolName))
        setUpdateSymbolsResults(prevResults => ({
            ...prevResults,
            [symbolName]: ''
        }))
        try {
            const response = await dispatch(updateSymbol(editingSymbols[symbolName]))
            const status = response?.status
            if (status === 200) {
                if (isMounted()) {
                    setSymbolsAreSaved(_.concat(symbolsAreSaved, symbolName))
                    _fetchSymbolParameter(symbolName, () => {
                        if (isMounted()) {
                            setEditingSymbols(prevEditingSymbols => _.omit(prevEditingSymbols, symbolName))
                        }
                    }) 
                }
            }
            const message = await response.text()
            if (isMounted()) {
                setUpdateSymbolsResults(prevResults => ({
                    ...prevResults,
                    [symbolName]: message
                }))
            }
        } catch(error) {
            if (isMounted()) {
                setUpdateSymbolsResults(prevResults => ({
                    ...prevResults,
                    [symbolName]: error.toString()
                }))
            }
        } finally {
            if (isMounted()) {
                setSymbolsAreSaving(prevSymbols => _.without(prevSymbols, symbolName))
            }
        }
    })

    const handleClickSyncOptionSymbolsButton = useEvent(async () => {
        if (!isSyncingOptionSymbols) {
            setIsSyncingOptionSymbols(true)
            setSyncOptionSymbolsMessage('')

            const _syncOptionSymbolsId = nanoid()
            syncOptionSymbolsId.current = _syncOptionSymbolsId

            try {
                const response = await dispatch(syncOptionSymbols(exchangeNameToSyncOptionSymbols))
                if (response?.status !== 200) {
                    const text = await response.text()
                    if (isMounted() && syncOptionSymbolsId.current === _syncOptionSymbolsId) {
                        setSyncOptionSymbolsMessage(`${response.status}: ${text}`)
                    }
                } else {
                    const prevEnabledSymbolItems = _.clone(enabledSymbolItems)
                    const prevDisabledSymbolItems = _.clone(disabledSymbolItems)

                    setSyncOptionSymbolsMessage(`Succefully synced.`)
                    _fetchSymbols((_symbolItems) => {
                        if (isMounted() && syncOptionSymbolsId.current === _syncOptionSymbolsId) {
                            const _symbolsAdded = [], _symbolsDisabled = []
                            _.forEach(_symbolItems, _symbolItem => {
                                const { symbol_name: _symbolName, trading } = _symbolItem
                                if (!_.has(prevEnabledSymbolItems, _symbolName) && !_.has(prevDisabledSymbolItems, _symbolName)) {
                                    _symbolsAdded.push(_symbolName)
                                } else if (_.get(prevEnabledSymbolItems, `${_symbolName}.trading`) === '1' && trading !== '1') {
                                    _symbolsDisabled.push(_symbolName)
                                }
                            })
                            const _addedSize = _.size(_symbolsAdded), _disabledSize = _.size(_symbolsDisabled)
                            setSyncOptionSymbolsMessage(`Succefully synced: ${_addedSize} ${_symbolsAdded > 1 ? 'are' : 'is'} added and ${_disabledSize} ${_disabledSize > 1 ? 'are' : 'is'} disabled.`)
                            if (!_.isEmpty(_symbolsAdded)) {
                                setSymbolsAdded(prevSymbolsAdded => _.uniq(_.concat(_symbolsAdded, prevSymbolsAdded)))
                            }
                        }
                    })
                }
            } catch (error) {
                console.error(`SymbolManagement handleClickSyncOptionSymbolsButton error: `, error)
            } finally {
                if (isMounted() && syncOptionSymbolsId.current === _syncOptionSymbolsId) {
                    setIsSyncingOptionSymbols(false)
                }
            }
        }
    })

    const _getSymbolItem = (_symbolName) => {
        return _.get(enabledSymbolItems, _symbolName) || _.get(disabledSymbolItems, _symbolName)
    }

    const handleChangeSymbolParameter = useEvent((_symbolName='', _paramKey='', _newValue='') => {
        const _symbolItem = _getSymbolItem(_symbolName) || {}
        const _editingSymbol = _.get(editingSymbols, _symbolName) || {}
        setEditingSymbols({
            ...editingSymbols,
            [_symbolName]: {
                ..._symbolItem,
                ..._editingSymbol,
                [_paramKey]: _newValue
            }
        })
        if (symbolsAreSaved.includes(_symbolName)) {
            setSymbolsAreSaved(_.without(symbolsAreSaved, _symbolName))
        }
        if (_.has(updateSymbolsResults, _symbolName)) {
            setUpdateSymbolsResults(_.omit(updateSymbolsResults, _symbolName))
        }
    })

    function AddSymbolPopup () {
        const _symbolsToAdd = _.concat(symbolsToAdd, SymbolToAddStruct({}))
        const _nonEmptySymbolSize = _.size(_.filter(_symbolsToAdd, _symbolToAdd => !_.isEmpty(_symbolToAdd?.symbolName)))
        return (
            <Popup className='symbol-management--add-symbol--popup'
                on={'click'}
                trigger={
                    <button className='symbol-management--add-symbol--trigger'>
                        <FaPlus />
                        {'Add Symbols'}
                    </button>
                }>
                <table className='symbol-management--add-symbol--table'>
                    <thead>
                        <tr>
                            <th>{'New Symbol'}</th>
                            <th>{'Status'}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(_symbolsToAdd, (_symbolToAdd, index) => {
                            const { symbolName: _symbolName, result: _result } = _symbolToAdd
                            const isLastRow = index === _.size(_symbolsToAdd) - 1

                            let isExisted, isDuplicate
                            if (!_.isEmpty(_symbolName)) {
                                isExisted = _.has(enabledSymbolItems, _symbolName) || _.has(disabledSymbolItems, _symbolName)
                                isDuplicate = _.size(_.filter(symbolsToAdd, v => v?.symbolName === _symbolName)) > 1
                            }

                            return (
                                <tr key={index}>
                                    <td>
                                        <div className='symbol-management--add-symbol--input-wrapper'>
                                            <input
                                                type={'text'}
                                                autoFocus={index === 0}
                                                placeholder='btc_usdt_BINANCE_SPT'
                                                spellCheck={false}
                                                value={_symbolName}
                                                disabled={isAddingSymbols}
                                                onChange={(e) => {
                                                    const _newSymbolName = (e.target.value ?? '').trim()
                                                    const _newSymbolsToAdd = dotProps.set(isLastRow ? _symbolsToAdd : symbolsToAdd, index, SymbolToAddStruct({ symbolName: _newSymbolName }))
                                                    setSymbolsToAdd(_newSymbolsToAdd)
                                                }} />
                                            {(isExisted || isDuplicate) &&
                                            <div className='symbol-management--add-symbol--input-wrapper--warning-message'>
                                                {isExisted ? 'Symbol is already existed' : 'Duplicate symbol'}
                                            </div>}
                                        </div>
                                    </td>
                                    <td><span className='symbol-management--add-symbol--status-message'>{_result}</span></td>
                                    <td>
                                        {!isLastRow &&
                                        <button
                                            tabIndex={-1}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                const _newSymbolsToAdd = dotProps.delete(symbolsToAdd, index)
                                                setSymbolsToAdd(_newSymbolsToAdd)
                                            }}>
                                            <FaXmark />
                                        </button>}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='symbol-management--add-symbol--footer'>
                    <button className='symbol-management--add-symbol--add-button'
                        disabled={_nonEmptySymbolSize <= 0 || isAddingSymbols}
                        onClick={() => { handleClickAddSymbols() }}>
                        {isAddingSymbols
                        ? <>
                            <ReactLoading className='symbol-management--add-symbol--add-button--loading'
                                type={'spin'}
                                color='#fff' />
                            {'Adding'}
                        </>
                        : `Add ${_nonEmptySymbolSize || ''} Symbol${_nonEmptySymbolSize > 1 ? 's' : ''}`}
                    </button>
                    {isAddingSymbols
                    ? <button className='symbol-management--add-symbol--discard-button'
                        onClick={() => { setIsAddingSymbols(false) }}>
                        {'Discard'}
                    </button>
                    : <button className='symbol-management--add-symbol--reset-button'
                        onClick={() => { setSymbolsToAdd([]) }}>
                        {'Reset'}
                    </button>}
                </div>
            </Popup>
        )
    }

    function SyncOptionSymbolsPopup () {
        return (
            <Popup className='symbol-management--sync-option-symbols--popup'
                on={'click'}
                trigger={
                    <button className='symbol-management--sync-option-symbols--trigger'>
                        <IoSync />
                        {'Sync Options'}
                    </button>
                }>
                <div className='symbol-management--sync-option-symbols--inputs'>
                    <div>
                        <label>{'Exchange'}</label>
                        <input
                            autoFocus
                            spellCheck={false}
                            placeholder='DERIBIT'
                            value={exchangeNameToSyncOptionSymbols}
                            disabled
                            onChange={(e) => { setExchangeNameToSyncOptionSymbols(_.toUpper(e.target.value)) }} />
                    </div> 
                </div>
                {!_.isEmpty(syncOptionSymbolsMessage) && <div className='symbol-management--sync-option-symbols--message'>{syncOptionSymbolsMessage}</div>}
                <div className='symbol-management--sync-option-symbols--footer'>
                    <button className='symbol-management--sync-option-symbols--sync-button'
                        disabled={isSyncingOptionSymbols || _.isEmpty(exchangeNameToSyncOptionSymbols)}
                        onClick={() => { handleClickSyncOptionSymbolsButton() }}>
                        {isSyncingOptionSymbols
                        ? <>
                            <ReactLoading className='symbol-management--sync-option-symbols--sync-button--loading'
                                type={'spin'}
                                color='#fff' />
                            {'Syncing'}
                        </>
                        : 'Sync Option Symbols'}
                    </button>
                    {isSyncingOptionSymbols &&
                    <button className='symbol-management--sync-option-symbols--discard-button'
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsSyncingOptionSymbols(false)
                        }}>
                        {'Discard'}
                    </button>}
                </div>
            </Popup>
        )
    } 

    function UpdateLeveragePopup () {
        return (
            <Popup className='symbol-management--update-leverage--popup'
                on={'click'}
                trigger={
                    <button className='symbol-management--update-leverage--trigger hidden'>
                        <FaScaleUnbalanced />
                        {'Update Leverage'}
                    </button>
                }>
                <SymbolLeverageUpdateForm />
            </Popup>
        )
    }

    function Header () {
        return (
            <div className='symbol-management--header'>
                <div className='symbol-management--header--left'>
                    <input placeholder='Search symbols'
                        type='text'
                        spellCheck={false}
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value)
                        }} />
                    <div className='symbol-management--criteria-toggle' onClick={() => {
                        setShouldShowEnabledItems(!shouldShowEnabledItems) 
                    }}>
                        <Checkbox
                            checked={shouldShowEnabledItems}
                            disabled />
                        <label>{'Enabled Symbols'}</label>
                    </div>
                    <div className='symbol-management--criteria-toggle' onClick={() => {
                        setShouldShowDisabledItems(!shouldShowDisabledItems)
                    }}>
                        <Checkbox
                            checked={shouldShowDisabledItems}
                            disabled />
                        <label>{'Disabled Symbols'}</label>
                    </div>
                </div>
                {isSeivingRows &&
                <ReactLoading className='symbol-management--header--loading'
                    type={'spin'}
                    color='#fff' />}
                <div className='symbol-management--header--right'>
                    <button className='symbol-management--header--refresh-button hidden'
                        disabled={isFetchingSymbols}
                        onClick={() => {
                            _fetchSymbols()
                            setEditingSymbols({})
                            setSymbolsAreSaved([])
                            setUpdateSymbolsResults({})
                        }}>
                        {isFetchingSymbols
                        ? <ReactLoading
                            className='symbol-management--header--refresh-button--loading'
                            type={'spin'}
                            color='#000' />
                        : <FaRotateRight />}
                        {isFetchingSymbols ? 'Fetching' : 'Fetch Latest'}
                    </button>
                    {SyncOptionSymbolsPopup()}
                    {UpdateLeveragePopup()}
                    {AddSymbolPopup()}
                </div> 
            </div>
        )
    }

    // eslint-disable-next-line react/prop-types
    function SaveParameterButton ({ isSaving=false, isSaved=false, onClick=()=>{} }) {
        return (
            <button
                className={'symbol-management--table--save-button' + (isSaved ? ' is-saved' : isSaving ? ' is-saving' : '')}
                disabled={isSaving || isSaved}
                onClick={() => { onClick() }}>
                {isSaved ? <>
                    <FaCircleCheck />
                    <span>{'Saved'}</span>
                </> : isSaving ? <>
                    <ReactLoading className='symbol-management--table--save-button--loading'
                        type={'spin'}
                        color='#fff' />
                    <span>{'Saving'}</span>
                </> : 'Save'}
            </button>
        )
    }

    // eslint-disable-next-line react/prop-types
    function SyncParameterButton ({ isSyncing=false, onClick=()=>{} }) {
        return (
            <button
                className='symbol-management--table--sync-button'
                title={'Fetch the latest parameters from the exchange and save them.'}
                disabled={isSyncing}
                onClick={() => { onClick() }}>
                {isSyncing &&
                <ReactLoading className='symbol-management--table--sync-button--loading'
                    type={'spin'}
                    color='#fff' />}
                {isSyncing ? 'Syncing' : 'Sync'}
            </button>
        )
    }

    const SymbolTable = (_rows=[], className) => {
        return (
            <div className={'symbol-management--table--wrapper' + (!_.isEmpty(className) ? ` ${className}` : '')}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className='symbol-management--table'
                            headerClassName={'symbol-management--table--header'}
                            headerHeight={27}
                            width={Math.max(width, 1080)}
                            height={height}
                            rowCount={_rows.length}
                            rowGetter={({ index }) => _rows[index]}
                            rowClassName={({ index }) => { 
                                return 'symbol-management--table--row' + (index % 2 === 1 ? ' odd-row' : ' even-row')
                            }}
                            rowHeight={45}
                            overscanRowCount={10}
                            noRowsRenderer={() => (<div className='symbol-management--table--no-content'>{'There is no matched records.'}</div> )}>
                            <Column dataKey={'symbol_name'}
                                label={`Symbol (${_.size(_rows)})`}
                                width={400}
                                flexGrow={1}
                                flexShrink={1} />
                            <Column dataKey={'id'}
                                label={'ID'}
                                width={60}
                                flexGrow={0}
                                flexShrink={1} />
                            <Column dataKey={'multiplier'}
                                label={'Multiplier'}
                                className='symbol-management--table--input-column'
                                width={90}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const _symbolName = rowData?.symbol_name
                                    const editingSymbol = _.get(editingSymbols, _symbolName) || {}
                                    const isChanged = !_.isNil(editingSymbol?.multiplier) && !BigNumber(editingSymbol.multiplier).eq(rowData?.multiplier)
                                    return (
                                        <input className={isChanged ? 'is-changed' : null}
                                            type={'number'}
                                            value={editingSymbol?.multiplier ?? rowData.multiplier}
                                            onWheel={(e) => { e.preventDefault() }}
                                            onKeyDown={(e) => { ['+', '-', 'e'].includes(e.key) && e.preventDefault() }}
                                            onChange={(e) => {
                                                if (!(Number(e.target.value) < 0)) {
                                                    handleChangeSymbolParameter(_symbolName, 'multiplier', (e.target.value ?? '').trim()) 
                                                }
                                            }} />
                                    )
                                }} />
                            <Column dataKey={'price_tick'}
                                className='symbol-management--table--input-column'
                                label={'Price Tick'}
                                width={90}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const _symbolName = rowData?.symbol_name
                                    const editingSymbol = _.get(editingSymbols, _symbolName) || {}
                                    const isChanged = !_.isNil(editingSymbol?.price_tick) && !BigNumber(editingSymbol.price_tick).eq(rowData?.price_tick)
                                    return (
                                        <input className={isChanged ? 'is-changed' : null}
                                            type={'number'}
                                            value={editingSymbol.price_tick ?? rowData.price_tick}
                                            onWheel={(e) => { e.preventDefault() }}
                                            onKeyDown={(e) => { ['+', '-', 'e'].includes(e.key) && e.preventDefault() }}
                                            onChange={(e) => {
                                                if (!(Number(e.target.value) < 0)) {
                                                    handleChangeSymbolParameter(_symbolName, 'price_tick', (e.target.value ?? '').trim())
                                                }
                                            }} />
                                    )
                                }} />
                            <Column dataKey={'tick_size'}
                                label={'Tick Size'}
                                className='symbol-management--table--input-column'
                                width={90}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const _symbolName = rowData?.symbol_name
                                    const editingSymbol = _.get(editingSymbols, _symbolName) || {}
                                    const isChanged = !_.isNil(editingSymbol?.tick_size) && !BigNumber(editingSymbol.tick_size).eq(rowData?.tick_size)
                                    return (
                                        <input className={isChanged ? 'is-changed' : null}
                                            type={'number'}
                                            value={editingSymbol.tick_size ?? rowData.tick_size}
                                            onWheel={(e) => { e.preventDefault() }}
                                            onKeyDown={(e) => { ['+', '-', 'e'].includes(e.key) && e.preventDefault() }}
                                            onChange={(e) => {
                                                if (!(Number(e.target.value) < 0)) {
                                                    handleChangeSymbolParameter(_symbolName, 'tick_size', (e.target.value ?? '').trim())
                                                }
                                            }} />
                                    )
                                }} />
                            <Column dataKey={'trading_in_notional'}
                                label={'Trading in Notional'}
                                width={120}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const _symbolName = rowData?.symbol_name
                                    const editingSymbol = _.get(editingSymbols, _symbolName) || {}
                                    const isChanged = !_.isNil(editingSymbol?.trading_in_notional) && editingSymbol.trading_in_notional !== rowData.trading_in_notional
                                    return (
                                        <Toggle
                                            className={isChanged ? 'is-changed' : null}
                                            checked={!_.isNil(editingSymbol?.trading_in_notional) ? editingSymbol.trading_in_notional === '1' : rowData.trading_in_notional === '1'}
                                            onChange={(newChecked) => { handleChangeSymbolParameter(_symbolName, 'trading_in_notional', newChecked ? '1' : '0') }} />
                                    )
                                }} />
                            <Column dataKey={'trading'}
                                label={'Enabled'}
                                width={80}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const enabled = rowData.trading === '1'
                                    return (
                                        <span className={enabled ? 'positive' : 'negative'}>{enabled ? 'True' : 'False'}</span>
                                    )
                                }} />
                            <Column dataKey={'action'}
                                label={'Actions'}
                                width={200}
                                flexGrow={0}
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const symbolName = rowData.symbol_name
                                    const enabled = rowData.trading === '1'
                                    const isEnabling = symbolsAreEnabling.includes(symbolName)
                                    const isSaving = symbolsAreSaving.includes(symbolName)
                                    const isSaved = symbolsAreSaved.includes(symbolName)
                                    const isSyncing = symbolsAreSyncing.includes(symbolName)
                                    const isEditing = _.has(editingSymbols, symbolName)

                                    return (
                                        <div className='symbol-management--table--actions'>
                                            {!enabled && !isEditing && !isSyncing &&
                                            <button
                                                className='symbol-management--table--enable-button'
                                                disabled={isEnabling}
                                                onClick={() => { handleClickEnableButton(symbolName) }}>
                                                {isEnabling &&
                                                <ReactLoading className='symbol-management--table--enable-button--loading'
                                                    type={'spin'}
                                                    color='#fff' />}
                                                {isEnabling ? 'Enabling' : 'Enable'}
                                            </button>}
                                            <Popup className='symbol-management--table--leverage-management'
                                                on={'click'}
                                                trigger={<button className='symbol-management--table--leverage-management--trigger'>{'Leverage'}</button>}>
                                                <LeverageManagement
                                                    minWidthDisabled
                                                    symbolColumnHidden
                                                    symbolSelectDisabled
                                                    defaultSymbol={symbolName}
                                                    defaultAccountPortfolios={['prop']} />
                                            </Popup>
                                            {(isEditing || isSaving) && !isSyncing &&
                                            <>
                                                {enabled ? <Popup
                                                    key={confirmSaveSymbolPopupId}
                                                    className='symbol-management--table--save-popup'
                                                    on={'click'}
                                                    trigger={
                                                        SaveParameterButton({
                                                            isSaving,
                                                            isSaved
                                                        })
                                                    }>
                                                    {`Confirm to update the parameters of ${symbolName}`}
                                                    <button className='symbol-management--table--save-popup--confirm-button'
                                                        disabled={isSaving}
                                                        onClick={() => {
                                                            handleClickSaveButton(symbolName)
                                                            setConfirmSaveSymbolPopupId(confirmSaveSymbolPopupId + 1)
                                                        }}>
                                                        {'Confirm to Save'}
                                                    </button>
                                                </Popup>
                                                : SaveParameterButton({
                                                    isSaving,
                                                    isSaved,
                                                    onClick: () => { handleClickSaveButton(symbolName) }
                                                })}
                                            </>}
                                            {isEditing && !isSaving && !isSaved &&
                                            <button className='symbol-management--table--reset-button'
                                                title='Discard all modifications of the parameters.'
                                                onClick={() => {
                                                    setEditingSymbols(_.omit(editingSymbols, symbolName))
                                                }}>
                                                {'Reset'}
                                            </button>}
                                            {!isSaving &&
                                            <>
                                                {enabled ? <Popup
                                                    key={confirmSaveSymbolPopupId}
                                                    className='symbol-management--table--save-popup'
                                                    on={'click'}
                                                    trigger={SyncParameterButton({ isSyncing })}>
                                                    {`Confirm to sync and update the parameters of ${symbolName}`}
                                                    <button className='symbol-management--table--save-popup--confirm-button'
                                                        disabled={isSaving}
                                                        onClick={() => {
                                                            handleClickSyncSymbolButton(symbolName)
                                                            setConfirmSaveSymbolPopupId(confirmSaveSymbolPopupId + 1)
                                                        }}>
                                                        {'Confirm to Sync'}
                                                    </button>
                                                </Popup>
                                                : SyncParameterButton({
                                                    isSyncing,
                                                    onClick: () => { handleClickSyncSymbolButton(symbolName) }
                                                })}
                                            </>}
                                        </div>
                                    )
                                }} />
                            <Column
                                dataKey={'message'}
                                label={'Message'}
                                width={120}
                                flexGrow={1}
                                flexShrink={1}
                                cellRenderer={({ rowData }) => {
                                    const symbolName = rowData.symbol_name
                                    const message= _.get(updateSymbolsResults, symbolName)
                                    return (
                                        <div className='symbol-management--table--update-message'
                                            title={message}>
                                            {message}
                                        </div>
                                    )
                                }} />
                        </Table>    
                    )}
                </AutoSizer>
            </div>
        )
    }

    const _symbolsRowsAdded = _.compact(_.map(symbolsAdded, _symbol => enabledSymbolItems[_symbol] || disabledSymbolItems[_symbol]))
    const _seivedRows = _.compact(_.map(seivedSymbolNames, _symbol => enabledSymbolItems[_symbol] || disabledSymbolItems[_symbol]))
    return (
        <div className='symbol-management'>
            {!_.isEmpty(_symbolsRowsAdded) &&
            <div className='symbol-management--added-symbols'>
                <div className='symbol-management--added-symbols--header'>
                    <div className='symbol-management--added-symbols--title'>{'Symbols Recently Added'}</div>
                    <button onClick={() => { setSymbolsAdded([]) }}>{<FaXmark />}</button>
                </div>
                {SymbolTable(_symbolsRowsAdded, 'symbol-management--added-symbols--main')}
                <hr />
            </div>}
            {Header()}
            {SymbolTable(_seivedRows, 'main')}
        </div>
    )   
}

SymbolManagement.propTypes = {
    enabledSymbolItems: PropTypes.object.isRequired,
    disabledSymbolItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        enabledSymbolItems: state.symbol?.items,
        disabledSymbolItems: state.symbol?.disabledItems
    }
}

export default connect(mapStateToProps)(SymbolManagement)