import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import _ from 'lodash'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

import { IoIosArrowDown } from 'react-icons/io'
import { FaFileSignature } from 'react-icons/fa'
import { FiChevronUp } from 'react-icons/fi'

import Toggle from '../common/toggle/Toggle'
import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import ExposureTable, { MODES, TABLE_GROUP_BYS } from './ExposureTable'
import ExposureChart from './ExposureChart'

import { fetchExposureTimestamps, fetchExposureById, fetchExposures, fetchInitialBalance, fetchExpsoureVariables, signExposure, fetchSignedExposurePreviews, fetchSignedExposureById, fetchExposureAdjustments } from './tradingAction'
import { getPortfolioNames } from '../../util/accountUtil'
import Checkbox from '../common/checkbox/Checkbox'
import { EXPOSURE_HOME_CURRENY } from './constants'

const LATEST = 'LATEST'
export const ALL = 'ALL'

const EXPOSURE_TYPES = {
    RECENT: 'RECENT',
    SIGNED: 'SIGNED'
}

const getStoragePortfolioNames = () => {
    const validPortfolioNames = _.concat(getPortfolioNames(), 'treasury')
    const portfolioNameString = sessionStorage.exposureContainerPortfolioNames
    const portfolioNames = _.isNil(portfolioNameString) ? validPortfolioNames
        : _.isEmpty(portfolioNameString) ? []
        : _.intersection(portfolioNameString.split(','), validPortfolioNames)
    return portfolioNames
}

const updateStoragePortfolioNames = (portfolioNames=[]) => {
    sessionStorage.exposureContainerPortfolioNames = portfolioNames.join(',')
}

const cachedWorkspaceComponentStates = {}

class ExposureContainer extends Component {
    constructor (props) {
        super(props)

        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            exposureTimestamps: {},
            signedExposurePreviews: {},
            comparison: {
                enabled: false,
                tableConfig: {},
                exposures: [{
                    exposureId: null,
                    type: null,
                    items: [],
                    initialBalance: {},
                    pricings: {},
                    isFetching: false
                }, {
                    exposureId: LATEST,
                    type: EXPOSURE_TYPES.RECENT,
                    items: [],
                    initialBalance: {},
                    pricings: {},
                    isFetching: false
                }]
            },
            homeCurrency: EXPOSURE_HOME_CURRENY.USDC,
            canFetchLatestExposure: true,
            portfolioNames: getStoragePortfolioNames(),
            mode: this.getMode(),
            groupBy: this.getGroupBy(),
            snapshotExposureType: EXPOSURE_TYPES.RECENT, 
            signingSnapshotPortfolios: [],
            isSigningSnapshot: false,
            signedSnapshotSuccess: false,
            signSnapshotErrorMessage: null,
            shouldShowExposureChartModal: false
        })
        this._mounted = false
        this.isFetchingExposureTimestamps = false
        this.isFetchingSignedExposurePreviews = false
        this.comparisonWrapperNode = null
        this.leftComparisonBlockNode = null
        this.rightComparisonBlockNode = null
        this.draggableLine = null

        this.updateExposureTimestampsInterval = null
    }

    static getDerivedStateFromProps (props, state) {
        if (_.some(state.comparison.exposures, exposure => exposure.exposureId === LATEST)) {
            const newExposures = state.comparison.exposures.map(exposure => {
                return exposure.exposureId === 'LATEST'
                    ? Object.assign({}, exposure, {
                        items: props.exposure.items,
                        initialBalance: props.initialBalance,
                        pricings: _.reduce(props.symbolPricings, (result, pricingItem, symbolName) => {
                            if (_.has(pricingItem, 'last')) {
                                result[symbolName] = Number(pricingItem.last)
                            }
                            return result
                        }, {})
                    })
                    : exposure
            })
            return {
                comparison: dotProp.set(state.comparison, 'exposures', newExposures)
            }
        } else {
            return null
        }
    }

    componentDidMount () {
        const { dispatch } = this.props
        this._mounted = true
        this.updateExposureTimestamps()
        this.updateSignedExpsourePreviews(true)
        this.registerUpdateExposureTimestampsInterval()
        dispatch(fetchExpsoureVariables())
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        this._mounted = false
        if (this.updateExposureTimestampsInterval) {
            window.clearInterval(this.updateExposureTimestampsInterval)
        }
        if (!_.isEmpty(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    getMode () {
        const mode = localStorage.exposureWindowMode
        return Object.keys(MODES).includes(mode) ? mode : MODES.EXPOSURE
    }

    updateMode (mode) {
        localStorage.exposureWindowMode = mode
        this.setState({ mode: this.getMode() })
    }

    getGroupBy () {
        const groupBy = localStorage.exposureWindowGroupBy
        return Object.keys(TABLE_GROUP_BYS).includes(groupBy) ? groupBy : TABLE_GROUP_BYS.COIN
    }

    updateGroupBy (groupBy) {
        localStorage.exposureWindowGroupBy = groupBy
        this.setState({ groupBy: this.getGroupBy() })
    }

    registerUpdateExposureTimestampsInterval () {
        if (this.updateExposureTimestampsInterval) {
            window.clearInterval(this.updateExposureTimestampsInterval)
        }
        this.updateExposureTimestampsInterval = setInterval(() => {
            if (this._mounted) {
                this.updateExposureTimestamps()
            }
        }, 60000)
    }

    updateExposureTimestamps (skip=0) {
        const { dispatch } = this.props
        if (!this.isFetchingExposureTimestamps) {
            this.isFetchingExposureTimestamps = true
            dispatch(fetchExposureTimestamps(skip))
            .then(body => {
                if (_.isArray(body) && this._mounted) {
                    this.setState(prevState => {
                        return {
                            exposureTimestamps: Object.assign({}, 
                                { [LATEST]: LATEST }, 
                                prevState.exposureTimestamps, 
                                _.reduce(body, (result, exposureTimestamp) => {
                                    result[exposureTimestamp._id] = exposureTimestamp.timestamp
                                    return result
                                }, {})
                            )
                        }
                    })
                }
            })
            .finally(() => {
                this.isFetchingExposureTimestamps = false
            })
        }
    }

    updateSignedExpsourePreviews (fromStart=false) {
        const { dispatch } = this.props
        const { signedExposurePreviews } = this.state
        if (!this.isFetchingSignedExposurePreviews) {
            this.isFetchingSignedExposurePreviews = true
            dispatch(fetchSignedExposurePreviews(fromStart ? 0 : _.size(signedExposurePreviews)))
            .then(body => {
                if (_.isArray(body) && this._mounted) {
                    const signedExposureIds = body.map(body => body._id)
                    const signedExposureIdIntersection = _.intersection(_.keys(signedExposurePreviews), signedExposureIds)
                    const newSignedExposurePreviews = _.reduce(body, (result, item) => {
                        result[item._id] = item
                        return result
                    }, fromStart && _.isEmpty(signedExposureIdIntersection) ? {} : _.cloneDeep(signedExposurePreviews))
                    this.setState({ signedExposurePreviews: newSignedExposurePreviews })
                }
            })
            .finally(() => {
                this.isFetchingSignedExposurePreviews = false
            })
        }
    }

    handleDragLine (e) {
        let newSplitLeft = e.clientX / this.comparisonWrapperNode.clientWidth * 100
        if (newSplitLeft && this.leftComparisonBlockNode && this.rightComparisonBlockNode && this.draggableLine) {
            newSplitLeft = _.clamp(newSplitLeft, 15, 85)
            this.leftComparisonBlockNode.style.width = `${newSplitLeft}%`
            this.leftComparisonBlockNode.style.minWidth = `${newSplitLeft}%`
            this.rightComparisonBlockNode.style.width = '100%'
            this.draggableLine.style.left = `${newSplitLeft}%`
        }
    }

    handleClickExposureSnapshot ({ comparisingExposureIndex=0, type=EXPOSURE_TYPES.RECENT, newExposureId }) {
        const { dispatch } = this.props
        const { comparison } = this.state
        const shouldFetchExposureItems = newExposureId !== LATEST
        const newComparison = dotProp.merge(comparison, `exposures.${comparisingExposureIndex}`, {
            exposureId: newExposureId,
            type,
            initialBalance: {},
            items: [],
            isFetching: shouldFetchExposureItems
        })
        this.setState({ comparison: newComparison })
        if (shouldFetchExposureItems) {
            const action = type === EXPOSURE_TYPES.SIGNED ? fetchSignedExposureById : fetchExposureById
            dispatch(action(newExposureId))
            .then(body => {
                if (this._mounted) {
                    this.setState(prevState => {
                        return {
                            comparison: dotProp.merge(prevState.comparison, `exposures.${comparisingExposureIndex}`, {
                                items: _.has(body, 'items') ? body.items : [],
                                initialBalance: _.has(body, 'initialBalance') ? body.initialBalance : {},
                                pricings: _.has(body, 'pricings') ? body.pricings : {},
                                isFetching: false
                            })
                        }
                    })
                }
            })
        }
    }

    SignSnapshotPopup (exposureId) {
        const { dispatch, authUsername } = this.props
        const { signingSnapshotPortfolios, isSigningSnapshot, signedSnapshotSuccess, signSnapshotErrorMessage } = this.state
        const portfolioNames = _.concat(getPortfolioNames(), 'treasury')
        return (
            <Popup className='exposure-container--header--sign-popup'
                on={'click'}
                trigger={<button className='exposure-container--header--sign-popup--trigger'><FaFileSignature />{'SIGN'}</button>}
                onOpen={() => {
                    this.setState({
                        signingSnapshotPortfolios: [],
                        isSigningSnapshot: false,
                        signedSnapshotSuccess: false,
                        signSnapshotErrorMessage: null
                    })
                }}>
                <div className='exposure-container--header--sign-popup--header'>{`Sign${_.isEmpty(exposureId) ? ' LATEST' : ''} Snapshot`}</div>
                <div className='exposure-container--header--sign-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <div className='exposure-container--header--sign-popup--user'>
                        <label>{'USER'}</label>
                        <span>{authUsername}</span>
                    </div>
                    <div className='exposure-container--header--sign-popup--portfolio'>
                        <label>{'Portfolios'}</label>
                        <div className='exposure-container--header--sign-popup--portfolio--items'>
                            {_.map(portfolioNames, portfolioName => {
                                return (
                                    <div className='exposure-container--header--sign-popup--portfolio--item' key={portfolioName}>
                                        <Checkbox 
                                            checked={signingSnapshotPortfolios.includes(portfolioName)}
                                            onChange={(newChecked) => {
                                                const newSigningSnapshotPortfolios = newChecked ? _.union(signingSnapshotPortfolios, [portfolioName]) : _.without(signingSnapshotPortfolios, portfolioName)
                                                this.setState({  
                                                    signingSnapshotPortfolios: newSigningSnapshotPortfolios
                                                })
                                            }} />
                                        <label>{portfolioName}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {signSnapshotErrorMessage && <div className='exposure-container--header--sign-popup--message'>{signSnapshotErrorMessage}</div>}
                    <button 
                        disabled={isSigningSnapshot || signedSnapshotSuccess || _.isEmpty(signingSnapshotPortfolios)}
                        className={'exposure-container--header--sign-popup--confirm' + (isSigningSnapshot ? ' signing' : signedSnapshotSuccess ? ' signed' : '')}
                        onClick={() => {
                            this.setState({ isSigningSnapshot: true })
                            dispatch(signExposure({
                                exposureId,
                                user: authUsername,
                                portfolios: _.size(signingSnapshotPortfolios) === _.size(portfolioNames) ? [] : signingSnapshotPortfolios
                            }))
                            .then(response => {
                                if (response.status === 200) {
                                    this.setState({ signedSnapshotSuccess: true })
                                    if (exposureId) {
                                        this.setState({
                                            signedExposurePreviews: {}
                                        }, () => {
                                            this.updateSignedExpsourePreviews(true)
                                        })
                                    } else {
                                        this.updateSignedExpsourePreviews(true)
                                    }
                                    
                                } else {
                                    this.setState({ signedSnapshotSuccess: false })
                                    return response.json()
                                }
                            })
                            .then(body => { 
                                if (body && body.error) {
                                    this.setState({ signSnapshotErrorMessage: _.toString(body.error) })
                                }
                            })
                            .catch(error => {
                                this.setState({ signSnapshotErrorMessage: error.toString() })
                            })
                            .finally(() => {
                                this.setState({ isSigningSnapshot: false })
                            })
                        }}>
                        {isSigningSnapshot ? 'SIGNING' 
                        : signedSnapshotSuccess ? 'SIGNED'
                        : 'CONFIRM'}
                    </button>
                </div>
            </Popup>
        )
    }

    Header () {
        const { dispatch, exposure } = this.props
        const { portfolioNames, mode, groupBy, comparison, canFetchLatestExposure, homeCurrency } = this.state

        const validPortfolioNames = _.concat(getPortfolioNames(), 'treasury')
        const isAllPortfolioSelected = _.every(validPortfolioNames, _n => portfolioNames.includes(_n))

        const groupBySelectOptions = _.map(TABLE_GROUP_BYS, item => {
            return {
                value: item,
                name: item
            }
        })

        const homeCurrencyOptions = _.map(EXPOSURE_HOME_CURRENY, currency => {
            return {
                value: currency,
                name: currency
            }
        })

        return (
            <div className='exposure-container--header clearfix'>
                <div className='exposure-container--header--modes'>
                    {_.map(MODES, key => {
                        return (
                            <button className={'exposure-container--header--mode' + (key === mode ? ' active' : '')} key={key}
                                onClick={() => { this.updateMode(key) }}>{key}</button>
                        )
                    })}
                </div>
                <div className='exposure-container--header--filters'>
                    <div className='exposure-container--header--filter'>
                        <span>{'Portfolios'}</span>
                        <Popup className='exposure-container--header--portfolios--popup'
                            on={'click'}
                            trigger={
                                <div className='exposure-container--header--portfolios--trigger'>
                                    <div className='exposure-container--header--portfolios--trigger--names'>
                                        {isAllPortfolioSelected ? 'ALL' : _.isEmpty(portfolioNames) ? 'None' : portfolioNames.join(', ')}
                                    </div>
                                    <span>{`(${_.size(portfolioNames)})`}</span>
                                    <IoIosArrowDown />
                                </div>
                            }>
                            <div className='exposure-container--header--portfolios--main'
                                onClick={(e) => { e.stopPropagation() }}>
                                <div className='exposure-container--header--portfolios--buttons'>
                                    <button className='exposure-container--header--portfolios--select-all-button'
                                        onClick={() => {
                                            updateStoragePortfolioNames(validPortfolioNames)
                                            this.setState({ portfolioNames: getStoragePortfolioNames() })
                                        }}>
                                        {'Select All'}
                                    </button>
                                    <button className='exposure-container--header--portfolios--unselect-all-button'
                                        onClick={() => {
                                            updateStoragePortfolioNames([])
                                            this.setState({ portfolioNames: getStoragePortfolioNames() })
                                        }}>
                                        {'Unselect All'}
                                    </button>
                                </div>
                                <div className='exposure-container--header--portfolios--list'>
                                    {_.map(validPortfolioNames, _portfolio => {
                                        const _selected = portfolioNames.includes(_portfolio)
                                        return (
                                            <div className='exposure-container--header--portfolios--list--item'
                                                onClick={() => {
                                                    const _newPortfolioNames = _selected ? _.without(portfolioNames, _portfolio) : _.concat(portfolioNames, [_portfolio])
                                                    updateStoragePortfolioNames(_newPortfolioNames)
                                                    this.setState({ portfolioNames: getStoragePortfolioNames() })
                                                }}>
                                                <Checkbox checked={_selected} />
                                                <span>{_portfolio}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </Popup>
                    </div>
                    <div className='exposure-container--header--filter'>
                        <span>{'Group By'}</span>
                        <SearchSelect
                            options={groupBySelectOptions}
                            value={groupBy}
                            hideSearchBar
                            onChange={(newOption) => { this.updateGroupBy(newOption.value) }} />
                    </div>
                    <div className='exposure-container--header--filter'>
                        <span>{'Home Currency'}</span>
                        <SearchSelect
                            options={homeCurrencyOptions}
                            value={homeCurrency}
                            hideSearchBar
                            onChange={(newOption) => { 
                                this.setState({ homeCurrency: newOption.value })
                            }} />
                    </div>
                </div>
                <div className='exposure-container--header--comparison-toggle'>
                    <span>{'SNAPSHOT Compare'}</span>
                    <Toggle 
                        checked={comparison.enabled}
                        trueText={'ON'}
                        falseText={'OFF'}
                        onChange={(newChecked) => {
                            this.setState({ comparison: dotProp.set(comparison, 'enabled', newChecked) })
                        }} />
                </div> 
                <div className='exposure-container--header--right-section'>
                    {exposure.timestamp && <span className='exposure-container--header--last-update-time'>{'Last Update: ' + moment(exposure.timestamp).format('HH:mm:ss')}</span>}
                    <button className='exposure-container--header--fetch-button'
                        disabled={!canFetchLatestExposure}
                        onClick={() => { 
                            this.setState({ canFetchLatestExposure: false })
                            dispatch(fetchExposures())
                            dispatch(fetchInitialBalance())
                            dispatch(fetchExposureAdjustments())
                            setTimeout(() => { 
                                if (this._mounted) {
                                    this.setState({ canFetchLatestExposure: true })
                                }
                            }, 3000)
                        }} >{'Fetch Latest'}</button>
                        {this.SignSnapshotPopup()}
                </div>
            </div>
        )
    }

    SnaphostPopup (comparisingIndex=0) {
        const { comparison, exposureTimestamps, signedExposurePreviews, snapshotExposureType } = this.state
        const comparisingExposure = comparison.exposures[comparisingIndex]
        const { exposureId, type, isFetching } = comparisingExposure
        let comparisingExposureName = ''
        if (exposureId) {
            if (exposureId === LATEST) {
                comparisingExposureName = LATEST
            } else if (type === EXPOSURE_TYPES.RECENT && _.has(exposureTimestamps, exposureId)) {
                comparisingExposureName = moment(exposureTimestamps[exposureId]).format('MM-DD HH:mm:ss')
            } else if (type === EXPOSURE_TYPES.SIGNED && _.has(signedExposurePreviews, exposureId)) {
                const preview = signedExposurePreviews[exposureId]
                const { timestamp, portfolios, user } = preview
                comparisingExposureName = (
                    <Fragment>
                        {moment(timestamp).format('MM-DD HH:mm')}
                        <span className={'portfolio-name' + (_.isEmpty(portfolios) ? ' all' : '')}>{!_.isEmpty(portfolios) ? portfolios.join(', ') : 'All'}</span>
                        {`By ${user}`}
                    </Fragment>
                )
            }
        }

        return (
            <Popup className='exposure-container--snapshot-popup'
                on={'click'}
                trigger={<button className='exposure-container--snapshot-popup--trigger'>
                    {!_.isEmpty(comparisingExposureName) ? comparisingExposureName : <span>{'Select'}</span>}
                    <FiChevronUp />
                </button>}
                onOpen={() => {
                    this.updateSignedExpsourePreviews(true)
                }}>
                <div className='exposure-container--snapshot-popup--list' onScroll={(e) => {
                    const { clientHeight, scrollTop, scrollHeight } = e.target

                    if (clientHeight + scrollTop + 2 >= scrollHeight) {
                        if (snapshotExposureType === EXPOSURE_TYPES.RECENT) {
                            this.updateExposureTimestamps(Math.max(0, _.size(exposureTimestamps) - 1))
                        } else if (snapshotExposureType === EXPOSURE_TYPES.SIGNED) {
                            this.updateSignedExpsourePreviews()
                        }
                    }
                }}>
                    {snapshotExposureType === EXPOSURE_TYPES.RECENT && _.map(exposureTimestamps, (timestamp, exposureId) => {
                        return (
                            <button className='exposure-container--snapshot-popup--item' key={exposureId}
                                disabled={isFetching}
                                onClick={() => { 
                                    this.handleClickExposureSnapshot({ 
                                        comparisingExposureIndex: comparisingIndex,
                                        type: snapshotExposureType,
                                        newExposureId: exposureId
                                    }) 
                                }}>
                                {exposureId === LATEST ? LATEST : moment(timestamp).format('MM-DD HH:mm:ss')}
                            </button>
                        )
                    })}
                    {snapshotExposureType === EXPOSURE_TYPES.SIGNED 
                    && _.sortBy(signedExposurePreviews, preview => -moment(preview.timestamp).valueOf())
                    .map(preview => {
                        return (
                            <button className='exposure-container--snapshot-popup--item left-algin' key={preview._id}
                                disabled={isFetching}
                                onClick={() => { 
                                    this.handleClickExposureSnapshot({ 
                                        comparisingExposureIndex: comparisingIndex,
                                        type: snapshotExposureType,
                                        newExposureId: preview._id
                                    }) 
                                }}>
                                {moment(preview.timestamp).format('MM-DD HH:mm')}
                                <span className={'portfolio-name' + (_.isEmpty(preview.portfolios) ? ' all' : '')}>{!_.isEmpty(preview.portfolios) ? preview.portfolios.join(', ') : 'All'}</span>
                                {`By ${preview.user}`}
                            </button>
)
                    })}
                </div>
                <div className='exposure-container--snapshot-popup--types'>
                    {_.map(EXPOSURE_TYPES, type => {
                        return (
                            <button className={'exposure-container--snapshot-popup--type' + (type === snapshotExposureType ? ' selected' : '')}
                                key={type}
                                onClick={() => { this.setState({ snapshotExposureType: type }) }}>
                                {type}
                            </button>
                        )
                    })}
                </div>
            </Popup>
        )
    }

    ExposureComparisonBlock (index) {
        const { comparison, portfolioNames, mode, groupBy, exposureTimestamps, signedExposurePreviews, homeCurrency } = this.state
        const comparisingExposure = comparison.exposures[index]
        const { exposureId, items: exposureItems, initialBalance, pricings, type } = comparisingExposure
        let csvFileName = null
        if (type === EXPOSURE_TYPES.RECENT && exposureId !== LATEST && _.has(exposureTimestamps, exposureId)) {
            csvFileName = `EXPOSURE_SNAPSHOT_${moment(exposureTimestamps[exposureId]).format('YYYY-MM-DD-HH_mm_ss')}.csv`
        } else if (type === EXPOSURE_TYPES.SIGNED && _.has(signedExposurePreviews, exposureId)) {
            const { timestamp, user } = signedExposurePreviews[exposureId]
            csvFileName = `EXPOSURE_SNAPSHOT_${moment(timestamp).format('YYYY-MM-DD-HH_mm_ss')}_${user}.csv`
        }
        return (
            <div className='exposure-comparison-block' ref={(node) => { 
                if (index > 0) {
                    this.rightComparisonBlockNode = node
                } else {
                    this.leftComparisonBlockNode = node
                }
            }}>
                <div className='exposure-comparison-block--table'>
                    <ExposureTable 
                        tmpIndex={index}
                        portfolioNames={portfolioNames}
                        mode={mode}
                        homeCurrency={homeCurrency}
                        tableGroupBy={groupBy}
                        exposureItems={exposureItems} 
                        exposureItemsToCompare={comparison.exposures[index === 0 ? 1 : 0].items}
                        initialBalance={initialBalance}
                        initialBalanceToCompare={comparison.exposures[index === 0 ? 1 : 0].initialBalance}
                        pricings={pricings}
                        pricingsToCompare={comparison.exposures[index === 0 ? 1 : 0].pricings}
                        comparisonRules={index === 0 ? ['UNIQUE_EXPOSURES'] : ['EXPOSURE_DIFF', 'UNIQUE_EXPOSURES']}
                        uniqueExposureBackgroundColor={index === 0 ? '#9c5b5b' : '#2e6f6f'}
                        config={comparison.tableConfig} 
                        csvFileName={csvFileName}
                        onChangeConfig={(newConfig) => { 
                            this.setState({
                                comparison: dotProp.set(comparison, `tableConfig`, newConfig)
                            })
                        }}/>
                </div>
                <div className='exposure-comparison-block--footer'>
                    <div className='exposure-comparison-block--footer--snapshots'>
                        <span>{'SNAPSHOT'}</span>
                        {this.SnaphostPopup(index)}
                    </div>
                    {type === EXPOSURE_TYPES.RECENT && exposureId !== LATEST && this.SignSnapshotPopup(exposureId)}
                    {comparisingExposure.isFetching && <div className='exposure-comparison-block--footer--fetching-text vertical-centered'>{'Fetching ...'}</div>}
                </div>
            </div>
        )
    }

    render () {
        const { exposure, initialBalance, symbolPricings } = this.props
        const { comparison, portfolioNames, mode, groupBy, exposureTimestamps, shouldShowExposureChartModal, homeCurrency } = this.state

        const exposurePricings = _.reduce(symbolPricings, (result, pricingItem, symbolName) => {
            if (_.has(pricingItem, 'last')) {
                result[symbolName] = Number(pricingItem.last)
            }
            return result
        }, {})

        return (
                <Fragment>
                    <div className='exposure-container'>
                        {this.Header()}
                        <div className='exposure-container--body'>
                            {comparison.enabled 
                            ? <div className='exposure-container--comparison-wrapper' ref={(node) => { this.comparisonWrapperNode = node }}>
                                {this.ExposureComparisonBlock(0)}
                                <div className='exposure-container--comparison-wrapper--draggable-line' 
                                    ref={(node) => { this.draggableLine = node }}
                                    draggable
                                    onDragOver={(e) => { e.preventDefault() }}
                                    onDrag={(e) => { this.handleDragLine(e) }} />
                                {this.ExposureComparisonBlock(1)}
                            </div>
                            : <div className='exposure-container--main'>
                                <ExposureTable 
                                    portfolioNames={portfolioNames}
                                    mode={mode}
                                    homeCurrency={homeCurrency}
                                    tableGroupBy={groupBy}
                                    exposureItems={exposure.items} 
                                    initialBalance={initialBalance} 
                                    pricings={exposurePricings} />
                            </div>}
                            <div className='exposure-container--right-column'>
                                {comparison.enabled 
                                && <ExposureChart
                                    portfolioNames={portfolioNames}
                                    onClickFetchSnapshotButton={({ index, timestamp }) => {
                                        const exposureId = _.findKey(exposureTimestamps, v => v === timestamp)
                                        if (exposureId) {
                                            this.handleClickExposureSnapshot({ comparisingExposureIndex: index, type: EXPOSURE_TYPES.RECENT, newExposureId: exposureId })
                                        }
                                    }} 
                                    onClickExpandButton={() => { this.setState({ shouldShowExposureChartModal: true }) }} />}
                            </div>
                        </div>
                    </div>
                    {shouldShowExposureChartModal && 
                    <Modal 
                        overlayClassName='exposure-container--chart-modal--overlay' 
                        className='exposure-container--chart-modal'
                        isOpen>
                        <ExposureChart
                            portfolioNames={portfolioNames}
                            shouldHideExpandButton
                            shouldHideMinimizeButton={false}
                            shouldUseWrapperHeight 
                            onClickMinimizeButton={() => { this.setState({ shouldShowExposureChartModal: false }) }} /> 
                    </Modal>}
                    {/* <div className='exposure-container--chart-modal'>
                         <div className='exposure-container--chart-modal--main'>
                             <ExposureChart 
                                 portfolioName={portfolioName} 
                                 shouldHideExpandButton
                                 shouldHideMinimizeButton={false}
                                 shouldUseWrapperHeight 
                                 onClickMinimizeButton={() => { this.setState({ shouldShowExposureChartModal: false }) }}/> 
                         </div>
                     </div> */}
                </Fragment>
        )
    }
}

ExposureContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    exposure: PropTypes.object.isRequired,
    initialBalance: PropTypes.object.isRequired,
    symbolPricings: PropTypes.object.isRequired,
    authUsername: PropTypes.string.isRequired,

    workspaceComponentId: PropTypes.string
}

function mapStateToProps (state) {
    return {
        exposure: state.trading.exposure,
        initialBalance: state.trading.initialBalance,
        symbolPricings: state.symbol.pricings,
        authUsername: state.auth.username
    }
}

export default connect(mapStateToProps)(ExposureContainer)