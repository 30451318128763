import React, { memo, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { TiWarning } from 'react-icons/ti'

import SearchSelect from '../common/searchSelect/SearchSelect'
import Toggle from '../common/toggle/Toggle'
import Popup from '../common/popup/Popup'
import SignalParamList from './SignalParamList'

import { SignalProfileShape } from './profilePropTypes'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectConfigItem } from '../config/configReducer'

function SignalParam ({ signalProfile, paramConfig, paramKey, value, onChange=()=>{} }) {

    const signalProfileConfig = useShallowEqualSelector(state => selectConfigItem(state, 'SIGNAL_PROFILE_CONFIG'))
    const { PARAM_TYPES, SIDES, TRANSFORMS } = _.get(signalProfileConfig, 'result') || {}

    const { symbols, samplers, pricing_models: pricingModels, variables, models } = signalProfile || {}
    const { type: paramType, description: paramDescription, defaultValue } = paramConfig || {}

    const [isFocused, setIsFocused] = useState(false)
    const paramRef = useRef(null)

    useClickAway(paramRef, () => {
        setIsFocused(false)
    })

    const symbolOptions = useMemo(() => {
        return _.map(symbols, _symbolName => ({
            value: _symbolName,
            name: _symbolName
        }))
    }, [symbols])

    const samplerOptions = useMemo(() => {
        return _.reduce(samplers, (_result, _sampler) => {
            const _samplerName = _.get(_sampler, '0')
            if (!_.isEmpty(_samplerName)) {
                _result.push({
                    value: _samplerName,
                    name: _samplerName
                })
            }
            return _result
        }, [])
    }, [samplers])

    const pricingModelOptions = useMemo(() => {
        return _.reduce(pricingModels, (_result, _pricingModel) => {
            const _pricingModelName = _.get(_pricingModel, '0')
            if (!_.isEmpty(_pricingModelName)) {
                _result.push({
                    value: _pricingModelName,
                    name: _pricingModelName
                })
            }
            return _result
        }, [])
    }, [pricingModels])

    const variableOptions = useMemo(() => {
        return _.reduce(variables, (_result, _variable) => {
            const _variableName = _.get(_variable, '0')
            if (!_.isEmpty(_variableName)) {
                _result.push({
                    value: _variableName,
                    name: _variableName
                })
            }
            return _result
        }, [])
    }, [variables])

    const signedTradingVolumeOptions = useMemo(() => {
        return _.reduce(variables, (_result, _variable) => {
            const [_variableName, _variableConfig] = _variable || []
            const _variableType = _.get(_variableConfig, '0')
            if (!_.isEmpty(_variableName) && ['SVol', 'SVol2', 'SVol3'].includes(_variableType)) {
                _result.push({
                    value: _variableName,
                    name: _variableName
                })
            }
            return _result
        }, [])
    }, [variables])

    const modelOptions = useMemo(() => {
        return _.reduce(models, (_result, _model) => {
            const _modelName = _.get(_model, '0')
            if (!_.isEmpty(_modelName)) {
                _result.push({
                    value: _modelName,
                    name: _modelName
                })
            }
            return _result
        }, [])
    }, [models])

    return (
        <div className='signal-param' style={{ zIndex: isFocused ? null : null }}
            onClick={() => { setIsFocused(true) }}>
            <Popup className='signal-param--label--popup'
                disabled={_.isEmpty(paramDescription)}
                trigger={<label className={`signal-param--label${!_.isEmpty(paramDescription) ? ` has-description` : ''}${_.isEmpty(paramKey) ? ` warning` : ''}`}>{paramKey || 'Unkown'}</label>}>
                {paramDescription}
            </Popup>
            {paramType === PARAM_TYPES?.BOOLEAN ? <Toggle
                trueText={'ON'}
                falseText={'OFF'}
                checked={value}
                onChange={(_newValue) => { onChange(_newValue) }} />
            : paramType === PARAM_TYPES?.NUMBER ? <input
                type={'number'}
                placeholder={defaultValue}
                spellCheck={false}
                value={value}
                onChange={(e) => {
                    const _newValue = (e.target.value || '').trim()
                    onChange(!_.isEmpty(_newValue) ? Number(_newValue) : '')
                }} />
            : paramType === PARAM_TYPES?.SYMBOL ? <SearchSelect
                placeholder={'Select Symbol'}
                options={symbolOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} />
            : paramType === PARAM_TYPES?.PRICING_MODEL ? <SearchSelect
                placeholder={'Select Pricing Model'}
                options={pricingModelOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} />
            : paramType === PARAM_TYPES?.SAMPLER ? <SearchSelect
                placeholder={'Select Sampler'}
                options={samplerOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} />
            : paramType === PARAM_TYPES?.ARRAY_OF_SAMPLERS ? <SignalParamList
                values={value}
                options={samplerOptions}
                placeholder={'Search and add sampler'}
                onChange={(_newSamplers) => { onChange(_newSamplers) }} />
            : paramType === PARAM_TYPES?.SIDE ? <div className='signal-param--sides'>
                {_.map(SIDES, _side => {
                    return (
                        <button className={`signal-param--side ${_side}` + (_side === value ? ' selected' : '')}
                            onClick={() => { onChange(_side) }}>
                            {_side}
                        </button>
                    )
                })}
            </div>
            : paramType === PARAM_TYPES?.VARIABLE ? <SearchSelect
                placeholder={'Select Variable'}
                options={variableOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} />
            : paramType === PARAM_TYPES?.ARRAY_OF_VARIABLES ? <SignalParamList
                values={value}
                options={variableOptions}
                placeholder={'Search and add variable'}
                onChange={(_newVariables) => { onChange(_newVariables) }} />
            : paramType === PARAM_TYPES?.SIGNED_VOLUME_VARIABLE ? <SearchSelect
                placeholder={'Select Signed Volume'}
                options={signedTradingVolumeOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} />
            : paramType === PARAM_TYPES?.MODEL ? <SearchSelect
                placeholder={'Select Model'}
                options={modelOptions}
                value={value}
                shouldHighlightInvalidValue
                onChange={(newOption) => { onChange(newOption.value) }} /> 
            : paramType === PARAM_TYPES?.TRANSFORM ? <div className='signal-param--transform'>
                    {_.map(TRANSFORMS, _side => {
                        return (
                            <button className={_side === value ? 'selected' : null}
                                onClick={() => { onChange(_side) }}>
                                {_side}
                            </button>
                        )
                    })}
            </div>
            : <div className='signal-param--config-missing'>
                <TiWarning />
                {'Config missing'}
            </div>}
        </div>
    )
}

SignalParam.propTypes = {
    signalProfile: SignalProfileShape.isRequired,
    paramConfig: PropTypes.shape({
        type: PropTypes.string.isRequired,
        description: PropTypes.string,
        defaultValue: PropTypes.any,
        optional: PropTypes.bool
    }).isRequired,
    paramKey: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
}

export default memo(SignalParam)