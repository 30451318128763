import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { FaSortAmountDownAlt, FaSortAmountDown, FaCheck } from 'react-icons/fa'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import PositionItem from './PositionItem'
import MarginAccountBalanceItem from '../account/MarginAccountBalanceItem'
import PortfolioMarginAccountInfo from '../account/PortfolioMarginAccountInfo'
import CrossMarginAccountBalanceItem from '../account/CrossMarginAccountBalanceItem'
import SpotLoanAccountBalanceItem from '../account/SpotLoanAccountBalanceItem'
import OptionAccountBalanceItem from '../account/OptionAccountBalanceItem'
import TokenTransferEditor, { BULK_TRANSFER_MODES, OPERATION_MODES, TransferAccount, TRANSFER_MODES } from '../account/TokenTransferEditor'

import { getPositionLiquidationRatio, getMarginTradingLiquidationRatio, getPositionItemTransferAdvice, 
    getCrossMarginAccountTransferAdivce, getRiskRatioThresholdByPositionItem, getOptionAccountTransferAdvice } from '../../util/tradingUtil'
import { isMetSearchStringCriteria } from '../../util/util'
import { getPortfolioNames } from '../../util/accountUtil'
import { getSymbolAttributeByName, getTokenPriceInUSD } from '../../util/symbolUtil'
import BnbfutaPM2UnifiedAccountBalanceItem from '../account/BnbfutaPM2UnifiedAccountBalanceItem'
import PortfolioMarginAccountIMInfo from '../account/PortfolioMarginAccountIMInfo'
import PortfolioMarginEffRatioVirtualAccountInfo from '../account/PortfolioMarginEffRatioVirtualAccountinfo'

const RiskTypes = {
    POSITION: 'POSITION',
    MARGIN: 'MARGIN',
    CROSS_MARGIN: 'CROSS_MARGIN',
    PORTFOLIO_MARGIN: 'PORTFOLIO_MARGIN',
    PORTFOLIO_MARGIN_IM: 'PORTFOLIO_MARGIN_IM',
    PORTFOLIO_MARGIN_EFF_RATIO: 'PORTFOLIO_MARGIN_EFF_RATIO',
    OPTION: 'OPTION',
    SPOT_LOAN: 'SPOT_LOAN',
    BNBFUTA_PM2: 'BNBFUTA_PM2'
}

export const RATIO_HIGHLIGHT_THRESHOLDS = {
    PORTFOLIO_MARGIN_ACCOUNT: 2,
    PORTFOLIO_MARGIN_ACCOUNT_IM: 1.4,
    PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT: 1.25,
    PORTFOLIO_MARGIN_EFF_RATIO_VIRTUAL_ACCOUNT: 0.02
}

const RiskItem = ({ type, symbol, ratio, riskRatioThresholdReference, ratioDiff, ratioHighlightThreshold, detail }) => {
    return { type, symbol, ratio, riskRatioThresholdReference, ratioHighlightThreshold, ratioDiff, detail }
}

const ALL = 'ALL'
const HIDDEN_PORTFOLIOS = ['dp', 'et1', 'falconx', 'et7_wj', 'et8_vincent', 'et9_vincent', 'et_triadcapital', 'et_qpi', 'et10_bp', 'et11_bq', 'et_okex_qpi', 'et_okex_qpi_bp']

const getSessionStoragePortfolio = () => {
    const portfolioNames = getPortfolioNames()
    const portfolio = sessionStorage.riskContainerPortfolio
    return portfolioNames.includes(portfolio) ? portfolio : ALL
}

const updateSessionStoragePortfolio = (portfolio) => {
    sessionStorage.riskContainerPortfolio = portfolio
}

class RiskContainer extends Component {
    constructor (props) {
        super(props) 
        
        this.SORT_ORDERS = {
            ASC: 'ASC',
            DESC: 'DESC'
        }

        this.state = {
            showSearch: false,
            searchString: '',
            portfolioName: getSessionStoragePortfolio(),
            shouldShowRiskDetail: this.getShouldShowDetail(),
            sortOrder: this.getSortOrder(),
            riskItemIdsShouldShowDetail: {},
            bulkTransfer: {
                enabled: false,
                token: null,
                accountNameToTransferItemMaps: {},
                positionFilterSymbolName: null
            },
            bulkTransferPopup: {
                shouldShowManyToOne: false,
                shouldShowOneToMany: false,
                config: {}
            }
        }

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })

        this.listNode = null
        this.prevRiskItemIds = []
        this.renderedStartIndex = null
    }

    getRiskItemId (riskItem) {
        return riskItem.type === RiskTypes.POSITION ? `POSITION--${riskItem.detail.account_name}--${riskItem.detail.product_name}`
        : riskItem.type === RiskTypes.MARGIN ? `MARGIN--${riskItem.detail.acct_name}--${riskItem.detail.pair}`
        : riskItem.type === RiskTypes.CROSS_MARGIN ? `CROSS_MARGIN--${riskItem.detail.acct_name}--${riskItem.detail.coin}`
        : riskItem.type === RiskTypes.PORTFOLIO_MARGIN ? `PORTFOLIO_MARGIN--${_.get(riskItem, `detail.acct_name`)}--${_.get(riskItem, `detail.coin`)}`
        : riskItem.type === RiskTypes.PORTFOLIO_MARGIN_IM ? `PORTFOLIO_MARGIN_IM--${_.get(riskItem, `detail.acct_name`)}--${_.get(riskItem, `detail.coin`)}`
        : riskItem.type === RiskTypes.PORTFOLIO_MARGIN_EFF_RATIO ? `PORTFOLIO_MARGIN_EFF_RATIO--${_.get(riskItem, `detail.acct_name`)}`
        : riskItem.type === RiskTypes.OPTION ? `OPTION--${riskItem.detail.acct_name}--${riskItem.detail.coin}`
        : riskItem.type === RiskTypes.BNBFUTA_PM2 ? `BNBFUTA_PM2--${riskItem.detail.acct_name}`
        : riskItem.type === RiskTypes.SPOT_LOAN ? `SPOT_LOAN--${riskItem.detail.acct_name}--${riskItem.detail.coin}`
        : null
    }

    getShouldShowDetail () {
        return sessionStorage.shouldShowRiskDetail === 'true'
    }

    updateShouldShowDetail (newShouldShowDetail) {
        sessionStorage.shouldShowRiskDetail = newShouldShowDetail ? 'true' : 'false'
        this.setState({
            shouldShowRiskDetail: this.getShouldShowDetail(),
            riskItemIdsShouldShowDetail: {}
        })
    }

    getSortOrder () {
        return sessionStorage.riskContainerSortOrder || this.SORT_ORDERS.ASC
    }

    updateSortOrder (newSortOrder=this.SORT_ORDERS.ASC) {
        sessionStorage.riskContainerSortOrder = newSortOrder
        this.setState({
            sortOrder: this.getSortOrder()
        })
    }

    getRiskItems () {
        const { searchString, portfolioName, sortOrder } = this.state
        const { positions, marginAccountBalances, crossMarginAccountBalances, bnbfutaPM2UnifiedAccountBalances, spotLoanAccountBalances, portfolioMarginAccountInfo, portfolioMarginVirtualAccountInfo, portfolioMarginEffRatioVirtualAccountInfo,
            deribitPortfolioMarginAccountInfo, deribitPortfolioMarginAccountIMInfo, symbolPricings, accountItems, riskRatioThresholds, liquidationRatios } = this.props

        const result = []

        const filteredPositions = []
        _.forEach(positions, position => {
            const { product_name: symbol, account_name: accountName } = position 
            const positionPortfolioName = _.get(accountItems, `${accountName}.portfolio_name`)
            const targetString = `position ${symbol} ${accountName}`
            const liquidationRatioItem = _.get(liquidationRatios, `${accountName}--${symbol}`) || {}
            if (isMetSearchStringCriteria(targetString, searchString) 
                && (portfolioName === ALL || positionPortfolioName === portfolioName)
                && !HIDDEN_PORTFOLIOS.includes(positionPortfolioName)) {
                filteredPositions.push({
                    ...position,
                    crossMarginGroup: _.get(liquidationRatioItem, 'cross_margin_group'),
                    adjustedPositionUSD: _.get(liquidationRatioItem, 'adjusted_position_usd'),
                    effectiveRatio: _.get(liquidationRatioItem, 'effective_ratio')
                })
            }
        })
        
        const [positionsWithCrossMarginGroup, positionsWithoutCrossMarginGroup] = _.partition(filteredPositions, position => !_.isEmpty(position.crossMarginGroup))

        const postionsPerAccountCrossMarginGroup = _.groupBy(positionsWithCrossMarginGroup, position => {
            const { account_name: accountName, crossMarginGroup } = position 
            return `${accountName}--${crossMarginGroup}`
        })

        const seivedPositions = _.concat(
            _.map(postionsPerAccountCrossMarginGroup, positions => {
                if (_.size(positions) === 1) {
                    return positions[0]
                } else {
                    return _.maxBy(positions, position => Math.abs(position.adjustedPositionUSD || 0))
                }
            }),
            positionsWithoutCrossMarginGroup
        )

        _.forEach(seivedPositions, position => {
            const { product_name: symbol, liquidation_price: liquidationPrice, crossMarginGroup, effectiveRatio } = position 
            const { exchangeName } = getSymbolAttributeByName(symbol)
            const pricingItem = symbolPricings[symbol]
            const ratio = (!_.isEmpty(crossMarginGroup) || exchangeName === 'COINBASE') && !_.isNil(effectiveRatio) ? effectiveRatio
                : Number(liquidationPrice) > 0 ? getPositionLiquidationRatio(position, pricingItem) 
                : null
            const riskRatioThreshold = getRiskRatioThresholdByPositionItem(riskRatioThresholds, position)
            const riskRatioThresholdReference = _.get(riskRatioThreshold, 'reference')
            result.push(RiskItem({
                type: RiskTypes.POSITION,
                symbol,
                ratio,
                riskRatioThresholdReference,
                ratioDiff: !_.isNil(ratio) && !_.isNil(riskRatioThresholdReference) ? Math.abs(ratio) - Number(riskRatioThresholdReference) : null,
                detail: position
            }))
        })

        _.forEach(marginAccountBalances, marginAccountBalance => {
            const { coin1, coin2, acct_name: accountName, liquidation_price: liquidationPrice } = marginAccountBalance
            const accountItem = accountItems[accountName]
            const pricingItem = _.has(accountItem, 'exchange_name') ? symbolPricings[`${coin1}_${coin2}_${accountItem.exchange_name}_SPT`] : null
            const ratio = liquidationPrice > 0 ? getMarginTradingLiquidationRatio(marginAccountBalance, pricingItem) : null
            const targetString = `margin ${coin1}-${coin2} ${accountName}`
            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                result.push(RiskItem({
                    type: RiskTypes.MARGIN,
                    symbol: _.has(accountItem, 'exchange_name') ? `${coin1}_${coin2}_${accountItem.exchange_name}_SPT` : null,
                    ratio,
                    detail: marginAccountBalance
                }))
            }
        })

        _.forEach(bnbfutaPM2UnifiedAccountBalances, _unifiedAccountBalance => {
            const { acct_name: accountName, uniMMR } = _unifiedAccountBalance
            const accountItem = accountItems[accountName]
            const targetString = `BNBFUTA PM2 ${accountName}`

            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (uniMMR - 2) / 2 
                result.push(RiskItem({
                    type: RiskTypes.BNBFUTA_PM2,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(uniMMR) - 2,
                    detail: _unifiedAccountBalance
                }))
            }
        })

        // _.forEach(optionAccountBalances, optionAccountBalance => {
        //     const { acct_name: accountName, coin, mmr } = optionAccountBalance
        //     const accountItem = accountItems[accountName]
        //     const targetString = `option ${coin} ${accountName}`

        //     if (isMetSearchStringCriteria(targetString, searchString)
        //         && (portfolioName === ALL
        //             || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName))
        //         ) {
        //         const ratio = (mmr - 2) / 2 
        //         result.push(RiskItem({
        //             type: RiskTypes.OPTION,
        //             symbol: null,
        //             ratio,
        //             ratioDiff: Math.abs(mmr) - 2,
        //             detail: optionAccountBalance
        //         }))
        //     }
        // })

        _.forEach(spotLoanAccountBalances, spotLoanAccountBalance => {
            const { acct_name: accountName, coin, risk_rate: riskRate } = spotLoanAccountBalance
            const accountItem = accountItems[accountName]
            const targetString = `spot loan ${coin} ${accountName}`
            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (riskRate - 1.6) / 1.6
                result.push(RiskItem({
                    type: RiskTypes.SPOT_LOAN,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(riskRate) - 1.6,
                    detail: spotLoanAccountBalance
                }))
            }
        })

        const filteredCrossMarginAccountBalances = _.filter(crossMarginAccountBalances, crossMarginAccountBalance => {
            const { acct_name: accountName, coin } = crossMarginAccountBalance
            const accountItem = accountItems[accountName]
            return isMetSearchStringCriteria(`cross margin ${coin} ${accountName}`, searchString) &&
                (portfolioName === ALL || (_.get(accountItem, 'portfolio_name')=== portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
        })
        const crossMarginAccountBalancesPerAccount = _.groupBy(filteredCrossMarginAccountBalances, 'acct_name')
        _.forEach(crossMarginAccountBalancesPerAccount, items => {
            const pickedCrossMarginAccountBalance = _.find(items, item => _.toUpper(item.coin || '') === 'USDT')
                || _.maxBy(items, item => {
                    const { balance, coin } = item
                    const coinPrice = getTokenPriceInUSD(coin, symbolPricings)
                    return Number(balance) * Number(coinPrice)
                })
            if (!_.isNil(pickedCrossMarginAccountBalance)) {
                const { risk_rate: riskRate } = pickedCrossMarginAccountBalance
                const ratio = (riskRate - 1.6) / 1.6
                result.push(RiskItem({
                    type: RiskTypes.CROSS_MARGIN,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(riskRate) - 1.6,
                    detail: pickedCrossMarginAccountBalance
                }))
            }
        })

        _.forEach(_.concat(Object.values(portfolioMarginAccountInfo), Object.values(deribitPortfolioMarginAccountInfo)), portfolioMarginAccountItem => {
            const { acct_name: accountName, coin, mmr } = portfolioMarginAccountItem
            const accountItem = accountItems[accountName]
            const targetString = `portfolio margin account pma ${accountName} ${coin || ''}`

            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (mmr - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT) / RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT 
                result.push(RiskItem({
                    type: RiskTypes.PORTFOLIO_MARGIN,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(mmr) - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT,
                    ratioHighlightThreshold: RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT,
                    detail: portfolioMarginAccountItem
                }))
            }
        })

        _.forEach(deribitPortfolioMarginAccountIMInfo, portfolioMarginAccountIMItem => {
            const { acct_name: accountName, coin, imr } = portfolioMarginAccountIMItem
            const accountItem = accountItems[accountName]
            const targetString = `portfolio margin account pma im ${accountName} ${coin || ''}`

            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (imr - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT_IM) / RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT_IM 
                result.push(RiskItem({
                    type: RiskTypes.PORTFOLIO_MARGIN_IM,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(imr) - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT_IM,
                    ratioHighlightThreshold: RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_ACCOUNT_IM,
                    detail: portfolioMarginAccountIMItem
                }))
            }
        })


        _.forEach(Object.values(portfolioMarginVirtualAccountInfo), _portfolioMarginVirtualAccountItem => {
            const { acct_name: accountName, mmr } = _portfolioMarginVirtualAccountItem
            const accountItem = accountItems[accountName]
            const targetString = `portfolio virtual margin account pma ${accountName}`

            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL ||
                ((!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)
                    || (_.isEmpty(accountItem?.portfolio_name) && portfolioName === 'prop')
                )) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (mmr - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT) / RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT
                result.push(RiskItem({
                    type: RiskTypes.PORTFOLIO_MARGIN,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(mmr) - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT,
                    ratioHighlightThreshold: RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_VIRTUAL_ACCOUNT,
                    detail: _portfolioMarginVirtualAccountItem
                }))
            }
        })

        _.forEach(Object.values(portfolioMarginEffRatioVirtualAccountInfo), _item => {
            const { acct_name: accountName, eff_ratio: effRatio } = _item
            const accountItem = accountItems[accountName]
            const targetString = `portfolio margin eff ratio virtual account pma ${accountName}`

            if (isMetSearchStringCriteria(targetString, searchString) &&
                (portfolioName === ALL ||
                ((!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName)
                    || (_.isEmpty(accountItem?.portfolio_name) && portfolioName === 'prop')
                )) &&
                !HIDDEN_PORTFOLIOS.includes(accountItem?.portfolio_name)
            ) {
                const ratio = (effRatio - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_EFF_RATIO_VIRTUAL_ACCOUNT) / RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_EFF_RATIO_VIRTUAL_ACCOUNT
                result.push(RiskItem({
                    type: RiskTypes.PORTFOLIO_MARGIN_EFF_RATIO,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(effRatio) - RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_EFF_RATIO_VIRTUAL_ACCOUNT,
                    ratioHighlightThreshold: RATIO_HIGHLIGHT_THRESHOLDS.PORTFOLIO_MARGIN_EFF_RATIO_VIRTUAL_ACCOUNT,
                    detail: _item
                }))
            }
        })

        let sortedRiskItems = []

        const [riskItemsShouldHighlight, riskItemsShouldNotHighlight] = _.partition(result, riskItem => {
            const { ratioDiff } = riskItem
            return !_.isNil(ratioDiff) && ratioDiff < 0
        })

        const [riskItemsHaveNilReference, remainingRiskItems] = _.partition(riskItemsShouldNotHighlight, riskItem => {
            const { type, ratio, riskRatioThresholdReference } = riskItem
            return type === RiskTypes.POSITION && !_.isNil(ratio) && _.isNil(riskRatioThresholdReference)
        })

        if (sortOrder === this.SORT_ORDERS.ASC) {
            sortedRiskItems = _.concat(
                _.sortBy(riskItemsShouldHighlight, riskItem => riskItem.ratioDiff),
                _.sortBy(riskItemsHaveNilReference, riskItem => Math.abs(riskItem.ratio)),
                _.sortBy(remainingRiskItems, riskItem => {
                    return !_.isNil(riskItem.ratioDiff) ? riskItem.ratioDiff
                        : _.isNumber(riskItem.ratio) ? Math.abs(riskItem.ratio)
                        : Infinity
                })
            )
        } else {
            const [remainingRiskItemsHaveRatioDiff, remainingRiskItemsHaveNilRatioDiff] = _.partition(remainingRiskItems, riskItem => !_.isNil(riskItem.ratioDiff))
            sortedRiskItems = _.concat(
                _.sortBy(remainingRiskItemsHaveRatioDiff, riskItem => -riskItem.ratioDiff),
                _.sortBy(riskItemsHaveNilReference, riskItem => -Math.abs(riskItem.ratio)),
                _.sortBy(riskItemsShouldHighlight, riskItem => -riskItem.ratioDiff),
                remainingRiskItemsHaveNilRatioDiff
            )
        }
        return sortedRiskItems
    }

    handleClickAccountName (accountName) {
        const { accountItems } = this.props
        const { searchString } = this.state
        const accountItem = accountItems[accountName]
        const trimmedSearchString = searchString.trim()
        const accountItemsRelated = _.filter(accountItems, item => {
            return item.account_name === accountItem.account_name
                || (!_.isEmpty(item.main_acct_name) 
                    && !['0', 'unknown'].includes(item.main_acct_name)
                    && item.main_acct_name === accountItem.account_name.replace('huobifut_', 'huobi_').replace('bnbfuta_', 'binance_'))
                || (!_.isEmpty(accountItem.main_acct_name) 
                    && !['0', 'unknown'].includes(accountItem.main_acct_name)
                    && accountItem.main_acct_name === item.account_name.replace('huobifut_', 'huobi_').replace('bnbfuta_', 'binance_'))
                || (!_.isEmpty(accountItem.main_acct_name) 
                    && !['0', 'unknown'].includes(accountItem.main_acct_name)
                    && !_.isEmpty(item.main_acct_name) && accountItem.main_acct_name === item.main_acct_name)
        })
        this.setState({
            searchString: trimmedSearchString + (trimmedSearchString.length > 0 ? ' ' : '') + accountItemsRelated.map(item => item.account_name).join(' || ')
        })
    }

    renderRow (params, riskItem) {
        const { shouldShowRiskDetail, riskItemIdsShouldShowDetail, bulkTransfer } = this.state
        const { index, key, parent, style } = params
        const { type, ratioHighlightThreshold, detail } = riskItem
        const riskItemId = this.getRiskItemId(riskItem)
        const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))

        return (
            <CellMeasurer
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ measure, registerChild }) => {
                    let transferAdvice = null, bulkTransferSelected = false, bulkTransferSelectable = false

                    if (bulkTransfer.enabled) {
                        if (type === RiskTypes.POSITION) {
                            transferAdvice = getPositionItemTransferAdvice(detail)
                        } else if (type === RiskTypes.CROSS_MARGIN) {
                            transferAdvice = getCrossMarginAccountTransferAdivce(detail)
                        } else if (type === RiskTypes.OPTION) {
                            transferAdvice = getOptionAccountTransferAdvice(detail)
                        }

                        if (!_.isNil(transferAdvice)) {
                            bulkTransferSelected = transferAdvice.token === bulkTransfer.token && _.has(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name)
                            bulkTransferSelectable = (_.isNil(headTransferItem) 
                                    && !_.isNil(transferAdvice.token) 
                                    && !_.isNil(transferAdvice.transferItem))
                                || (transferAdvice.token === bulkTransfer.token 
                                    && _.has(transferAdvice.transferItem, 'originTransferAccount')
                                    && _.has(headTransferItem, 'originTransferAccount')
                                    && _.isEqual(transferAdvice.transferItem.originTransferAccount, headTransferItem.originTransferAccount))
                        }
                    }
                    
                    return (
                        <div className={'risk-container--item' + (!shouldShowRiskDetail ? ' clickable' : '') + (bulkTransfer.enabled && !bulkTransferSelectable ? ' not-allowed' : '')} 
                            ref={registerChild}
                            style={style}
                            onClick={(e) => {
                                if (bulkTransfer.enabled) {
                                    if (!bulkTransferSelectable) {
                                        e.stopPropagation()
                                    } else if (!bulkTransferSelected) {
                                        this.setState({
                                            bulkTransfer: Object.assign({}, bulkTransfer, {
                                                token: transferAdvice.token,
                                                accountNameToTransferItemMaps: dotProp.merge(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name, transferAdvice.transferItem),
                                                positionFilterSymbolName: _.isNil(bulkTransfer.positionFilterSymbolName) ? detail.product_name : bulkTransfer.positionFilterSymbolName
                                            })
                                        })
                                    } else {
                                        this.setState({
                                            bulkTransfer: Object.assign({}, bulkTransfer, {
                                                token: _.size(bulkTransfer.accountNameToTransferItemMaps) === 1 ? null : bulkTransfer.token,
                                                accountNameToTransferItemMaps: _.omit(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name),
                                                positionFilterSymbolName: _.size(bulkTransfer.accountNameToTransferItemMaps) === 1 ? null : bulkTransfer.positionFilterSymbolName
                                            })
                                        })
                                    }
                                } else if (!shouldShowRiskDetail) {
                                    this.setState({
                                        riskItemIdsShouldShowDetail: _.has(riskItemIdsShouldShowDetail, riskItemId) 
                                            ? dotProp.delete(riskItemIdsShouldShowDetail, riskItemId)
                                            : dotProp.set(riskItemIdsShouldShowDetail, riskItemId, 1)
                                    },() => {
                                        measure()
                                    })
                                }
                            }}>
                            {bulkTransfer.enabled && bulkTransferSelectable && 
                            <div className={'risk-container--item--bulk-transfer-select-mark' + (bulkTransferSelected ? ' marked' : '')}>
                                {bulkTransferSelected && <FaCheck />}
                            </div>}
                            {type === RiskTypes.POSITION && 
                            <PositionItem 
                                positionItem={detail}
                                hideDetails={!shouldShowRiskDetail && !_.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                shouldShowTag 
                                tokenTransferEnabled={!bulkTransfer.enabled}
                                isAccountNameClickable
                                onClickAccountName={(accountName) => { this.handleClickAccountName(accountName) }} />}
                            {type === RiskTypes.MARGIN &&
                            <MarginAccountBalanceItem 
                                marginAccountBalance={detail}
                                shouldShowAccountTypeTag 
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                tokenTransferEnabled 
                                isAccountNameClickable
                                onClickAccountName={(accountName) => { this.handleClickAccountName(accountName) }}
                                onChangeComponentHeight={() => { setTimeout(() => { measure() }) }} />}
                            {type === RiskTypes.CROSS_MARGIN && 
                            <CrossMarginAccountBalanceItem 
                                crossMarginAccountBalance={detail}
                                shouldShowAccountTypeTag
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                tokenTransferEnabled={!bulkTransfer.enabled} 
                                onChangeComponentHeight={() => { setTimeout(() => { measure() }) }} />}
                            {type === RiskTypes.SPOT_LOAN &&
                            <SpotLoanAccountBalanceItem 
                                spotLoanAccountBalance={detail} 
                                shouldShowAccountTypeTag
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                onChangeComponentHeight={() => { setTimeout(() => { measure() }) }} />}
                            {type === RiskTypes.PORTFOLIO_MARGIN &&
                            <PortfolioMarginAccountInfo 
                                portfolioMarginAccount={detail}
                                tokenTransferEnabled
                                ratioHighlightThreshold={ratioHighlightThreshold} />}
                            {type === RiskTypes.PORTFOLIO_MARGIN_IM &&
                            <PortfolioMarginAccountIMInfo 
                                portfolioMarginAccountIM={detail}
                                tokenTransferEnabled
                                ratioHighlightThreshold={ratioHighlightThreshold} />}
                            {type === RiskTypes.PORTFOLIO_MARGIN_EFF_RATIO &&
                            <PortfolioMarginEffRatioVirtualAccountInfo
                                portfolioMarginEffRatioVirtualAccount={detail}
                                ratioHighlightThreshold={ratioHighlightThreshold} />}
                            {type === RiskTypes.OPTION &&
                            <OptionAccountBalanceItem 
                                optionAccountBalance={detail}
                                shouldShowAccountTypeTag
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                tokenTransferEnabled />}
                            {type === RiskTypes.BNBFUTA_PM2 &&
                            <BnbfutaPM2UnifiedAccountBalanceItem
                                bnbfutaPM2UnifiedAccountBalance={detail}
                                shouldShowAccountTypeTag
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} />}
                        </div>
                    )
                }}
            </CellMeasurer>
        )
    }

    render () {
        const { searchString, portfolioName, shouldShowRiskDetail, sortOrder, bulkTransfer, bulkTransferPopup } = this.state
        const riskItems = this.getRiskItems()
        const riskItemIds = riskItems.map(riskItem => this.getRiskItemId(riskItem))
        const portfolioNames = getPortfolioNames()

        const portfolioOptions = _.map(_.concat(ALL, _.without(portfolioNames, ...HIDDEN_PORTFOLIOS)), portfolio => {
            return {
                value: portfolio,
                name: portfolio
            }
        })

        if (!_.isEqual(this.prevRiskItemIds, riskItemIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevRiskItemIds = riskItemIds
        }

        return (
            <div className='risk-container'>
                <div className='risk-container--menu'>
                    <button className='risk-container--toggle-detail-button' 
                        onClick={() => { 
                            this.cellMeasurerCache.clearAll()
                            this.updateShouldShowDetail(!shouldShowRiskDetail) 
                            this.listNode.scrollToPosition(0)
                        }}>
                        {shouldShowRiskDetail ? 'Hide Details' : 'Show Details'}
                    </button>
                    <button className={'risk-container--toggle-bulk-transfer-selector-button' + (bulkTransfer.enabled ? ' enabled' : '')}
                        onClick={() => {
                            this.setState({
                                bulkTransfer: Object.assign({}, bulkTransfer, {
                                    enabled: !bulkTransfer.enabled,
                                    token: null,
                                    accountNameToTransferItemMaps: {},
                                    positionFilterSymbolName: null
                                })
                            })
                        }}>
                        {bulkTransfer.enabled ? 'Reset Selection' : 'Select to Bulk Transfer'}
                    </button>
                    <button className='risk-container--sort-order-button' 
                        onClick={() => {
                            this.setState({
                                sortOrder: sortOrder === this.SORT_ORDERS.ASC ? this.SORT_ORDERS.DESC : this.SORT_ORDERS.ASC
                            })
                            this.listNode.scrollToPosition(0)
                        }}>
                        {sortOrder === this.SORT_ORDERS.ASC ? <FaSortAmountDownAlt /> : <FaSortAmountDown />}
                    </button>
                </div>
                <div className='risk-container--criterias'>
                    <input className='risk-container--search-input' value={searchString}
                        placeholder={'Search Symbol, Account'}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => { 
                            this.setState({ searchString: e.target.value }) 
                            setTimeout(() => { this.listNode.scrollToPosition(0) })
                        }} />
                    <div className='risk-container--portfolio'>
                        <Popup className='risk-container--portfolio--hidden-list'
                            trigger={<label className='risk-container--portfolio--hidden-list--trigger'>{'Portfolio'}</label>}>
                            {`Hidden Portfolios: ${_.isEmpty(HIDDEN_PORTFOLIOS) ? 'Empty' : HIDDEN_PORTFOLIOS.join(', ')}`}
                        </Popup>
                        <SearchSelect 
                            value={portfolioName}
                            options={portfolioOptions} 
                            hideSearchBar 
                            onChange={(newOption) => {
                                updateSessionStoragePortfolio(newOption.value)
                                this.setState({ portfolioName: getSessionStoragePortfolio() })
                            }} />
                    </div>
                </div>
                <div className='risk-container--body'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List 
                                ref={(node) => { this.listNode = node }}
                                className='risk-container--list'
                                width={width}
                                height={height} 
                                rowCount={_.size(riskItems)}
                                rowRenderer={params => this.renderRow(params, riskItems[params.index])} 
                                rowHeight={this.cellMeasurerCache.rowHeight} 
                                overscanRowCount={5} 
                                scrollToAlignment={'start'}
                                deferredMeasurementCache={this.cellMeasurerCache}  
                                onRowsRendered={({ startIndex }) => { this.renderedStartIndex = startIndex }} />
                        )}
                    </AutoSizer>
                </div>
                {bulkTransfer.enabled && 
                <div className='risk-container--bulk-transfer-summary'>
                    <span>{`${_.size(bulkTransfer.accountNameToTransferItemMaps)} Selected Account${_.size(bulkTransfer.accountNameToTransferItemMaps) > 1 ? 's' : ''}`}</span>
                    <Popup className='risk-container--bulk-transfer-popup'
                        on={'click'}
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) === 0}
                        trigger={
                            <button
                                disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) === 0} 
                                className='risk-container--bulk-transfer-summary--confirm-button n-to-1'>
                                {'N:1'}
                            </button>
                        }
                        onOpen={() => { 
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            const switchedTransferItems = _.map(Object.values(bulkTransfer.accountNameToTransferItemMaps), transferItem => {
                                const switchedTransferItem = _.cloneDeep(transferItem) 
                                const { originTransferAccount, destinationTransferAccount } = switchedTransferItem
                                switchedTransferItem.originTransferAccount = destinationTransferAccount
                                switchedTransferItem.destinationTransferAccount = originTransferAccount
                                return switchedTransferItem
                            })
                            this.setState({
                                bulkTransferPopup: {
                                    shouldShowManyToOne: true,
                                    shouldShowOneToMany: false,
                                    config: {
                                        updateId: uuidv4(),
                                        token: bulkTransfer.token,
                                        bulkTransferMode: BULK_TRANSFER_MODES.MANY_TO_ONE,
                                        oneToManyOriginTransferAccount: TransferAccount({}),
                                        manyToOneDestinationTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                        transferItems: switchedTransferItems,
                                        positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                    }
                                }
                            })
                        }}>
                        {bulkTransferPopup.shouldShowManyToOne && _.get(bulkTransferPopup, 'config.bulkTransferMode') === BULK_TRANSFER_MODES.MANY_TO_ONE &&
                        <TokenTransferEditor 
                            defaultOperationMode={OPERATION_MODES.BULK}
                            transferMode={TRANSFER_MODES.TRANSFER}
                            bulkTransferConfig={bulkTransferPopup.config} />}
                    </Popup>
                    <Popup className='risk-container--bulk-transfer-popup'
                        on={'click'}
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) <= 0}
                        trigger={
                            <button 
                                disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) <= 0}
                                className='risk-container--bulk-transfer-summary--confirm-button 1-to-n'>
                                {'1:N'}
                            </button>
                        }
                        onOpen={() => { 
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            this.setState({
                                bulkTransferPopup: {
                                    shouldShowOneToMany: true,
                                    shouldShowManyToOne: false,
                                    config: {
                                        updateId: uuidv4(),
                                        token: bulkTransfer.token,
                                        bulkTransferMode: BULK_TRANSFER_MODES.ONE_TO_MANY,
                                        oneToManyOriginTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                        manyToOneDestinationTransferAccount: TransferAccount({}),
                                        transferItems: Object.values(bulkTransfer.accountNameToTransferItemMaps),
                                        positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                    }
                                }
                            })
                        }}>
                        {bulkTransferPopup.shouldShowOneToMany && _.get(bulkTransferPopup, 'config.bulkTransferMode') === BULK_TRANSFER_MODES.ONE_TO_MANY &&
                        <TokenTransferEditor 
                            defaultOperationMode={OPERATION_MODES.BULK}
                            transferMode={TRANSFER_MODES.TRANSFER}
                            bulkTransferConfig={bulkTransferPopup.config} />}
                    </Popup>
                    {/* <button className='risk-container--bulk-transfer-summary--confirm-button n-to-1' 
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) === 0} 
                        onClick={() => {
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            const switchedTransferItems = _.map(Object.values(bulkTransfer.accountNameToTransferItemMaps), transferItem => {
                                const switchedTransferItem = _.cloneDeep(transferItem) 
                                const { originTransferAccount, destinationTransferAccount } = switchedTransferItem
                                switchedTransferItem.originTransferAccount = destinationTransferAccount
                                switchedTransferItem.destinationTransferAccount = originTransferAccount
                                return switchedTransferItem
                            })
                            batch(() => {
                                dispatch(updateLayoutBulkTransferWindowConfig({
                                    updateId: uuidv4(),
                                    token: bulkTransfer.token,
                                    bulkTransferMode: BULK_TRANSFER_MODES.MANY_TO_ONE,
                                    oneToManyOriginTransferAccount: TransferAccount({}),
                                    manyToOneDestinationTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                    transferItems: switchedTransferItems,
                                    positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                }))
                                dispatch(updateLayoutBulkTransferWindowId())
                            })
                        }}>{'N:1'}</button>
                    <button className='risk-container--bulk-transfer-summary--confirm-button 1-to-n' 
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) <= 0}
                        onClick={() => {
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            batch(() => {
                                dispatch(updateLayoutBulkTransferWindowConfig({
                                    updateId: uuidv4(),
                                    token: bulkTransfer.token,
                                    bulkTransferMode: BULK_TRANSFER_MODES.ONE_TO_MANY,
                                    oneToManyOriginTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                    manyToOneDestinationTransferAccount: TransferAccount({}),
                                    transferItems: Object.values(bulkTransfer.accountNameToTransferItemMaps),
                                    positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                }))
                                dispatch(updateLayoutBulkTransferWindowId())
                            })  
                        }}>{'1:N'}</button> */}
                </div>}
            </div>
        )
    }
} 

RiskContainer.propTypes = {
    positions: PropTypes.array.isRequired,
    marginAccountBalances: PropTypes.object.isRequired,
    crossMarginAccountBalances: PropTypes.object.isRequired,
    // optionAccountBalances: PropTypes.object.isRequired,
    spotLoanAccountBalances: PropTypes.object.isRequired,
    bnbfutaPM2UnifiedAccountBalances: PropTypes.object.isRequired,
    portfolioMarginAccountInfo: PropTypes.object.isRequired,
    portfolioMarginVirtualAccountInfo: PropTypes.object.isRequired,
    portfolioMarginEffRatioVirtualAccountInfo: PropTypes.object.isRequired,
    deribitPortfolioMarginAccountInfo: PropTypes.object.isRequired,
    deribitPortfolioMarginAccountIMInfo: PropTypes.object.isRequired,
    symbolPricings: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    riskRatioThresholds: PropTypes.object.isRequired,
    liquidationRatios: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        marginAccountBalances: state.account.balance.margin,
        crossMarginAccountBalances: state.account.balance.crossMargin,
        optionAccountBalances: state.account.balance.option,
        spotLoanAccountBalances: state.account.balance.spotLoan,
        bnbfutaPM2UnifiedAccountBalances: state.account.balance.bnbfutaPM2Unified,
        portfolioMarginAccountInfo: state.account.portfolioMarginAccountInfo,
        portfolioMarginVirtualAccountInfo: state.account.portfolioMarginVirtualAccountInfo,
        portfolioMarginEffRatioVirtualAccountInfo: state.account.portfolioMarginEffRatioVirtualAccountInfo,
        deribitPortfolioMarginAccountInfo: state.account.deribitPortfolioMarginAccountInfo,
        deribitPortfolioMarginAccountIMInfo: state.account.deribitPortfolioMarginAccountIMInfo,
        symbolPricings: state.symbol.pricings,
        accountItems: state.account.items,
        riskRatioThresholds: state.trading.riskRatioThresholds,
        liquidationRatios: state.trading.liquidationRatios
    }
}

export default connect(mapStateToProps)(RiskContainer)