import _ from 'lodash'
import { store } from '../../store'
import { selectConfigItem } from '../config/configReducer'

export function WeightedTransVariableStruct ({ norm_coef=0, variable, weight=0, transform='orig', sm_std=0 }) {
    return {
        norm_coef,
        variable,
        weight,
        transform,
        sm_std
    }
}

function InstanceStruct (instance={}) {
    const signalProfileConfig = selectConfigItem(store.getState(), 'SIGNAL_PROFILE_CONFIG')
    const instanceParams = _.get(signalProfileConfig, 'result.INSTANCE.params') || {}
    const defaultInstance = _.reduce(instanceParams, (_result, _paramConfig, _paramKey) => {
        _result[_paramKey] = _paramConfig.defaultValue ?? null
        return _result
    }, {})
    return Object.assign({}, defaultInstance, instance)
}

export function SignalProfileStruct ({ _id, name='', description='', includes=[], instance=InstanceStruct({}), symbols=[], samplers=[], pricing_models=[], variables=[], models=[], strategies=[] }) {
    return {
        _id,
        name,
        description,
        includes,
        instance,
        symbols,
        samplers,
        pricing_models,
        variables,
        models,
        strategies
    }
}

export function serializeData ({ name='', type='', params={} }) {
    return [name, [type, params]]
}

export function deserializeData (data=[]) {
    return {
        name: _.get(data, '0'),
        type: _.get(data, '1.0'),
        params: _.get(data, '1.1')
    }
}

