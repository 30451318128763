import { DEFAULT_SERVER } from '../../configs/config'
import { secureFetch } from '../../util/util'
export const UPDATE_SETTING_SOUND_EFFECT = 'UPDATE_SETTING_SOUND_EFFECT'

export function updateSettingSoundEffect (params) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SETTING_SOUND_EFFECT,
            params
        })
    }
}

export function muteAlertServer (portfolioName='prop', duration=300000, type='') {
    return (dispatch) => new Promise((resolve, reject) => {
        const params = {
            portfolio_name: portfolioName,
            duration
        }
        if (type === 'balance_alert') {
            params.type = 'balance_alert'
        }

        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/mute_alert_server`, {
            method: 'POST',
            body: JSON.stringify(params)
        }))
        .then(response => resolve(response))
        .catch(error => {
            console.error(`muteAlertServer error: `, error)
            reject(error)
        })
    })
}