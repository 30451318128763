import React, { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import _ from 'lodash'

import { FiAlertCircle } from 'react-icons/fi'

function ProfileStatus ({
    pauseReasonHidden = false,
    profileItem
}) {

    const profileStartStopStatus = profileItem?.isStarting ? 'starting' : profileItem?.isStopping ? 'stopping' : profileItem?.started ? 'started' : 'stopped'
    const profileResumePauseStatus = profileItem?.isResuming ? 'resuming' : profileItem?.isPausing ? 'pausing' : profileItem?.resumed ? 'resumed' : 'paused'
    const loopStatus = profileItem?.params?.LOOP ? 'loop-on' : 'loop-off'

    return (
        <div className='profile-status'>
            {profileItem.crashed && <div className='profile-status--item--item crashed-status'>
                <FiAlertCircle className='profile-status--item--crashed-icon' />
                <div className='profile-status--item--label'>{'CRASHED'}</div>
            </div>}
            <div className='profile-status start-stop-status'>
                <div className={`profile-status--item--bulb ${profileStartStopStatus}`} />
                <div className='profile-status--item--label'>{profileStartStopStatus.toUpperCase()}</div>
            </div>
            <div className='profile-status loop-status'>
                <div className={`profile-status--item--bulb ${loopStatus}`} />
                <div className='profile-status--item--label'>{loopStatus === 'loop-on' ? 'LOOP ON' : 'LOOP OFF'}</div>
            </div>
            {profileItem.started && !profileItem.isStarting && !profileItem.isStopping &&
            <div className='profile-status resume-pause-status'>
                <div className={`profile-status--item--bulb ${profileResumePauseStatus}`} />
                <div className={'profile-status--item--label'}>
                    {profileResumePauseStatus.toUpperCase()}
                    {profileResumePauseStatus === 'paused' && !pauseReasonHidden &&
                    <span>
                        {`: ${profileItem.pauseReason || 'Unkown Reason'} @ 
                        ${_.has(profileItem, 'pauseTimestamp') && moment(profileItem.pauseTimestamp).isValid() ? moment(profileItem.pauseTimestamp).format('MM-DD HH:mm:ss') : 'Unkown Time'}`}
                    </span>}
                </div>
            </div>}
        </div>
    )
}

ProfileStatus.propTypes = {
    pauseReasonHidden: PropTypes.bool,
    profileItem: PropTypes.object.isRequired
}

export default memo(ProfileStatus)