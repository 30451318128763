import React, { memo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useClickAway } from 'react-use'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { MdDragIndicator } from 'react-icons/md'
import { FaAngleRight, FaXmark } from 'react-icons/fa6'
import Popup from '../common/popup/Popup'
import SignalParam from './SignalParam'

import { deserializeData, SignalProfileStruct } from './SignalProfileConfig'
import { SignalProfileShape } from './profilePropTypes'
import { isMetSearchStringCriteria } from '../../util/util'
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectConfigItem } from '../config/configReducer'

const DragHandle = SortableHandle(() => <button className='signal-config-item--sortable-variable-handle'>{<MdDragIndicator />}</button>)

const SortableVariableItem = SortableElement(({
    signalProfile, paramConfigs={}, variableParams={}, isFocused,
    onClickItem=()=>{}, onClickRemove=()=>{}, onChange=()=>{}
}) => {
    return (
        <div className='signal-config-item--sortable-variable-item'
            style={{ zIndex: isFocused ? 9 : null }}
            onClick={() => { onClickItem() }}>
            <DragHandle />
            <div className='signal-config-item--sortable-variable-item--params'>
                {_.map(variableParams, (_value, _key) => {
                    return (
                        <div className={`signal-config-item--sortable-variable-item--params--item`} key={_key}>
                            <SignalParam
                                key={_key}
                                signalProfile={signalProfile}
                                paramKey={_key}
                                value={_value}
                                paramConfig={paramConfigs[_key]}
                                onChange={(_newValue) => {
                                    onChange(dotProp.set(variableParams, _key, _newValue))
                                }} />
                        </div>

                    )
                })}
            </div>
            <button className='signal-config-item--sortable-variable-item--remove-button'
                onClick={() => { onClickRemove() }}>
                <FaXmark />
            </button>
        </div>
    )
})

const SortableVariables = SortableContainer(({ signalProfile={}, variableParamConfig={}, variableParams=[], variableName='', focusedVariableIndex, onChange=()=>{}, onFocusItem=()=>{} }) => {
    const { description, params: paramConfigs } = variableParamConfig || {}
    return _.isArray(variableParams) && (
        <div className='signal-config-item--sortable-variables'>
        <Popup className='signal-config-item--sortable-variables--label--popup'
            disabled={_.isEmpty(description)}
            trigger={<label className={`signal-config-item--sortable-variables--label${!_.isEmpty(description) ? ` has-description` : ''}`}>{'variables'}</label>}>
            {description}
        </Popup>
            <div className='signal-config-item--sortable-variables--list'>
                {_.map(variableParams, (_params, _index) => {
                    return (
                        <SortableVariableItem
                            key={_index}
                            index={_index}
                            signalProfile={signalProfile}
                            paramConfigs={paramConfigs}
                            variableParams={_params}
                            isFocused={focusedVariableIndex === _index}
                            onClickItem={() => {
                                onFocusItem(_index) }}
                            onChange={(_newValue) => {
                                const _newVariableParams = dotProp.set(variableParams, _index, _newValue)
                                onChange(_newVariableParams)
                            }}
                            onClickRemove={() => {
                                const _newVariableParams = dotProp.delete(variableParams, _index)
                                onChange(_newVariableParams)
                            }} />
                    )
                })}
            </div>
            <button className='signal-config-item--sortable-variables--add-button'
                onClick={() => {
                    const _newParam = _.reduce(variableParamConfig?.params, (_result, _paramConfig, _paramKey) => {
                        _result[_paramKey] = _paramConfig.defaultValue
                        return _result
                    }, {})
                    const _newVariableParams = _.concat(variableParams, _newParam)
                    onChange(_newVariableParams)
                }}>
                {`Add ${variableName}`}
            </button>
        </div>
    )
})

function SignalConfigItem ({ signalProfile=SignalProfileStruct({}), configKey, data=['', ['', {}]], namePlaceholder='Config Name', typeSelectorTitle='Config Types', onChangeData=()=>{}, onClickRemove=()=>{} }) {

    const signalProfileConfig = useShallowEqualSelector(state => selectConfigItem(state, 'SIGNAL_PROFILE_CONFIG'))
    const { MODELS, PARAM_TYPES, PRICING_MODELS, SAMPLERS, STRATEGIES, VARIABLES, WEIGHTED_TRANS_VARIABLE } = _.get(signalProfileConfig, 'result') || {}
    const profileConfigPerType = configKey === 'samplers' ? SAMPLERS
        : configKey === 'pricing_models' ? PRICING_MODELS
        : configKey === 'variables' ? VARIABLES
        : configKey === 'models' ? MODELS
        : configKey === 'strategies' ? STRATEGIES
        : {}

    const { name, type, params: paramValuePerKey } = deserializeData(data)
    const { description: profileConfigDescription, params: paramConfigPerKey } = profileConfigPerType[type] || {}

    const [typeSelectorPopupKey, setTypeSelectorPopupKey] = useState(0)
    const [searchString, setSearchString] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const [focusedIndexPerVariableKey, setFocusedIndexPerVariableKey] = useState({})
    const itemRef = useRef(null)

    useClickAway(itemRef, () => {
        setIsFocused(false)
    })

    const pickedProfileConfigPerType = _.pickBy(profileConfigPerType, (_profileConfig, _type) => isMetSearchStringCriteria(`${_type} ${_profileConfig.description}`, searchString))

    function CurrentType () {
        return (
            <Popup
                className='signal-config-item--current-type--popup'
                disabled={_.isEmpty(profileConfigDescription)}
                trigger={<span className={'signal-config-item--current-type' + (!_.isEmpty(profileConfigDescription) ? ' has-description' : '')}>{type || 'Select Type'}</span>}>
                {profileConfigDescription}
            </Popup>
        )
    }

    function TypeSelector () {
        return (
            <Popup className='signal-config-item--type-selector--popup'
                key={typeSelectorPopupKey}
                on={'click'}
                trigger={
                    <button className={'signal-config-item--type-selector--trigger' + (_.isEmpty(type) ? ' empty-value' : '') + (!_.isEmpty(type) && !_.has(profileConfigPerType, type) ? ' invalid-type' : '')}>
                        {CurrentType()}
                        <FaAngleRight />
                    </button>
                }
                onClose={() => { setSearchString('') }}>
                <div className='signal-config-item--type-selector--title'>{typeSelectorTitle}</div>
                <input className='signal-config-item--type-selector--search-input'
                    placeholder={`Search types`}
                    value={searchString}
                    onChange={(e) => { setSearchString(e.target.value) }} />
                <div className='signal-config-item--type-selector--list'>
                    {_.map(pickedProfileConfigPerType, (_profileConfig, _type) => {
                        const { description: _description, params: _paramConfigPerKey } = _profileConfig
                        return (
                            <div className='signal-config-item--type-selector--item'
                                key={_type}
                                onClick={() => {
                                    const _newParams = _.reduce(_paramConfigPerKey, (_result, _paramConfig, _paramKey) => {
                                        const { type: _paramType, defaultValue: _paramDefaultValue } = _paramConfig || {}
                                        _result[_paramKey] = !_.isNil(_paramDefaultValue) ? _paramDefaultValue
                                            : [PARAM_TYPES?.ARRAY_OF_SAMPLERS, PARAM_TYPES?.ARRAY_OF_VARIABLES, PARAM_TYPES?.ARRAY_OF_WEIGHTED_TRANS_VARIABLES].includes(_paramType) ? []
                                            : null
                                        return _result
                                    }, {})
                                    const _newData = dotProp.set(data, '1', [_type, _newParams])
                                    onChangeData(_newData)
                                    setTypeSelectorPopupKey(typeSelectorPopupKey + 1)
                                }}>
                                <div className='signal-config-item--type-selector--item--type'>{_type}</div>
                                {!_.isEmpty(_description) && <div className='signal-config-item--type-selector--item--description'>{_description}</div>}
                            </div>
                        )
                    })}
                </div>
            </Popup>
        )
    }

    return (
        <div className='signal-config-item' ref={itemRef}
            style={{ zIndex: isFocused ? 2 : null }}
            onClick={() => { setIsFocused(true) }}>
            <div className='signal-config-item--head'>
                <input
                    type={'text'}
                    spellCheck={false}
                    placeholder={namePlaceholder}
                    value={name ?? ''}
                    onChange={(e) => {
                        const _newData = dotProp.set(data, '0', e.target.value.trim())
                        onChangeData(_newData)
                    }} />
                {TypeSelector()}
                <button className='signal-config-item--remove-button'
                    onClick={() => { onClickRemove() }}>
                    <FaXmark />
                </button>
            </div>
            {!_.isEmpty(paramValuePerKey) &&
            <div className='signal-config-item--params'>
                {_.map(paramValuePerKey, (_value, _key) => {
                    const _paramConfig = _.get(paramConfigPerKey, _key) || {}
                    const { type: _paramType } = _paramConfig
                    return (
                        <div className={`signal-config-item--params--item ${_paramType}`} key={_key}>
                            {_paramType === PARAM_TYPES?.ARRAY_OF_WEIGHTED_TRANS_VARIABLES ?
                            <SortableVariables
                                axis={'y'}
                                signalProfile={signalProfile}
                                variableParamConfig={WEIGHTED_TRANS_VARIABLE}
                                variableParams={_value} 
                                variableName='Weighted Trans Variable'
                                useDragHandle
                                focusedVariableIndex={focusedIndexPerVariableKey?.[_key]}
                                onChange={(_newValue) => {
                                    const _newData = dotProp.set(data, `1.1.${_key}`, _newValue)
                                    onChangeData(_newData)
                                }}
                                onFocusItem={(_index) => { setFocusedIndexPerVariableKey(dotProp.set(focusedIndexPerVariableKey, _key, _index)) }} />
                            : <SignalParam
                                paramKey={_key}
                                value={_value}
                                signalProfile={signalProfile}
                                paramConfig={_paramConfig}
                                onChange={(_newValue) => {
                                    const _newData = dotProp.set(data, `1.1.${_key}`, _newValue)
                                    onChangeData(_newData)
                                }} />}
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}

SignalConfigItem.propTypes = {
    signalProfile: SignalProfileShape.isRequired,
    configKey: PropTypes.oneOf(['samplers', 'pricing_models', 'variables', 'models', 'strategies']).isRequired,
    data: PropTypes.array.isRequired,
    namePlaceholder: PropTypes.string,
    typeSelectorTitle: PropTypes.string,
    onChangeData: PropTypes.func,
    onClickRemove: PropTypes.func
}

export default memo(SignalConfigItem)
