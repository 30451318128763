import React from 'react'
import { createRoot } from 'react-dom/client'
// import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { store } from './store'

import PrivateRoute from './components/auth/PrivateRoute'
import NoMatchPage from './pages/NoMatchPage'
import LoginPage from './pages/LoginPage'
import ProfilePage from './pages/ProfilePage'
import TradePage from './pages/TradePage'
import OptionStrategiesPage from './pages/OptionStrategiesPage'
import WorkspacePage from './pages/WorkspacePage'

import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from './wagmiConfig'

import 'react-circular-progressbar/dist/styles.css'
import './styles/global.scss'
import './pages/pages.scss'

import './components/common/searchSelect/searchSelect.scss'
import './components/common/toggle/toggle.scss'
import './components/common/saveButton/saveButton.scss'
import './components/common/contentIndex/contentIndex.scss'
import './components/common/checkbox/checkbox.scss'
import './components/common/webSocket/webSocketClient.scss'
import './components/common/VirtualizedList/virtualizedList.scss'
import './components/common/input/input.scss'

import './styles/loginPage.scss'

import './components/layout/layout.scss'
import './components/auth/auth.scss'
import './components/blockchain/blockchain.scss'
import './components/profile/profile.scss'
import './components/profile/legEditor.scss'
import './components/profile/profileActionPopup.scss'
import './components/profile/profileBulkUploadEditor.scss'
import './components/trading/trading.scss'
import './components/market/market.scss'
import './components/market/currencyPair.scss'
import './components/account/account.scss'
import './components/account/accountBalanceMonitor.scss'
import './components/account/tokenTransfer.scss'
import './components/symbol/symbol.scss'
import './components/symbol/option.scss'
import './components/symbol/fundingTag.scss'
import './components/timer/timer.scss'
import './components/setting/setting.scss'
import './components/webSocket/webSocket.scss'
import './components/support/support.scss'
import './components/workspace/workspace.scss'
import './components/optionStrategy/optionStrategy.scss'
import './components/loan/loan.scss'
import './components/config/config.scss'
import './components/common/tableColomnSelector/tableColumnSelector.scss'

import 'react-virtualized/styles.css'
// import '/node_modules/react-grid-layout/css/styles'
// import '/node_modules/react-resizable/css/styles'
import './styles/react-grid-layout-styles.css'
import './styles/react-resizeable-styles.css'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
            <Router>
                <Routes>
                    <Route index
                        element={
                            <PrivateRoute>
                                <ProfilePage />
                                {/* <WorkspacePage /> */}
                            </PrivateRoute>
                        } />
                    <Route path={'trade'}
                        element={
                            <PrivateRoute>
                                <TradePage />
                            </PrivateRoute>
                        } />
                    <Route path={'workspace'}
                        element={
                            <PrivateRoute>
                                <WorkspacePage />
                            </PrivateRoute>
                        } />
                    <Route path={'pairs-trade'}
                        element={
                            <PrivateRoute>
                                <OptionStrategiesPage />
                            </PrivateRoute>
                        } />
                    <Route path='login' element={<LoginPage />} />
                    <Route path='*' element={<NoMatchPage />} />
                </Routes>
            </Router>
        </WagmiConfig>
    </Provider>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
