import React, { memo, useState } from 'react'
import { useMount, useMountedState } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import ReactLoading from 'react-loading'
import moment from 'moment'
import _ from 'lodash'

import AceEditor from 'react-ace-builds'
import 'react-ace-builds/webpack-resolver-min'

import { HiOutlineSave } from 'react-icons/hi'
import { IoIosRefresh } from 'react-icons/io'
import { TbRefresh } from 'react-icons/tb'

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector'
import { selectConfigItem, selectConfigTexts } from './configReducer'
import { CONFIG_TYPE, fetchConfigs, updateConfig } from './configAction'
import { areAllValuesNonEmpty } from '../../util/util'

function ConfigContainer () {
    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const configTexts = useShallowEqualSelector(state => selectConfigTexts(state))
    const [isFetchingTexts, setIsFetchingTexts] = useState(false)
    const [editingText, setEditingText] = useState(null)
    const [configKey, setConfigKey] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const selectedConfigText = configTexts[configKey] ?? {}
    const selectedConfigItem = useShallowEqualSelector(state => selectConfigItem(state, configKey))

    const _editingText = editingText ?? selectedConfigText.text ?? ''

    const _fetchConfigs = useEvent(() => {
        if (!isFetchingTexts) {
            setIsFetchingTexts(true)
            dispatch(fetchConfigs(CONFIG_TYPE.RESULT))
            dispatch(fetchConfigs(CONFIG_TYPE.TEXT))
            .finally(() => {
                if (isMounted()) {
                    setIsFetchingTexts(false)
                }
            })
        }
    })

    useMount(() => {
        _fetchConfigs()
    })

    const _handleClickSave = useEvent(() => {
        if (!isSaving && areAllValuesNonEmpty([configKey, editingText])) {
            setIsSaving(true)
            dispatch(updateConfig(configKey, editingText))
            .then(() => {
                dispatch(fetchConfigs(CONFIG_TYPE.RESULT))
                if (isMounted()) {
                    setEditingText(null)
                }
            })
            .finally(() => {
                if (isMounted()) {
                    setIsSaving(false)
                }
            })
        }
    })

    function List () {
        return (
            <div className='config-container--list'>
                <div className='config-container--list--header'>
                    <label>{'Documents'}</label>
                    {isFetchingTexts
                    ? <ReactLoading
                        className='config-container--list--header--loading'
                        type={'spin'}
                        color='#fff' />
                    : <button className='config-container--list--header--fetch-button'
                        onClick={() => { _fetchConfigs() }}>
                        <TbRefresh />
                    </button>}
                </div>
                <div className='config-container--list--main'>
                    {_.map(configTexts, _item => {
                        const { key, name, updatedBy, updatedTimestamp } = _item.meta || {}
                        const _selected = configKey === key
                        return (
                            <div className={'config-container--list--item' + (_selected ? ' selected' : '')} key={key}
                                onClick={() => {
                                    if (!_selected) {
                                        setConfigKey(key)
                                        setEditingText(null)
                                    }
                                }}>
                                <div className='config-container--list--item--name'>{name}</div>
                                <div className='config-container--list--item--footer'>
                                    <div className='config-container--list--item--updated-by'>
                                        <label>{'By'}</label>
                                        <span>{updatedBy ?? ''}</span>
                                    </div>
                                    <div className='config-container--list--item--updated-timestamp'>
                                        <label>{'@'}</label>
                                        <span>{updatedTimestamp ? moment(updatedTimestamp).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className='config-container'>
            {List()}
            {!_.isEmpty(selectedConfigText) &&
            <div className='config-container--editor'>
                <div className='config-container--editor--header'>
                    <label>{selectedConfigText.meta?.name}</label>
                    {!_.isEmpty(editingText) && <span className='config-container--editor--header--edited-mark' />}
                    <div className='config-container--editor--header--buttons'>
                        <button className='config-container--editor--header--button discard'
                            disabled={_.isEmpty(editingText)}
                            onClick={() => { setEditingText(null) }}>
                            <IoIosRefresh />{'Discard'}
                        </button>
                        <button className='config-container--editor--header--button save'
                            disabled={_.isEmpty(editingText)}
                            onClick={() => { _handleClickSave() }}>
                            <HiOutlineSave />{isSaving ? 'Saving' : 'Save'}
                        </button>
                    </div>
                </div>
                {selectedConfigItem?.error && _.size(_.keys(selectedConfigItem)) === 1 &&
                <div className='config-container--editor--error-message'>{selectedConfigItem.error}</div>}
                <div className='config-container--editor--main'>
                    <AceEditor
                        mode='javascript'
                        theme='dracula'
                        value={_editingText}
                        showPrintMargin={false}
                        editorProps={{
                            $blockScrolling: true
                        }}
                        commands={[{
                            name: 'saveFile',
                            bindKey: {
                                win: 'Ctrl-S',
                                mac: 'Cmd-S'
                            },
                            exec: () => { _handleClickSave() }
                        }]}
                        onChange={(newValue) => { setEditingText(newValue) }} />
                </div>
            </div>}
        </div>
    )
}

export default memo(ConfigContainer)